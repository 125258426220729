import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'
import Menu from '../Menu/Menu'
import { MdAccountCircle } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import { formatDatetime, getCurrentDateTime, formatMoney } from '../../GeneralFunctions'
import { FiEdit } from 'react-icons/fi'
import Dialog from '../Dialog'






const Item = ({ field, value, title }) => {

    const session = useSelector(state => state.session);
    const baseUrl = useSelector(state => state.baseUrl);


    /* Edit */

    const [edit, setEdit] = useState(false);
    const [fieldValue, setFieldValue] = useState(value);
    const [actualPassword, setActualPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [error, setError] = useState('');


    const dispatch = useDispatch();

    /* dialogs */

    const successDialog = () => dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'success', text: 'Informação alterada com sucesso' } });
    const failDialog = () => dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'fail', text: 'Não foi possível fazer alterar os dados nesse momento. Tente novamente ou contate o suporte.' } });
    const requestFailedDialog = (error) => dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'fail', text: `Não foi possível fazer essa requisição no momento. Tente novamente ou contate o suporte. Ref: ${error}` } });


    const updateData = function () {

        let data = new FormData();



        if (field === 'password') {

            data.append('data', field);
            data.append('value', newPassword);
            data.append('actualPassword', actualPassword);

            if (newPassword.length >= 6) {

                if (newPassword === newPasswordConfirmation) {
                    fetch(`${baseUrl}/modules/account/update_data.php`, { method: 'post', body: data }).then(response => response.json()).then(response => {

                        if (response.http_code === '200') {

                            var newSession = { ...session, pwLastUpdate: getCurrentDateTime() };
                            dispatch({ type: 'SET_SESSION', data: newSession });

                            successDialog();
                            setEdit(false);
                            setFieldValue(`Última alteração em: ${formatDatetime(getCurrentDateTime())}`);

                        } else if (response.http_code === '501') {
                            setError('A senha atual informada está incorreta');
                        } else {
                            failDialog();
                        }

                    }).catch(error => {
                        requestFailedDialog(error);
                    });

                } else {
                    setError('A senha e a confirmação de senha estão diferentes');
                }

            } else {
                setError('Por favor, selecione uma senha válida com no mínimo 6 caracteres.');
            }


        } else {

            data.append('data', field);
            data.append('value', fieldValue);

            if (fieldValue.length > 0) {

                fetch(`${baseUrl}/modules/account/update_data.php`, { method: 'post', body: data }).then(response => response.json()).then(response => {

                    if (response.http_code === '200') {

                        var newSession = { ...session, [field]: fieldValue };
                        dispatch({ type: 'SET_SESSION', data: newSession });

                        successDialog();
                        setFieldValue(fieldValue);
                        setEdit(false);

                    } else {
                        failDialog();
                    }

                }).catch(error => {
                    requestFailedDialog();
                });

            } else {
                setError('Por favor, escolha um valor válido');
            }
        }


    }


    return (

        edit ?


            <div className='informationContainer'>
                <div className='title label'>{title}</div>
                <div className='content'>
                    <div className='information'>
                        {field === 'password' ?
                            <div style={{ display: 'flex', flexDirection: 'column', width: '20vw' }}>
                                <input type='password' value={actualPassword} onChange={(e) => setActualPassword(e.target.value)} className='basicInput' placeholder='Senha atual' />
                                <input type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className='basicInput' placeholder='Nova senha' />
                                <input type='password' value={newPasswordConfirmation} onChange={(e) => setNewPasswordConfirmation(e.target.value)} className='basicInput' placeholder='Confirme a nova senha' />
                            </div>

                            :

                            <input type='text' onChange={(e) => setFieldValue(e.target.value)} className='basicInput' value={fieldValue} />
                        }

                        <div className='error'>{error}</div>
                    </div>



                    <div className='editButtons'>
                        <button className='blueBtn save' onClick={() => updateData()}>Salvar alteração</button>
                        <button className='whiteBtn cancel' onClick={() => setEdit(false)}>Cancelar</button>
                    </div>
                </div>
            </div>


            :


            <div className='informationContainer'>
                <div className='title label'>{title}</div>
                <div className='content'>
                    <div className='information'>
                        {field === 'password' ? <span className='empty'>{fieldValue}</span> : fieldValue}
                    </div>
                    <div className='editButtons'>
                        <button className='iconBtn edit' onClick={() => { setEdit(true); setError('') }}>
                            <FiEdit style={{ marginRight: 5 }} />
                            Editar
                        </button>
                    </div>
                </div>
            </div>

    );


}













export default function Account() {

    const session = useSelector(state => state.session);
    const [payment, setPayment] = useState(false);
    const [paymentDescription, setPaymentDescription] = useState('');
    const baseUrl = useSelector(state => state.baseUrl);


    useEffect(() => {

        if (!payment) {

            if (session.is_testing === 't') {
                console.log(session.is_testing);
                setPayment('0');
                setPaymentDescription('Referente ao período de testes');
            } else {


                //Se o usuario tiver uma conta fixa
                if (session.bill) {
                    setPayment(session.bill); 
                    setPaymentDescription(session.payment_description);
                    return;
                }

                //Caso contrario, verifica qual é a fatura

                fetch(`${baseUrl}/modules/account/get_average_products.php`).then(response => response.json()).then(response => {
                    if (response.http_code === '200') {
                        setPaymentDescription(`Fatura estimada referente a ${response.average} produtos`);
                        setPayment(response.payment);

                    }
                });
            }
        }

    }, [payment]);

    return (
        <>

            <Header pageTitle='Minha conta' pageIcon={<MdAccountCircle size={16} />} />
            <Menu active='account' />
            <div className='pageContent'>

                <div id='accountContainer'>
                    <div style={{ marginBottom: 40 }}>
                        <div className='containerHeader'>
                            <div className='title'>
                                <h3 className='containerTitleSmall'>Informações gerais</h3>
                            </div>
                        </div>
                        <div>
                            <div className='informationContainer'>
                                {session.user_name}
                            </div>

                            <Item field='user_login' value={session.user_login} title='Login' />
                            <Item field='user_email' value={session.user_email} title='Endereço de e-mail' />
                            <Item field='password' value={session.pwLastUpdate ? 'Nunca alterado' : `Última alteração em ${formatDatetime(session.pwLastUpdate)}`} title='Senha' />

                        </div>
                    </div>
                    <div>
                        <div className='containerHeader'>
                            <div className='title'>
                                <h3 className='containerTitleSmall'>Fatura</h3>
                            </div>
                        </div>
                        <div className='informationContainer'>
                            {paymentDescription ? <div className='title label'>{paymentDescription}</div> : null}

                            <div>
                                {payment ?
                                    payment == '0' ?
                                        <span style={{ color: 'green' }}>R$ 0,00</span>
                                        :
                                        formatMoney(payment) :
                                    'Fatura indisponível'
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog />
        </>
    )
}


// switch (session.id) {
//     case "1":
//         setPayment(429.00);
//         setPaymentDescription('Referente à Hooklab e Catálogo');
//         break;

//     case "2":
//         setPayment(389.00);
//         break;

//     case "4":
//         setPayment(342.90);
//         break;

//     case "6":
//         setPayment(389.90);
//         break;

//     case "7":
//         setPayment(350.00);
//         break;

//     case "31":
//     case "32":
//         setPayment(250.00);
//         break;
