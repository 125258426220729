
import React, { useEffect, useState } from 'react'

import { FaTools } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { formatDatetime, toggleView } from '../../GeneralFunctions'
import { AiOutlineSync, AiOutlineTag, AiOutlineEye } from 'react-icons/ai'
import { RiFileExcel2Line } from 'react-icons/ri'
import Overlay from '../Overlay'
import ImportData from './ImportDataModal'
import Markup from './Markup'
import AddProductModal from './AddProductModal'
import Dialog from '../Dialog'
import Header from '../Header/Header'
import Menu from '../Menu/Menu'
import { FiBox } from 'react-icons/fi'
import useSync from './useSync'


export default function Manage() {

    const baseUrl = useSelector(state => state.baseUrl);
    const sync = useSelector(state => state.sync);
    const session = useSelector(state => state.session);
    const dispatch = useDispatch();
    const loadSync = useSync();

    const [importDataWindow, setImportDataWindow] = useState(false);
    const [markupWindow, setMarkupWindow] = useState(false);
    const [addProductWindow, setAddProductWindow] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            loadSync()
            console.log(`Fetching sync data...`)
        }, 10000)
        loadSync()
        return () => clearInterval(interval)
    }, []);

    const allowUnavailables = () => {
        fetch(`${baseUrl}/modules/manage/change_visualization.php?to=${session.allow_unavailable === 't' ? 'f' : 't'}`).then(response => response.json()).then(response => {
            if (response.http_code === '200') {
                dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'success', text: 'Alteração realizada com sucesso! A página será atualizada...' } })
                setTimeout(() => window.location.reload(), 3000);
            } else {
                dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'fail', text: 'Não foi possível realizar essa operação no momento. Tente novamente ou contate o suporte' } });
            }

        }).catch(error => {
            dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'fail', text: `Não foi possível enviar essa requisição agora. Ref: ${error}` } });
        });
    }

    const downloadTemplate = () => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.download = 'hooklab_template.xlsx';
        link.href = '/t/hooklab_template.xlsx';
        link.click();
        link.remove();
    }

    useEffect(() => {
        window.fetch = new Proxy(window.fetch, {
            apply(fetch, that, args) {
                // Forward function call to the original fetch
                //@ts-ignore
                const result = fetch.apply(that, args);

                // Do whatever you want with the resulting Promise
                result.then(async (response) => {
                    try {
                        if (args[0].indexOf('/etl-netshoes/new/user/') > -1) {
                            const responseText = await response.text()
                            if (response.ok) {
                                if (responseText === '200') {
                                    alert('A sincronização de produtos foi realizada com sucesso! Atualize a página.')
                                } else {
                                    alert('Ocorreu um erro ao processar a planilha de sincronização de produtos. Tente novamente em instantes')
                                }
                            } else {
                                alert('Ocorreu um erro ao processar a planilha de sincronização de produtos. Tente novamente em instantes')
                            }
                        }
                    } catch (error) {
                        console.log(error.message)
                    }
                });
                return result;
            }
        });
    }, [])


    return (
        <>
            <Header pageTitle='Gerenciar produtos' pageIcon={<FaTools size={16} />} />
            <Menu active='manage' />
            <div className='pageContent'>
                <div id='manageContainer'>
                    <div className='cell' onClick={() => toggleView(importDataWindow, setImportDataWindow)}>
                        <div className='icon'>
                            <AiOutlineSync size={20} />
                        </div>
                        <div className='text'>
                            <div className='title'>Sincronizar produtos</div>
                            <div className='subtitle'>
                                {sync &&
                                    <>
                                        {sync.error === 't' ?
                                            <div>Ocorreu um erro ao processar a última sincronização em {formatDatetime(sync.upload_date)}</div>
                                            :
                                            sync.processing === 't' ?
                                                <div style={{ color: 'rgb(61, 131, 210)', fontSize: '1.4rem', fontWeight: 500 }}>Processando sincronização... Isto pode levar alguns minutos</div>
                                                :
                                                <div style={{ color: 'green', fontSize: '1.4rem', fontWeight: 500 }}>Última sincronização concluída em {formatDatetime(sync.upload_date)}</div>
                                        }
                                    </>}
                            </div>
                        </div>
                    </div>

                    <div className='cell' onClick={() => toggleView(markupWindow, setMarkupWindow)}>
                        <div className='icon'>
                            <AiOutlineTag size={20} />
                        </div>
                        <div className='text'>
                            <div className='title'>Margem mínima</div>
                            <div className='subtitle'>Alterar margem mínima por marca</div>
                        </div>
                    </div>

                    <div className='cell' onClick={() => downloadTemplate()}>
                        <div className='icon'>
                            <RiFileExcel2Line size={20} />
                        </div>
                        <div className='text'>
                            <div className='title'>Template planilha de custos</div>
                            <div className='subtitle'>Baixar template de planilha de preço de custo</div>
                        </div>
                    </div>

                    <div className='cell' onClick={() => toggleView(addProductWindow, setAddProductWindow)}>
                        <div className='icon'>
                            <FiBox size={20} />
                        </div>
                        <div className='text'>
                            <div className='title'>Adicionar Produto</div>
                            <div className='subtitle'>Adicione produtos utilizando o seu SKU Netshoes</div>
                        </div>
                    </div>

                    <div className='cell' style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='icon' style={{ display: 'flex', alignItems: 'center' }}>
                                <AiOutlineEye size={20} />
                            </div>
                            <div className='text'>
                                <div style={{ marginBottom: 0 }} className='title'>Visualização</div>
                            </div>
                        </div>
                        <div style={{ marginTop: 15, fontSize: '1.2rem' }}>
                            <input defaultChecked={session.allow_unavailable === 't' ? true : false} value={session.allow_unavailable === 't' ? true : false} onChange={() => allowUnavailables()} style={{ marginRight: 15 }} type='checkbox' />
                            Exibir produtos indisponíveis/sem estoque
                        </div>
                    </div>

                </div>
            </div>

            {importDataWindow &&
                <div>

                    <ImportData
                        sync={sync}
                        toggleView={() => toggleView(importDataWindow, setImportDataWindow)} />
                </div>

            }

            {addProductWindow &&
                <div>
                    <Overlay toggleView={() => toggleView(addProductWindow, setAddProductWindow)} />
                    <AddProductModal toggleView={() => toggleView(addProductWindow, setAddProductWindow)} />
                </div>

            }


            {markupWindow &&
                <div>
                    <Overlay toggleView={() => toggleView(markupWindow, setMarkupWindow)} />
                    <Markup toggleView={() => toggleView(markupWindow, setMarkupWindow)} />
                </div>
            }




            <Dialog />
        </>
    )
}





/*


   <div className='containerHeader'>
                        <div className='title'>
                            <h3 className='containerTitleSmall'>Sincronizar produtos</h3>
                        </div>
                        <div>
                            <button className='blueBtn'>Sincronizar agora</button>
                        </div>
                    </div>


<div className='syncList'>
<div className='headerCell'>
    <div className='section label'>Data</div>
    <div className='section label'>Status</div>
</div>
{sync.length === 0 ?
    <div className='empty'>Nenhuma sincronização realizada.</div>
    :
    sync.map(s =>
        <div className='cell'>
            <div className='section'>{formatDatetime(s.upload_date)}</div>
            <div className='section'>
                {s.status === 'success' ?
                    <div className='syncSuccess'>Processado com successo.</div>
                    :
                    s.status === 'failed' ?
                        <div className='syncFail'>Ocorreu um erro ao processar</div>
                        :
                        <div className='syncLoading'>Processando...</div>
                }
            </div>
        </div>)
}
</div>
*/