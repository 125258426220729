import React, { useState } from 'react'
import { AiOutlineArrowRight, AiOutlineLineChart } from 'react-icons/ai';
import { formatMoney } from '../../GeneralFunctions.js';
import { MdClose } from 'react-icons/md';
import ProductHistory from '../ProductHistory.js';
import LateralProductView from '../Products/LateralProductView/LateralProductView.js';
import { useDispatch, useSelector } from 'react-redux';

export default function ViewProducts(props) {

    const activeProduct = useSelector(state => state.activeProduct);
    const dispatch = useDispatch();


    const toggleLateralView = (product, e) => {

        let role = e.target.getAttribute('role');
        if (role == 'netshoes-link' || role == 'sku' || role == 'title') return;
        const selection = window.getSelection();

        if (selection.type != "Range") {
            document.querySelector('body').style.overflowY = 'hidden';
            if (!activeProduct) return dispatch({ type: 'SET_DATA', data: { activeProduct: product } });
            if (activeProduct.id === activeProduct.id) {
                document.querySelector('body').style.overflowY = 'auto';
                return dispatch({ type: 'SET_DATA', data: { activeProduct: false } });
            }

            document.querySelector('body').style.overflowY = 'hidden';
            return dispatch({ type: 'SET_DATA', data: { activeProduct: product } });
        }
    }



    return (
        <div id='viewProducts' className='modal' >
            <div className='modalHeader'>
                <div className='title'>
                    <h3 className='containerTitleSmall'>{props.title}</h3>
                </div>
                <div className='close'>
                    <button onClick={() => props.toggleView()} className='iconBtn'>
                        <MdClose size={18} />
                    </button>
                </div>
            </div>
            <ul className='productsList'>
                {props.data.map((product, index) =>
                    <Product toggleLateralView={toggleLateralView} key={index} index={index} product={product} />
                )}
            </ul>
            {props.children}

            <LateralProductView toggleLateralView={toggleLateralView} />

        </div>
    )
}



const Product = ({ product, index, toggleLateralView }) => {

    const [view, setView] = useState(false);
    const dispatch = useDispatch();






    return (
        <li className='cell' onClick={(e) => toggleLateralView(product, e)} key={index}>

            <div className='data'>
                <div className='section'>
                    <div className='indexSection'>
                        <div>{index + 1}</div>
                        <div className='historicBtn'>
                            <button className='iconBtn' onClick={() => setView(!view)}>
                                <AiOutlineLineChart size={24} />
                            </button>
                        </div>
                    </div>
                    <div className='subSection'>
                        <div><a href={`https://www.${product.marketplace}.com.br/${product.pk_sku_netshoes}`} target='_blank' rel='norefferer noopener'>{product.title}</a></div>
                        <span className='sku'>SKU {product.sku_seller} · {product.pk_sku_netshoes}</span>
                    </div>
                </div>
                <div className='section'>
                    <div className='subSection'>
                        <div>{product.old_seller}</div>
                        <span>{formatMoney(product.old_sale_price)}</span>
                    </div>
                    <div className='arrowSection'>
                        <AiOutlineArrowRight />
                    </div>
                    <div className='subSection'>
                        <div>{product.seller}</div>
                        <span>{formatMoney(product.sale_price)}</span>
                    </div>

                </div>
            </div>

            {view &&
                <ProductHistory sku={product.pk_sku_netshoes} />
            }
        </li>
    );

}

// <div>
// <div className='title'>{product.title}</div>
// <div className='sku'>{product.sku_seller}</div>
// </div>
{/* <div className='status'>
<div className='oldSeller'>
    <div>{product.old_seller}</div>
    <div>{formatMoney(product.old_sale_price)}</div>
</div>
<div><AiOutlineArrowRight /></div>
<div className='newSeller'>
    <div>{product.seller}</div>
    <div>{formatMoney(product.sale_price)}</div>
</div> */}
// </div>