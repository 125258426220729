import { useSelector } from 'react-redux'
import { IStore } from '../../store/IStore';


export function usePost() {

    const baseUrl = useSelector((state: IStore) => state.baseUrl);
    const savedLocalStorageSession = localStorage.getItem('session')
    const session = savedLocalStorageSession ? JSON.parse(savedLocalStorageSession) : null;
   

    return (url: string, object: any, extraHeaders?: any) => new Promise((resolve, reject) => {

        if (session) {
            fetch(`${baseUrl}${url}`, {
                headers: {
                    'content-type': 'application/json',
                    ...extraHeaders
                },
                method: 'POST',
                body: object,
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => resolve(response)).catch(error => resolve(error));
                } else {

                    var statusCode = response.status;
                    if (statusCode === 555) {
                        alert('Sua sessão expirou. Faça login novamente.')
                        const origin = window.location.origin;
                        window.location.href = origin + '/app/login';
                    } else {
                        return response.json().then(response => {
                            reject(response)
                        });
                    }
                }
            });
        }




        // return (url, object, callback, error) => {
        //     fetch(url, {
        //         headers: {
        //             'x-access-token': session.token,
        //             'client-secret': session.userData.secret,
        //             'Content-Type': 'application/json',
        //             'client-account-id': session.userData.account_id,
        //             'client-user-id': session.userData.user_id
        //         },
        //         method: 'PUT',
        //         body: JSON.stringify(object)
        //     }).then(function (response) {

        //         var statusCode = response.status;

        //         if (response.ok) {
        //             return Promise.resolve(response);
        //         } else {

        //             return response.json().then(response => {

        //                 console.log(response);

        //                 Promise.reject({ ...response, statusCode: statusCode })
        //             });
        //         }

        //     }).then(response => response.json()).then(data => {

        //         if (callback) {
        //             callback(data);
        //         }

        //     }).catch((err) => {

        //         if (err.statusCode === 555) {
        //             alert('Sua sessão expirou. Faça login novamente.')
        //             window.location.href = '/login';
        //         }

        //         if (error) {
        //             error(err);
        //         }
        //     });
        // }
    });
}
