import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../store/IStore";
import style from "../Feedback.module.scss";

export default function Comment() {
  const dispatch = useDispatch();
  const comment = useSelector((state: IStore) => state.feedback.formData.comment)

  return (
    <div id="comment" className={style.comment}>
      <h3>Deseja fazer um comentário ou sugestão?</h3>
      <div className={style.feedback__satisfaction__header__sub}>
        Seu feedback é muito importante para nós
      </div>
      <textarea
        value={comment}
        placeholder="Digite seu feedback ou comentário..."
        className={style.comment__input}
        onChange={(e) =>
          dispatch({ type: "SET_FEEDBACK_COMMENT", data: e.target.value })
        }
      />
    </div>
  );
}
