import { createStore } from 'redux';


const activeHost = window.location.href;

if (activeHost.indexOf('localhost') > -1) {
    var baseUrl = 'http://localhost/hooklab-react/public/backend/';
    var landingPage = 'localhost:3000';
} else {
    var baseUrl = 'https://hooklab.com.br/backend';
    var landingPage = 'https://hooklab.com.br/pt-br';
}


const INITIAL_STATE = {
    baseUrl: baseUrl,
    landingPage: landingPage,
    products: false,
    productsCount: 0,
    queryProduct: { term: null },
    queryOrder: [],
    queryFilters: { page: 1, status: 1 },
    session: {},
    editableProduct: false,
    dialog: {
        status: 'hidden',
        text: '',
        type: ''
    },
    loadingSummary: true,
    summary: false,
    sync: false,
    performanceData: false,
    newSales: false,
    lostSales: false,
    productsNewSales: false,
    productsLostSales: false,
    opportunitiesHistoric: false,
    brandsQuantityData: false,
    addedProductsData: false,
    optimizedProducts: false,
    productsHistory: [],
    userMarkups: false,
    opportunities: { loading: true },
    prices: { loading: true },
    optimized: { loading: true },
    updatePricesHistoric: false,
    feedback: {
        lastFeedback: undefined,
        viewFeedback: false,
        formData: {
            score: null,
            comment: ''
        },

    }
};

function reducer(state = INITIAL_STATE, action) {

    switch (action.type) {


        /* sessão */

        case "SET_SESSION":
            return { ...state, session: action.data };

        case 'SET_DATA':

            let key = Object.keys(action.data)[0];
            let value = action.data[key];
            return { ...state, [key]: value };


        /* produtos */

        case "SET_USER_PRODUCTS":
            return { ...state, products: action.data, productsCount: action.count };

        case "LOADING_PRODUCTS":
            return { ...state, loadingProducts: action.status };


        /* resumo */

        case "LOADING_SUMMARY":
            return { ...state, loadingSummary: action.status };

        case "SET_USER_SUMMARY":
            return { ...state, summary: action.data };




        /* SEARCH, FILTERS, PAGINATION */

        case "SET_PRODUCTS_QUERY":
            return { ...state, queryProduct: { ...state.queryProduct, term: action.term }, queryFilters: { ...state.queryFilters, page: 1 } };

        case "SET_PRODUCTS_FILTERS":
            return { ...state, queryFilters: action.filters };

        case "SET_PRODUCTS_ORDER":
            return { ...state, queryOrder: action.order };



        /* EDITAR PRODUTO */

        case "SET_EDITABLE_PRODUCT":
            return { ...state, editableProduct: action.data };


        /* DIALOG */

        case "SET_DIALOG":
            return { ...state, dialog: action.data };



        /* MANAGE */

        case "SET_SYNC_DATA":
            return { ...state, sync: action.data };

        case "SET_EXPORT_PRICES_HISTORIC":
            return { ...state, exportPricesHistoric: action.data };

        case "SET_USER_MARKUPS":
            return { ...state, userMarkups: action.data };



        /* DASHBOARD */

        case "SET_PERFORMANCE_DATA":
            return { ...state, performanceData: action.data };

        case "SET_NEW_SALES":
            return { ...state, newSales: action.data };

        case "SET_LOST_SALES":
            return { ...state, lostSales: action.data };

        case "SET_PRODUCTS_NEW_SALES":
            return { ...state, productsNewSales: action.data };

        case "SET_PRODUCTS_LOST_SALES":
            return { ...state, productsLostSales: action.data };

        /* OPTIMIZE */

        case "SET_OPPORTUNITIES_QUANTITY":
            return { ...state, opportunitiesQuantity: action.data };

        case "SET_OPTIMIZE_PRICES_QUANTITY":
            return { ...state, optimizePricesQuantity: action.data };

        case "SET_PRODUCTS_OPPORTUNITIES":
            return { ...state, productsOpportunities: action.data };

        case "SET_OPPORTUNITIES_HISTORIC":
            return { ...state, opportunitiesHistoric: action.data };

        case "SET_PRODUCTS_PRICES_OPTIMIZE":
            return { ...state, productsPricesOptimize: action.data };

        case "SET_UPDATE_PRICES_HISTORIC":
            return { ...state, updatePricesHistoric: action.data };

        case "SET_PRICES_REOPTIMZATION":
            return { ...state, reoptimizationProducts: action.data };


        /* RELATORIO */

        case "SET_BRANDS_QUANTITY_DATA":
            return { ...state, brandsQuantityData: action.data };

        case "SET_ADDED_PRODUCTS":
            return { ...state, addedProductsData: action.data }


        case "SET_OPTIMIZE_PRICES_EARNED":
            return { ...state, earnedPricesOptimize: action.data };

        case "SET_OPTIMIZED_PRODUCTS":
            return { ...state, optimizedProducts: action.data };



        /* PRODUCTS HISTORY */
        case "SET_PRODUCTS_HISTORY":
            return { ...state, productsHistory: action.data };

        case "SET_OPPORTUNITIES":
            return { ...state, opportunities: action.data };

        case "SET_PRICES":
            return { ...state, prices: action.data };

        case "SET_OPTIMIZED":
            return { ...state, optimized: action.data };

        /* COMPETITION */
        case "SET_PRODUCTS_COMPETITION":
            return { ...state, productsCompetition: action.data };

        case "SET_VIEW_FEEDBACK":
            var newFeedback = { ...state.feedback, viewFeedback: action.data }
            return { ...state, feedback: newFeedback }

        case "SET_FEEDBACK_SCORE":
            var newFeedbackFormData = {
                ...state.feedback.formData,
                score: action.data
            }
            var newFeedback2 = { ...state.feedback, formData: newFeedbackFormData }
            return { ...state, feedback: newFeedback2 }

        case "SET_FEEDBACK_COMMENT":
            var newFeedbackFormData = {
                ...state.feedback.formData,
                comment: action.data
            }
            var newFeedback2 = { ...state.feedback, formData: newFeedbackFormData }
            return { ...state, feedback: newFeedback2 }

        case "SET_LAST_FEEDBACK":
            return { ...state, feedback: { ...state.feedback, lastFeedback: action.data } };

        case "RESET_FEEDBACK_STATE":
            var newFeedback2 = {
                ...state.feedback, formData: {
                    score: null,
                    comment: ''
                }
            }
            return { ...state, feedback: newFeedback2 }

        default:
            return state;

    }

}


const store = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;