import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useProducts from '../../hooks/products/useProducts';

export default function Footer() {

    const query = useSelector(state => state.queryProduct);
    const order = useSelector(state => state.queryOrder);  // [filter, 'desc | asc' ]
    const filters = useSelector(state => state.queryFilters);
    const productsCount = useSelector(state => state.productsCount);
    const loadProducts = useProducts();

    const dispatch = useDispatch();
    const [pagesCount, setPagesCount] = useState([]);

    useEffect(() => {

        //Ajusta a quantidade de páginas para a quantidade de produtos.
        const count = Math.ceil(parseInt(productsCount) / 20);
        var newPagesCount = [];
        for (let i = 1; i <= count; i++) {
            var newPagesCount = newPagesCount.concat(<option value={i}>{i}</option>);
        }
        setPagesCount(newPagesCount);
    }, [productsCount]);




    const handlePagination = (value) => {
        var newFilters = { ...filters, page: value };
        dispatch({ type: 'SET_PRODUCTS_FILTERS', filters: newFilters });
        loadProducts(newFilters, query, order);
    }

    return (

        pagesCount.length > 0 ?
            <div className='pagination'>
                <div>
                    <span>Exibindo a página</span>
                </div>
                <div>
                    <select defaultValue={filters.page} onChange={(e) => handlePagination(e.target.value)} className='basicInput'>
                        {pagesCount.map(item => item)}
                    </select>
                </div>
                <div>
                    <span>de {productsCount} resultados.</span>

                </div>
            </div>
            :
            null

    )
}
