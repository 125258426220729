import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../store/IStore";

export default function useSync() {
  const baseUrl = useSelector((state: IStore) => state.baseUrl);
  const dispatch = useDispatch();

  return async () => {
    try {
      const request = await fetch(
        `${baseUrl}/modules/manage/get_sync_data.php`
      );
      const response = await request.json();
      if (response.http_code === "200") {
        dispatch({ type: "SET_SYNC_DATA", data: response.data });
      } else {
        dispatch({
          type: "SET_DIALOG",
          data: {
            status: "visible",
            type: "fail",
            text: "Não foi possível carregar as informações de sincronização.",
          },
        });
      }
      //@ts-ignore
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: "SET_DIALOG",
        data: { status: "visible", type: "fail", text: error.message },
      });
    }
  };
}
