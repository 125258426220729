import React, { useEffect, useState } from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GiFishingHook } from 'react-icons/gi';

import Products from './components/Products/Products.js';
import Manage from './components/Manage/Manage.js';
import Account from './components/Account/Account.js';
import Dashboard from './components/Dashboard/Dashboard.js';
import Support from './components/Support/Support.js';
import Optimize from './components/Optimize/Optimize.js';
import Report from './components/Report/Report.js';
import InactiveAccount from './components/InactiveAccount.js';
import NotFound from './components/NotFound.js';

export default function Routes() {

    const [auth, setAuth] = useState(false);
    const [active, setActive] = useState(false);

    const baseUrl = useSelector(state => state.baseUrl);
    const dispatch = useDispatch();
    const landingPage = useSelector(state => state.landingPage);

    useEffect(() => {
        if (!window.location.pathname.includes('/pt-br')) {
            fetch(`${baseUrl}/modules/login/auth.php`).then(response => response.json()).then(response => {

                console.log(response);


                if (!response.id) {

                    window.location.href = landingPage;

                }
                if (response.id) {

                    if (response.active === 't') {
                        setActive(true);
                    } else {
                        setActive(false);
                    }

                    dispatch({ type: 'SET_SESSION', data: response });
                    setAuth(true);
                }

            });
        }
    });


    const LoginRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={props => (
            auth ?
                <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
                :
                <Component {...props} />
        )} />
    )

    const AppRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={props =>
            auth ?
                <Component {...props} />
                :
                window.location.href = landingPage
        } />
    )




    return (
        <>
            {auth === true ?
                <BrowserRouter basename='/app'>

                    {active ?
                        <Switch>
                            <AppRoute exact path="/" component={() => <Dashboard />} />
                            <AppRoute exact path="/dashboard" component={() => <Dashboard />} />
                            <AppRoute exact path="/produtos" component={() => <Products />} />
                            <AppRoute exact path="/editar" component={() => <Manage />} />
                            <AppRoute exact path="/conta" component={() => <Account />} />
                            <AppRoute exact path="/suporte" component={() => <Support />} />
                            <AppRoute exact path="/otimizar" component={() => <Optimize />} />
                            <AppRoute exact path="/relatorio" component={() => <Report />} />
                            <AppRoute path="/" component={() => <NotFound />} />
                        </Switch>
                        :
                        <AppRoute path="/" component={() => <InactiveAccount />} />
                    }

                </BrowserRouter>
                :
                <div style={{ width: '100%', backgroundColor: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', position: 'absolute', left: 0, top: 0 }}>
                    <GiFishingHook size={40} />
                </div>
            }
        </>
    )
}
