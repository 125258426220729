import React, { useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

export default function Help({ text }) {

    const [view, setView] = useState(false);

    return (
        <div id='helpContainer' onMouseEnter={() => setView(true)} onMouseLeave={() => setView(false)}>
            <button className='iconBtn'>
                <AiOutlineQuestionCircle />
            </button>
            {view &&
                <div id='helpWindow'>{text}</div>
            }
        </div>
    )
}
