import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { formatMoney } from '../../../GeneralFunctions';
import { AiOutlineArrowRight } from 'react-icons/ai';
import Help from '../../Help';
import Loading from '../../Loading';

export default function Earned() {


    const opportunities = useSelector(state => state.opportunities);
    const prices = useSelector(state => state.prices);
    const optimized = useSelector(state => state.optimized);




    return (
        <div id='earnedContainer'>
            <div className='earnedSection container' style={{ marginRight: 5 }} >
                {opportunities.loading ?
                    <Loading />
                    :
                    <>
                        <div className='title'>
                            <h3 className="containerInsideTitleBig">Estimativa de quanto você pode ganhar reduzindo os seus preços</h3>
                            {/* <Help text='Estimado através da diferença entre os preços sugeridos e os preços atuais que podem ser otimizados.' /> */}
                        </div>
                        <div className='mediumNumber'>
                            {opportunities.potential ? <div>+{formatMoney(opportunities.potential)}</div> : <div>{formatMoney('0')}</div>}
                        </div>
                    </>
                }
            </div>
            <div className='earnedSection container' style={{ marginRight: 5, marginLeft: 5 }} >
                {prices.loading ?
                    <Loading />
                    :
                    <>
                        <div className='title'>
                            <h3 className="containerInsideTitleBig">Estimativa de quanto você pode ganhar otimizando seus preços</h3>
                            {/* <Help text='Estimado através dos produtos que você está vendendo que tiveram o preço aumentado.' /> */}
                        </div>
                        <div className='mediumNumber'>
                            {prices.potential ? <div>+{formatMoney(prices.potential)}</div> : <div>{formatMoney('0')}</div>}
                        </div>
                    </>
                }
            </div>

            <div className='earnedSection container' style={{ marginLeft: 5 }} >
                {optimized.loading ?
                    <Loading />
                    :
                    <>
                        <div className='title'>
                            <h3 className="containerInsideTitleBig">Estimativa de quanto você já ganhou otimizando seus preços nos últimos 30 dias</h3>
                            {/* <Help text='Estimado através dos produtos que você está vendendo que tiveram o preço aumentado.' /> */}
                        </div>
                        <div className='mediumNumber'>
                            {optimized.earned ? <div>+{formatMoney(optimized.earned)}</div> : <div>{formatMoney('0')}</div>}
                        </div>
                    </>
                }
            </div>

        </div>
    )
}
