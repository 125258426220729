import React, { useState, useEffect } from 'react'
import { AiOutlineDownload, AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import Overlay from '../Overlay';
import ExportModal from './ExportModal';
import { toggleView } from '../../GeneralFunctions';

export default function ExportBtn({ forbiddenColumns, selectProductsMode, setSelectProductsMode, selectedProducts, setSelectedProducts, defaultColumns, products, section }) {

    const [window, setWindow] = useState(false);
    const [columnsWindow, setColumnsWindow] = useState(false);

    console.log(products);

    const dispatch = useDispatch();


    const handleSelectMode = function (mode) {

        setSelectProductsMode(mode);
        setWindow(false);

        if (mode === 'select') {
            dispatch({
                type: 'SET_DIALOG',
                data: {
                    type: 'notice',
                    status: 'visible',
                    text: 'Clique nos produtos para selecioná-los para exportação.'
                }
            });
        } else {
            setSelectedProducts([]);
            setColumnsWindow(true);
        }
    }




    const openWindow = function () {
        if (selectedProducts) {
            if (selectedProducts.length > 0) {
                setColumnsWindow(true);
            } else {
                dispatch({
                    type: 'SET_DIALOG',
                    data: {
                        type: 'fail',
                        status: 'visible',
                        text: 'Selecione os produtos para continuar'
                    }
                })
            }
        } else {
            dispatch({
                type: 'SET_DIALOG',
                data: {
                    type: 'fail',
                    status: 'visible',
                    text: 'Ocorreu um erro ao exportar os dados.'
                }
            });
        }
    }




    return (
        <>
            <div id='exportBtnContainer'>
                {selectProductsMode === 'select' &&
                    <button
                        className='blueBtn'
                        onClick={() => openWindow()}
                        style={{ marginRight: 5, width: 150 }}>
                        Gerar planilha
                    </button>
                }

                {selectProductsMode === false ?
                    <button className='blueBtn2' onClick={() => setWindow(!window)}>
                        <AiOutlineDownload size={18} /> Exportar dados
                    </button>
                    :
                    <button className='blueBtn2' onClick={() => { setSelectProductsMode(false); setSelectedProducts([]); }}>
                        <AiOutlineClose size={18} /> Cancelar seleção
                    </button>
                }

                {window &&
                    <div id='exportOptions'>
                        <div onClick={() => handleSelectMode('all')}>Exportar todos os produtos</div>
                        <div onClick={() => handleSelectMode('select')}>Selecionar produtos para exportar</div>
                    </div>
                }
            </div>

            {columnsWindow &&
                <div>
                    <div onClick={()=>selectProductsMode === 'all' ? setSelectProductsMode(false) : null}>
                        <Overlay toggleView={() => toggleView(columnsWindow, setColumnsWindow)} />
                    </div>
                    <ExportModal
                        section={section}
                        products={products}
                        selectProductsMode={selectProductsMode}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        toggleView={() => toggleView(columnsWindow, setColumnsWindow)}
                        defaultColumns={defaultColumns}
                        setSelectProductsMode={setSelectProductsMode}
                        setWindow={setWindow}
                        forbiddenColumns={forbiddenColumns}
                    />
                </div>
            }

        </>
    )
}

