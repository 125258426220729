import React, { useEffect } from 'react'
import Routes from './Routes.js';
import { useDispatch, useSelector } from 'react-redux';
import { myGet } from './GeneralFunctions.js';
import Feedback from './components/Feedback/index.tsx'

export default function Config() {

    const dispatch = useDispatch();
    const baseUrl = useSelector(state => state.baseUrl);


    useEffect(() => {
        dispatch({ type: 'SET_DIALOG', data: { type: 'notice', status: 'visible', text: 'Carregando...' } });
    });





    useEffect(() => {
        /* UPDATE OPTIMIZED PRODUCTS */

        fetch(`${baseUrl}/modules/optimize/get_optimizable_products.php`).then(response => response.json()).then(response => {
            if (response.http_code === '200') {
                const pricesQuantity = response.data.quantity;
                const pricesPotential = response.data.products.reduce((total, product) => Number(total) + Number(product.price_difference), 0);
                const newPrices = { quantity: pricesQuantity, potential: pricesPotential, products: response.data.products };
                dispatch({ type: 'SET_PRICES', data: newPrices });
            }
        });

        fetch(`${baseUrl}/modules/optimize/get_opportunities_products.php?priceDifference=0.05`).then(response => response.json()).then(response => {
            if (response.http_code === '200') {
                const opQuantity = response.data.quantity;
                const opPotential = response.data.products.reduce((total, product) => Number(total) + Number(product.suggested_price), 0);
                const newOpportunities = { quantity: opQuantity, potential: opPotential, products: response.data.products };
                dispatch({ type: 'SET_OPPORTUNITIES', data: newOpportunities });
            }
        });

    });

    useEffect(() => {

        myGet(`${baseUrl}/modules/optimize/get_optimized_products.php`, (response) => {


            const mergedData = [...response.data.optimize, ...response.data.opportunity];
            const earned = mergedData.reduce((total, product) => Number(total) + Number(product.earned), 0);
            const optimized = { optimizeProducts: response.data.optimize, opportunitiesProducts: response.data.opportunity, earned: earned };
            dispatch({ type: 'SET_OPTIMIZED', data: optimized });

        }, (response) => {
            const optimized = { products: undefined, earned: undefined };
            dispatch({ type: 'SET_OPTIMIZED', data: optimized });
            dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Ocorreu um erro ao carregar os produtos otimizados. Tente novamente ou entre em contato com o suporte.` } });
        }, (error) => {
            const optimized = { products: undefined, earned: undefined };
            dispatch({ type: 'SET_OPTIMIZED', data: optimized });
            dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Ocorreu um erro ao carregar os produtos otimizados. Ref: ${error}.` } });
        });



    });


    // useEffect(() => {
    //     myGet(`${baseUrl}/modules/optimize/opportunities/get_opportunities_quantity.php?priceDifference=0.10`, (response) => {
    //         const opportunitiesQuantity = response.data.count;
    //         const potentialOpportunities = response.data.sum;
    //         const newOpportunities = { quantity: opportunitiesQuantity, potential: potentialOpportunities };
    //         dispatch({ type: 'SET_OPPORTUNITIES', data: newOpportunities });

    //     }, () => {
    //         const newOpportunities = { quantity: undefined, potential: undefined };
    //         dispatch({ type: 'SET_OPPORTUNITIES', data: newOpportunities });
    //         dispatch({ type: 'SET_DIALOG', data: { type: 'fail', text: 'Ocorreu um erro ao carregar as oportunidades.', status: 'visible' } });
    //     }, (error) => {
    //         dispatch({ type: 'SET_DIALOG', data: { status: 'visible', text: `Não foi possível carregar as oportunidades. Ref: ${error}`, type: 'fail' } });
    //         const newOpportunities = { quantity: undefined, potential: undefined };
    //         dispatch({ type: 'SET_OPPORTUNITIES', data: newOpportunities });

    //     });
    // });


    // useEffect(() => {
    //     myGet(`${baseUrl}/modules/optimize/prices/get_optimize_prices_quantity.php?priceDifference=0.10`, (response) => {
    //         const pricesQuantity = response.data.count;
    //         const potentialPrices = response.data.sum;
    //         const newPrices = { quantity: pricesQuantity, potential: potentialPrices };
    //         dispatch({ type: 'SET_PRICES', data: newPrices });

    //     }, () => {
    //         const newPrices = { quantity: undefined, potential: undefined };
    //         dispatch({ type: 'SET_PRICES', data: newPrices });
    //         dispatch({ type: 'SET_DIALOG', data: { type: 'fail', text: 'Ocorreu um erro ao carregar as oportunidades de otimização de preços.', status: 'visible' } });
    //     }, (error) => {
    //         dispatch({ type: 'SET_DIALOG', data: { status: 'visible', text: `Não foi possível carregar as oportunidades de otimização de preço. Ref: ${error}`, type: 'fail' } });
    //         const newPrices = { quantity: undefined, potential: undefined };
    //         dispatch({ type: 'SET_PRICES', data: newPrices });

    //     });
    // });



    return (
        <>
        <Routes />
        <Feedback />
        </>
    )
}
