import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineEye, AiOutlineArrowDown } from 'react-icons/ai';
import Loading from '../Loading';
import ViewProducts from './ViewProducts';
import Overlay from '../Overlay.js';
import { toggleView, myGet } from '../../GeneralFunctions';

export default function NewSales() {

    const lostSales = useSelector(state => state.lostSales);
    const baseUrl = useSelector(state => state.baseUrl);
    const [showProducts, setShowProducts] = useState(false);
    const [loadingSales, setLoadingSales] = useState(false);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const productsLostSales = useSelector(state => state.productsLostSales);

    const dispatch = useDispatch();


    useEffect(() => {
        if (!lostSales) {
            setLoadingSales(true);
            myGet(`${baseUrl}/modules/dashboard/get_lost_sales.php`, (response) => {
                dispatch({ type: 'SET_LOST_SALES', data: response.data });
                setLoadingSales(false);
            }, () => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Ocorreu um erro ao carregar os produtos que deixaram de ser vendidos.' } })
                setLoadingSales(false);
            }, (err) => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Não foi possível enviar a requisição para carregar os produtos que deixaram de ser vendidos. Ref: ${err}` } })
                setLoadingSales(false);
            });

        }
    }, [lostSales]);




    const loadProducts = function () {


        if (!productsLostSales) {
            setLoadingProducts(true);

            myGet(`${baseUrl}/modules/dashboard/load_lost_sales_products.php`, (response) => {
                dispatch({ type: 'SET_PRODUCTS_LOST_SALES', data: response.data });
                setLoadingProducts(false);
            }, () => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Ocorreu um erro ao carregar estes produtos.' } })
                setLoadingProducts(false);
            }, (err) => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Não foi possível enviar a requisição para carregar estes produtos. Ref: ${err}` } })
                setLoadingProducts(false);
            });
        }

        toggleView(showProducts, setShowProducts);


    }




    return (
        <>
            <div className='container salesVariation' id='lostSalesContainer'>
                {loadingSales ?
                    <Loading />
                    :
                    <>

                        <div className='salesBigNumber'>
                            <AiOutlineArrowDown size={20} />{lostSales}
                            <span>
                                <h3 className="containerInsideTitleBig">Produtos deixaram de ser vendidos em 24h</h3>
                            </span>
                        </div>
                        <div>
                            {loadingProducts ?
                                <Loading />
                                :
                                <button onClick={() => loadProducts()} className='blueBtn' style={{ width: '100%' }}>
                                    <AiOutlineEye /> Ver produtos
                            </button>
                            }
                        </div>

                    </>
                }
            </div>
            {
                showProducts &&
                productsLostSales &&
                <>
                    <Overlay toggleView={() => toggleView(showProducts, setShowProducts)} />
                    <ViewProducts toggleView={() => toggleView(showProducts, setShowProducts)} title={`Produtos que deixeram de ser vendidos (${lostSales})`} data={productsLostSales} />
                </>
            }
        </>

    )
}
