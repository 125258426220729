import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import Menu from '../Menu/Menu'
import Overlay from '../Overlay';
import { AiOutlineSearch, AiOutlineEye } from 'react-icons/ai';

import { useSelector, useDispatch } from 'react-redux';
import Product from './Product.js';
import { FaInbox } from "react-icons/fa";
import { GrInbox } from "react-icons/gr";
import EditProduct from './LateralProductView/EditProductModal';
import Dialog from '../Dialog';
import Summary from '../Summary/Summary';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { toggleView } from '../../GeneralFunctions';
import LateralProductView from './LateralProductView/LateralProductView';
import Footer from './Footer';
import useProducts from '../../hooks/products/useProducts';
import ProductsHeaderCell from './ProductsHeaderCell';
import { FiBox } from 'react-icons/fi';

export default function Products() {

    const products = useSelector(state => state.products);

    const baseUrl = useSelector(state => state.baseUrl);
    const loadingProducts = useSelector(state => state.loadingProducts);
    const query = useSelector(state => state.queryProduct);
    const filters = useSelector(state => state.queryFilters);
    const editableProduct = useSelector(state => state.editableProduct);
    const order = useSelector(state => state.queryOrder);  // [filter, 'desc | asc' ]
    const session = useSelector(state => state.session);
    const activeProduct = useSelector(state => state.activeProduct);

    const [productIndex, setProductIndex] = useState(0);

    const [searchValue, setSearchValue] = useState(useSelector(state => state.queryProduct).term);


    const dispatch = useDispatch();
    const loadProducts = useProducts();


    useEffect(() => {
        if (!products) {
            loadProducts();
        }
    }, [products, baseUrl]);

    useEffect(() => {
        //Calcula qual será o indice do primeiro produto.
        //Se a página for 1, começa do 1... se a página for 30, começa do 2900!
        setProductIndex((((parseInt(filters.page) - parseInt(1)) * parseInt(20))) + parseInt(1));
    }, [filters]);

    /* Adiciona o value do input e muda o state para o termo pesquisado */
    const handleSearch = function (value) {
        setSearchValue(value);
        dispatch({ type: 'SET_PRODUCTS_QUERY', term: value });
    }

    const search = function (e) {
        if (e.key === 'Enter') {
            loadProducts(filters, query, order);
        }
    }

    const handleStatus = function (value) {
        var newFilters = { ...filters, status: value, page: 1 };
        dispatch({ type: 'SET_PRODUCTS_FILTERS', filters: newFilters });
        loadProducts(newFilters, query, order);
    }


    const toggleLateralView = (product, e) => {

        let role = e.target.getAttribute('role');
        if (role == 'netshoes-link' || role == 'sku' || role == 'title') return;
        const selection = window.getSelection();

        if (selection.type != "Range") {
            document.querySelector('body').style.overflowY = 'hidden';
            if (!activeProduct) return dispatch({ type: 'SET_DATA', data: { activeProduct: product } });
            if (activeProduct.id === activeProduct.id) {
                document.querySelector('body').style.overflowY = 'auto';
                return dispatch({ type: 'SET_DATA', data: { activeProduct: false } });
            }

            document.querySelector('body').style.overflowY = 'hidden';
            return dispatch({ type: 'SET_DATA', data: { activeProduct: product } });
        }
    }



    return (
        <>

            <Header pageTitle='Produtos' pageIcon={<FiBox size={16} />} />
            <Menu active='products' />

            <div className='pageContent'>
                <div id='productsContainer'>
                    <div id='products-header' >
                        <div className='title'>
                            <h3 className='containerTitleSmall'>Meus Produtos</h3>
                        </div>
                        <div className='status'>
                            <select value={filters.status} onChange={(e) => handleStatus(e.target.value)} className='basicInput'>
                                <option value={1}>Todos os produtos</option>
                                <option value={2}>Vendendo</option>
                                <option value={3}>Perdendo</option>
                                <option value={4}>Alerta</option>
                                <option value={5}>Netshoes/Zattini</option>
                                {session.allow_unavailable === 't' && <option value={6}>Indisponível/Sem Estoque</option>}
                                <option value={7}>Desconhecidos</option>
                                <option value={8}>Adicionados nos últimos 7 dias</option>
                                <option value={9}>Adicionados manualmente</option>
                            </select>
                        </div>
                        <div className='search'>
                            <input type='text' onChange={(e) => handleSearch(e.target.value)} onKeyPress={(e) => search(e)} value={searchValue} style={{ border: 0 }} className='basicInput' placeholder='Pesquisar produto' />
                            <button className='iconBtn'>
                                <AiOutlineSearch />
                            </button>
                        </div>
                    </div>

                    <div className='productsContent'>
                        <ProductsHeaderCell />


                        {loadingProducts ?
                            <div style={{ width: '100%', height: 400, display: 'flex', backgroundColor: '#FFF', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={`${baseUrl}/icons/loading2.gif`} style={{ width: 40, height: 40 }} />
                            </div>
                            :
                            products.length > 0 ?
                                products.map((product, index) =>
                                    <Product toggleLateralView={toggleLateralView} page={filters.page} key={index} index={parseInt(productIndex) + parseInt(index)} data={product} />
                                )
                                :
                                <div className='cell'>Nenhum produto disponível.</div>
                        }

                        <Footer />


                    </div>
                </div>
                {/* <Summary /> */}
                <LateralProductView toggleLateralView={toggleLateralView} />
            </div >

            <Dialog />

        </>
    )
}
