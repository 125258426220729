import React from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "../Feedback.module.scss";
import classNames from "classnames";
// import { setScore } from "../../../store/feedbackReducer/feedbackReducer";
import { IStore } from "../../../store/IStore";

export default function Evaluation() {
  

  const selectedOption = useSelector(
    (state: IStore) => state.feedback.formData.score
  );
  const dispatch = useDispatch();

  const handleScore = (value: number) => {
    dispatch({ type: "SET_FEEDBACK_SCORE", data: value });
  };

  return (
    <>
      <div id="satisfaction" className={style.feedback__satisfaction}>
        <div className={style.feedback__satisfaction__header}>
          <h3>Como está sendo a sua experiência usando a Hooklab?</h3>
          <div className={style.feedback__satisfaction__header__sub}>
            Estamos trazendo melhorias e queremos saber como tem sido sua
            experiência com a plataforma
          </div>
        </div>
        <div className={style.feedback__satisfaction__options}>
          {avaliationOptions.map((opt) => {
            return (
              <button
                onClick={() => handleScore(opt.score)}
                className={
                  selectedOption === opt.score
                    ? classNames({
                        [style.feedback__satisfaction__options__option_selected]:
                          true,
                      })
                    : classNames({
                        [style.feedback__satisfaction__options__option]: true,
                        "has-hover": true,
                      })
                }
              >
                <h3>{opt.title}</h3>
                <h4>{opt.description}</h4>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}

const avaliationOptions = [
  {
    title: "😡",
    description: "Péssima",
    score: 1,
  },
  {
    title: "🙁",
    description: "Ruim",
    score: 2,
  },
  {
    title: "😐",
    description: "Neutra",
    score: 3,
  },
  {
    title: "🙂",
    description: "Boa",
    score: 4,
  },
  {
    title: "😀",
    description: "Excelente",
    score: 5,
  },
];
