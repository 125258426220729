import React from 'react'
import Header from './Header/Header'
import Menu from './Menu/Menu'

import { AiOutlineRobot } from 'react-icons/ai'

export default function InactiveAccount() {
    return (
        <>

            <Header pageTitle='' />

            <div className='pageContent'>
                <div id='notFoundContainer'>

                    <div><AiOutlineRobot size={128} /></div>
                    <div className='notFoundTitle'>A sua conta foi desativada</div>
                    <div className='text'>Se você deseja obter mais informações ou ativar novamente a sua conta, entre em contato com o suporte.</div>
                </div>
            </div>
        </>
    )
}
