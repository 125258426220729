import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';



export default function AddProductModal({ toggleView }) {

    const baseUrl = useSelector(state => state.baseUrl);
    const dispatch = useDispatch();
    const session = useSelector(state => state.session);

    const error = (message) => dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: message } });

    const [formValue, setFormValue] = useState([

        {
            requested: true,
            value: '',
            name: 'sku-netshoes',
            placeholder: 'SKU Netshoes'
        },
        // {
        //     requested: true,
        //     value: '',
        //     name: 'title',
        //     placeholder: 'Título',
        // },
        {
            requested: true,
            value: 'netshoes-zattini',
            name: 'marketplace',
            placeholder: 'Marketplace',
            type: 'select'
        },
        {
            requested: false,
            value: '',
            name: 'sku-seller',
            placeholder: 'SKU Seller'

        },
        {
            requested: false,
            value: '',
            name: 'cost-price',
            placeholder: 'Preço de Custo',

        },
        {
            requested: false,
            value: '',
            name: 'seller-price',
            placeholder: 'Preço de Venda'
        },
        {
            requested: false,
            value: '',
            name: 'brand',
            placeholder: 'Marca'
        },
        {
            requested: false,
            value: '',
            name: 'category',
            placeholder: 'Categoria'
        },

    ]);

    const handleSubmit = (e) => {

        e.preventDefault();

        let rejected = formValue.filter(input => input.requested === true && input.value.length === 0);

        if (rejected.length > 0) {
            return error('Preencha todos os campos obrigatórios para adicionar o produto.');
        }

        let data = new FormData(document.getElementById('add-product-form'))

        fetch(`${baseUrl}/modules/manage/add_product.php?uid=${session.id}`, {
            method: 'POST',
            body: data,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        }).then(response => response.json()).then(response => {

            if (response.http_code === '200') {
                toggleView();
                dispatch({ type: 'SET_DIALOG', data: { type: 'success', status: 'visible', text: 'Produto adicionado com sucesso' } });
            } else {
                return error(response.message)
            }

        }).catch(err => {
            console.log(err.message);
            return error('Aconteceu um erro na requisição. Tente mais tarde ou entre em contato com o suporte.');
        });
    }

    return (
        <div className='modal' id='addProductModal'>
            <div className='modalHeader'>
                <div className='title'>
                    <h3 className='containerTitleSmall'>Adicionar Produto</h3>
                </div>
                <div className='close'>
                    <button className='iconBtn' onClick={() => toggleView()}>
                        <MdClose size={18} />
                    </button>
                </div>
            </div>
            <div className='modalContent' id='exportPricesContent'>
                <form method='POST' onSubmit={handleSubmit} id='add-product-form'>

                    <ul>
                        {formValue.map(input =>
                            <li>{
                                input.type === 'select' ?
                                    <Select formValue={formValue} setFormValue={setFormValue} placeholder={input.placeholder} name={input.name} requested={input.requested ? true : false}>
                                        <option value='netshoes-zattini'>Netshoes e Zattini</option>
                                        <option value='netshoes'>Apenas Netshoes</option>
                                        <option value='zattini'>Apenas Zattini</option>
                                    </Select>
                                    :
                                    <Input
                                        formValue={formValue}
                                        setFormValue={setFormValue}
                                        requested={input.requested}
                                        name={input.name}
                                        placeholder={input.placeholder}
                                    />
                            }</li>
                        )}

                        {/* <li>
                            <Input formValue={formValue} setFormValue={setFormValue} requested name='title' placeholder='Título' />
                        </li>
                        <li>

                        </li>

                        <li>
                            <Input formValue={formValue} setFormValue={setFormValue} requested name='sku-seller' placeholder='SKU Seller' />
                        </li>
                        <li>
                            <Input formValue={formValue} setFormValue={setFormValue} name='cost-price' placeholder='Preço de Custo' />
                        </li>
                        <li>
                            <Input formValue={formValue} setFormValue={setFormValue} requested name='seller-price' placeholder='Preço de Venda' />
                        </li> */}
                        <li >
                            <div style={{ flex: 1 }}>
                                <span style={{ color: 'red', fontSize: '1.4rem' }}>*</span> campos obrigatórios
                            </div>
                            <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}>
                                <button className='blueBtn'>Adicionar</button>
                            </div>
                        </li>


                    </ul>
                </form>

            </div>
        </div >

    )
}


const Input = React.memo(({ placeholder, name, requested, formValue, setFormValue }) => {



    const handleValue = (value) => {

        const newFormValue = formValue.map(input => {
            if (input.name === name) {
                return { ...input, value: value };
            } else {
                return input
            }
        })

        setFormValue(newFormValue);

    }

    return (
        <div className='input'>
            <div className='title'>
                <span className='label'>{placeholder}
                    <span style={{ color: 'red' }}>{requested ? '*' : ''}</span>
                </span>
            </div>
            <div>
                <input
                    type='text'
                    placeholder={placeholder}
                    name={name}
                    value={formValue.find(d => d.name === name).value}
                    onChange={(e) => handleValue(e.target.value)}
                />
            </div>

        </div>
    );

});

const Select = ({ children, name, placeholder, requested, formValue, setFormValue }) => {


    const handleValue = (value) => {


        const newFormValue = formValue.map(input => {
            if (input.name === name) {
                return { ...input, value: value };
            } else {
                return input
            }
        })

        setFormValue(newFormValue);

    }


    return (
        <div className='input'>
            <div className='title'>
                <span className='label'>{placeholder}
                    <span style={{ color: 'red' }}>{requested && '*'}</span>
                </span>
            </div>
            <div>
                <select
                    className='select'
                    name={name}
                    value={formValue.find(d => d.name === name).value}
                    onChange={(e) => handleValue(e.target.value)}
                >
                    {children}
                </select>
            </div>
        </div>
    );

}
