import React from "react";
import Comment from "./Comment";
import Score from "./Score";
import { useEffect, useState } from "react";
import { WizardSteps } from "./WizardSteps";
import style from "./Feedback.module.scss";

import { useDispatch, useSelector } from "react-redux";

// import {
//   resetFeedbackState,
//   setLastFeedback,
//   setViewFeedback,
// } from "../../store/feedbackReducer/feedbackReducer";

import useDisplayFeedback from "./useDisplayFeedback";

import FeedbackSentSVG from "../../images/feedback-sent.svg";
import feedbackConfig from "./feedback-config.json";
import Overlay from "../Overlay";
import Loading from "../Loading";
import { usePost } from "../../hooks/http/usePost";
import { IStore } from "../../store/IStore";
import ReactTooltip from "react-tooltip";

export default function FeedbackModal() {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const formData = useSelector((state: IStore) => state.feedback.formData);
  const baseUrl = useSelector((state: IStore) => state.baseUrl);

  const post = usePost();
  const dispatch = useDispatch();

  const displayFeedbackWindow = useDisplayFeedback()();

  const handleNext = () => {
    if (activeStep < WizardPages.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const cancel = () => {
    console.log({
      d: !displayFeedbackWindow.isRequired,
      f: finished,
    });

    if (!displayFeedbackWindow.isRequired || finished) {
      dispatch({ type: "SET_VIEW_FEEDBACK", data: false });
      dispatch({ type: "RESET_FEEDBACK_STATE", data: false });
    }
  };

  const finish = async () => {
    try {
      setLoading(true);
      let id = displayFeedbackWindow.isRequired ? feedbackConfig.id : "normal";

      const data = new FormData();

      data.append("comment", formData.comment);
      data.append("score", String(formData.score));
      data.append("id", id);

      const request = await fetch(
        `${baseUrl}/modules/feedback/send-feedback.php`,
        {
          method: "POST",
          body: data,
        }
      );

      const response = await request.json();

      if (response.http_code == "200") {
        setLoading(false);
        setFinished(true);
        dispatch({ type: "SET_LAST_FEEDBACK", data: { ...formData, id } });
      }

      return;
    } catch (error) {
      setLoading(false);
      //@ts-ignore
      alert(error.message);
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    //@ts-ignore
    body.style.overflowY = "hidden";
    return () => {
      //@ts-ignore
      body.style.overflowY = "auto";
    };
  }, [displayFeedbackWindow]);

  const isLastStep = activeStep === WizardSteps.length - 1;

  return (
    <>
      <Overlay toggleView={cancel} />
      <div className="confirm-box" style={{ width: "600px" }}>
        {loading ? (
          <Loading text='Enviando...' />
        ) : (
          <div className={style.feedback}>
            {finished ? (
              <div className={style.feedback__feedback_sent}>
                <h2>Sua avaliação foi enviada</h2>
                <span>Agradecemos pelo feedback!</span>
                <div>
                  <img
                    src={FeedbackSentSVG}
                    alt="Sua avaliação foi encaminhada"
                  />
                </div>
                <div>
                  <button onClick={cancel} className="blueBtn">
                    Finalizar
                  </button>
                </div>
              </div>
            ) : (
              <>
                {WizardPages[activeStep]}
                <div className={style.feedback__buttons}>
                  {activeStep > 0 ? (
                    <button
                      onClick={() => handlePrevious()}
                      style={{ marginRight: 0 }}
                      className="whiteBtn"
                    >
                      {isLastStep ? "Voltar" : "Cancelar"}
                    </button>
                  ) : (
                    <i></i>
                  )}

                  <button
                    title={
                      formData.score
                        ? `Continuar`
                        : `Seleciona uma opção para continuar`
                    }
                    className="blueBtn"
                    onClick={() => (isLastStep ? finish() : handleNext())}
                    disabled={!formData.score}
                  >
                    {activeStep === WizardSteps.length - 1
                      ? "Enviar"
                      : "Próximo"}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

const WizardPages = [<Score />, <Comment />];

// <Stepper className="stepper" activeStep={activeStep}>
// {WizardSteps.map((step, index) => {
//     const stepProps: { completed?: boolean } = {};
//     const labelProps: {
//         optional?: React.ReactNode;
//     } = {};
//     return (
//         <Step
//             key={step.label}
//             {...stepProps}
//             className="step"
//         >
//             <StepLabel
//                 className="step-label"
//                 {...labelProps}
//             >
//                 {step.description}
//             </StepLabel>
//         </Step>
//     );
// })}
// </Stepper>
