import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Pie } from 'react-chartjs-2';
import { FaInbox } from 'react-icons/fa';
import { MdArrowUpward, MdArrowDownward, MdShoppingCart, MdReportProblem, MdHelpOutline, MdBlock } from 'react-icons/md';

export default function Summary(props) {

    const summary = useSelector(state => state.summary);
    const baseUrl = useSelector(state => state.baseUrl);
    const dispatch = useDispatch();
    const loadingProducts = useSelector(state => state.loadingProducts);
    const loadingSummary = useSelector(state => state.loadingSummary);
    const session = useSelector(state => state.session);



    /* carrega o resumo */

    useEffect(() => {
        if (!summary) {
            dispatch({ type: 'LOADING_SUMMARY', status: true });

            fetch(`${baseUrl}/modules/summary/get_summary_data.php`).then(response => response.json()).then(response => {

                dispatch({ type: 'SET_USER_SUMMARY', data: response.data });
                dispatch({ type: 'LOADING_SUMMARY', status: false });
            });

        }
    }, [summary, baseUrl]);



    if (summary) {
        var data = {
            labels: [
                'Vendendo',
                'Alerta',
                'Perdendo',
                'Netshoes',
                'Desconhecido',
                'Indisponível',
            ],
            datasets: [{
                data: [summary.selling, summary.alert, summary.losing, summary.netshoes, summary.unknown, summary.unavailable],
                backgroundColor: [
                    '#00800091',
                    '#ffa50091',
                    '#ff00008a',
                    '#6607668a',
                    'silver',
                    'rgba(62, 62, 62, 0.7)',

                ],
                hoverBackgroundColor: [
                    'green',
                    'orange',
                    'red',
                    'purple',
                    'silver',
                    'rgba(62, 62, 62, 0.7)',
                ]
            }]
        }
    } else {
        var data = {};
    }





    const loadProducts = function (status) {

        if (loadingProducts === false) {
            var filters = { page: 1, status: status }
            dispatch({ type: 'LOADING_PRODUCTS', status: true });
            dispatch({ type: 'SET_PRODUCTS_FILTERS', filters: filters })

            var data = new FormData();
            data.append("filters", JSON.stringify(filters));

            fetch(`${baseUrl}/modules/products/get_products.php`, {
                method: "POST",
                body: data

            }).then(response => response.json()).then(response => {
                dispatch({ type: 'SET_USER_PRODUCTS', data: response.data, count: response.resultsCount });
                dispatch({ type: 'LOADING_PRODUCTS', status: false });
            });
        }
    }



    return (
        <div id='summaryContainer' className='container'>

            {loadingSummary ?
                <div className='loadingSummary'>
                    <img src={`${baseUrl}/icons/loading2.gif`} style={{ width: 40, height: 40 }} />
                </div>
                :
                <>
                    <div className='containerHeader'>
                        <div className='title'>
                            <h3 className='containerInsideTitle'>Resumo</h3>
                        </div>
                    </div>
                    <div className='chartArea'>
                        <Pie width={150} height={150} options={{ maintainAspectRatio: false }} legend={false} data={data} />
                    </div>
                    <div className='summaryList'>
                        <div className='summaryRow total'>
                            <div className='rowTitle'>
                                <FaInbox />
                                <a href='#' onClick={() => loadProducts(1)}>Disponíveis</a>
                            </div>
                            <div className='rowData'>
                                <a href='#' onClick={() => loadProducts(1)}>{summary.total}</a>
                            </div>
                        </div>

                        <div className='summaryRow selling'>
                            <div className='rowTitle'>
                                <MdArrowUpward />
                                <a href='#' onClick={() => loadProducts('2')}>Vendendo</a>
                            </div>
                            <div className='rowData'>
                                <a href='#' onClick={() => loadProducts('2')}>{summary.selling}
                                    <span>
                                        ({summary.sellinginpercent}%)
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className='summaryRow alert'>
                            <div className='rowTitle'>
                                <MdReportProblem />
                                <a href='#' onClick={() => loadProducts(4)}>Alerta</a>
                            </div>
                            <div className='rowData'>
                                <a href='#' onClick={() => loadProducts(4)}>{summary.alert}
                                    <span>
                                        ({summary.alertinpercent}%)
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className='summaryRow losing'>

                            <div className='rowTitle'>
                                <MdArrowDownward />
                                <a href='#' onClick={() => loadProducts(3)}>Perdendo</a>
                            </div>
                            <div className='rowData'>
                                <a href='#' onClick={() => loadProducts(3)}>{summary.losing}
                                    <span>
                                        ({summary.losinginpercent}%)
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className='summaryRow netshoes'>
                            <div className='rowTitle'>
                                <MdShoppingCart />
                                <a href='#' onClick={() => loadProducts(5)}>Netshoes/Zattini</a>
                            </div>
                            <div className='rowData'>
                                <a href='#' onClick={() => loadProducts(5)}>
                                    {summary.netshoes}
                                    <span>
                                        ({summary.netshoesinpercent}%)
                                    </span>
                                </a>
                            </div>
                        </div>

                        {session.allow_unavailable === 't' &&
                            <div className='summaryRow unavailable'>
                                <div className='rowTitle'>
                                    <MdBlock />
                                    <a href='#' onClick={() => loadProducts(6)}>Indisponível/Sem Estoque</a>
                                </div>
                                <div className='rowData'>
                                    <a href='#' onClick={() => loadProducts(6)}>
                                        {summary.unavailable}
                                    </a>
                                </div>
                            </div>
                        }


                        <div className='summaryRow unknown'>
                            <div className='rowTitle'>
                                <MdHelpOutline />
                                <a href='#' onClick={() => loadProducts(7)}>Desconhecido</a>
                            </div>
                            <div className='rowData'>
                                <a href='#' onClick={() => loadProducts(7)}>
                                    {summary.unknown}
                                    <span>
                                        ({summary.unknowninpercent}%)
                                    </span>
                                </a>
                            </div>
                        </div>

                    </div>
                </>

            }

            {props.children}
        </div>

    )
}
