import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Loading from '../Loading';

export default function ConfirmBox({ text, confirm, cancel, loading, setLoading, loadingMessage }) {

    return (
        <>
            <div className="confirmation-overlay" onClick={() => cancel()}></div>
            <div className="confirm-box">
                {loading ?
                    <Loading message={loadingMessage} />
                    :
                    <>
                        <div className="title">
                            <div>Confirmação necessária</div>
                            <div className="cancel"><button onClick={() => cancel()}><AiOutlineClose size={14} /></button></div>
                        </div>
                        <div className="text">{text}</div>
                        <div className="buttons">
                            <button className='blueBtn' onClick={() => confirm()}>Confirmar</button>
                            <button onClick={() => cancel()} style={{ marginRight: 0 }} className="whiteBtn">Cancelar</button>
                        </div>
                    </>
                }
            </div>
        </>
    );
}
