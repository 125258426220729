import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaEye } from 'react-icons/fa';
import { AiOutlineEye, AiOutlineArrowUp } from 'react-icons/ai';
import Loading from '../Loading';
import ViewProducts from './ViewProducts';
import Overlay from '../Overlay.js';
import { toggleView, myGet } from '../../GeneralFunctions';

export default function NewSales() {

    const newSales = useSelector(state => state.newSales);
    const baseUrl = useSelector(state => state.baseUrl);
    const [showProducts, setShowProducts] = useState(false);
    const [loadingSales, setLoadingSales] = useState(false);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const productsNewSales = useSelector(state => state.productsNewSales);

    const dispatch = useDispatch();


    useEffect(() => {
        if (!newSales) {
            setLoadingSales(true);
            myGet(`${baseUrl}/modules/dashboard/get_new_sales.php`, (response) => {
                dispatch({ type: 'SET_NEW_SALES', data: response.data });
                setLoadingSales(false);
            }, () => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Ocorreu um erro ao carregar as novas vendas.' } })
                setLoadingSales(false);
            }, (err) => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Não foi possível enviar a requisição para carregar as novas vendas. Ref: ${err}` } })
                setLoadingSales(false);
            });
        }
    }, [newSales, baseUrl]);






    const loadProducts = function () {


        toggleView(showProducts, setShowProducts);

        if (!productsNewSales) {
            setLoadingProducts(true);

            myGet(`${baseUrl}/modules/dashboard/load_new_sales_products.php`, (response) => {
                dispatch({ type: 'SET_PRODUCTS_NEW_SALES', data: response.data });
                setLoadingProducts(false);
            }, () => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Ocorreu um erro ao carregar as novas vendas.' } })
                setLoadingProducts(false);
            }, (err) => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Não foi possível enviar a requisição para carregar as novas vendas. Ref: ${err}` } })
                setLoadingProducts(false);
            });

        }



    }



    return (
        <>
            <div className='container salesVariation' id='newSalesContainer'>
                {loadingSales ?
                    <Loading />
                    :
                    <>

                        <div className='salesBigNumber'>
                            <AiOutlineArrowUp size={20} />{newSales}
                            <span>
                                <h3 className="containerInsideTitleBig">Produtos passaram a ser vendidos em 24h</h3>
                            </span>
                        </div>
                        <div>
                            {loadingProducts ?
                                <Loading />
                                :
                                <button onClick={() => loadProducts()} className='blueBtn' style={{ width: '100%' }}>
                                    <AiOutlineEye /> Ver produtos
                                </button>
                            }
                        </div>

                    </>
                }
            </div>
            {
                showProducts &&
                productsNewSales &&
                <>
                    <Overlay toggleView={() => toggleView(showProducts, setShowProducts)} />
                    <ViewProducts toggleView={() => toggleView(showProducts, setShowProducts)} title={`Produtos que passaram a ser vendidos (${newSales})`} data={productsNewSales} />
                </>
            }
        </>

    )
}
