import React from 'react';
import Config from './Config.js';
import { Provider } from 'react-redux';
import store from './store';


import './css/App.css';
import './css/Header.css';
import './css/Menu.css';
import './css/Products.css';
import './css/EditProducts.css';
import './css/Modal.css';
import './css/Dialog.css';
import './css/Summary.css';
import './css/Manage.css';
import './css/Account.css';
import './css/Dashboard.css';
import './css/Support.css';
import './css/Optimize.css';
import './css/Report.css';
import './css/NotFound.css';
import './css/PopupCentauro.css';
import './css/Confirm.css';
import './css/PlanError.css';


function App() {
  return (
    <Provider store={store}>
      <Config />
    </Provider>
  );
}

export default App;
