import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'
import Menu from '../Menu/Menu'
import { IoIosPricetags } from 'react-icons/io'
import Opportunities from './Opportunities/Opportunities'
import Prices from './Prices/Prices'
import Earned from './Prices/Earned'
import OptimizedProducts from './OptimizedProducts'
import { MdUpdate } from 'react-icons/md'
import { myGet, formatDatetime } from '../../GeneralFunctions'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../Loading'

export default function Optimize() {

    const [updatePricesValue, setUpdatePricesValue] = useState('Atualizar preços');
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const updatePricesHistoric = useSelector(state => state.updatePricesHistoric);
    const session = useSelector(state => state.session);
    const baseUrl = useSelector(state => state.baseUrl);
    const dispatch = useDispatch();



    const handleFileInput = function (value) {
        let filename = value.split('\\').pop() || 'Atualizar preços';
        setUpdatePricesValue(filename);
    }

    const uploadFile = function () {

        let data = new FormData(document.getElementById('updatePricesForm'));
        setLoadingUpdate(true);

        fetch(`https://api-netshoes.hooklab.com.br/upload/etl-netshoes/file-name/preco/user/${session.id}`, {
            method: 'post',
            body: data
        }).then(response => response.text()).then(response => {



            if (response === '200') {


                if (session.platform === 'tray' || session.platform === 'placemarket') {

                    myGet(`${baseUrl}/modules/manage/importer/update_new_charge.php`, (response) => {
                        dispatch({ type: 'SET_DIALOG', data: { type: 'success', status: 'visible', text: 'Os dados foram sincronizados com sucesso. Em breve os seus produtos serão atualizados.' } });
                        setLoadingUpdate(false);
                    }, (response) => {
                        dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Ocorreu um erro: ${response}. Tente novamente ou entre em contato com o suporte.` } });
                        setLoadingUpdate(false);
                    }, (error) => {
                        dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Não foi possível enviar a requisição para alteração. ${error}.` } });
                        setLoadingUpdate(false);
                    });

                } else {

                    if (session.id === '1') {
                        var etlUrl = `https://api-netshoes.hooklab.com.br/etl-netshoes/user/${session.id}/platform/moovin/estoque-netshoes/price/true`;
                    } else {
                        var etlUrl = `https://api-netshoes.hooklab.com.br/etl-netshoes/user/${session.id}/platform/${session.platform}/price/true`;
                    }

                    fetch(etlUrl).then(response => response.text()).then(response => {
                        if (response === '200') {
                            setLoadingUpdate(false);
                            dispatch({ type: 'SET_DIALOG', data: { type: 'success', text: `Preços atualizados com sucesso! A página será atualizada.`, status: 'visible' } });
                            setTimeout(() => window.location.reload(), 5000);
                        } else {
                            setLoadingUpdate(false);
                            dispatch({ type: 'SET_DIALOG', data: { type: 'fail', text: `Não foi possível tratar a planilha. Ref: ${response}.`, status: 'visible' } });
                        }
                    });
                }

            } else {
                setLoadingUpdate(false);
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', text: `Não foi possível fazer o upload desta planilha. Ref: ${response}.`, status: 'visible' } });
            }
        });
    }


    useEffect(() => {
        if (!updatePricesHistoric) {
            console.log('query => ');
            myGet(`${baseUrl}/modules/optimize/get_update_prices_historic.php`, (response) => {
                console.log(response.data);
                dispatch({ type: 'SET_UPDATE_PRICES_HISTORIC', data: response.data });
            }, (response) => {
                alert(response)
            }, (error) => {
                alert(error);
            });
        }
    }, [updatePricesHistoric]);




    return (
        <>
            <Header pageTitle='Otimizar preços' pageIcon={<IoIosPricetags size={16} />} />
            <Menu active='optimize' />
            <div className='pageContent'>
                <div id='optimizeContainer'>
                    <div className='containerHeader'>
                        <div className='title'>
                            <h3 className='containerTitleSmall'>Oportunidades de otimização de preços</h3>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Earned />
                    </div>
                    <div style={{ display: 'flex', marginBottom: 20 }}>
                        <Opportunities />
                        <Prices />
                    </div>
                    <div>
                        <div className='containerHeader'>
                            <div className='title'>
                                <h3 className='containerTitleSmall'>Produtos otimizados recentemente</h3>
                            </div>

                            {/* <div id='updatePrices'>
                                {loadingUpdate ?
                                    <img src={`${baseUrl}/icons/loading2.gif`} width='24' height='24' />
                                    :
                                    <>
                                        {
                                            updatePricesHistoric ?
                                                updatePricesHistoric.length > 0 ?
                                                    <div id='lastUpdate'>Última importação em {formatDatetime(updatePricesHistoric[updatePricesHistoric.length - 1].import_date)}</div>
                                                    : null
                                                : null
                                        }

                                        < div id='updatePricesInput'>
                                            <form method='post' id='updatePricesForm' encType='multipart/form-data'>
                                                <input onChange={(e) => handleFileInput(e.target.value)} type='file' name='file' />
                                                <button className='blueBtn'>
                                                    <MdUpdate />
                                                    {updatePricesValue}
                                                </button>
                                            </form>
                                        </div>
                                        {updatePricesValue !== 'Atualizar preços' && <div><button className='blueBtn2' id='updatePricesSubmit' onClick={() => uploadFile()}>Enviar</button></div>}
                                    </>
                                }
                            </div> */}
                        </div>
                        <OptimizedProducts />
                    </div>
                </div>
            </div>
        </>
    )
}





