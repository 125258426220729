
import { useEffect } from "react";
import useLastFeedback from "./useLastFeedback";
import feedbackConfig from "./feedback-config.json";
import { useDispatch, useSelector } from "react-redux";
// import { setViewFeedback } from "../../store/feedbackReducer/feedbackReducer";
import { IStore } from "../../store/IStore";

export default function useDisplayFeedback() {
  const loadLastFeedback = useLastFeedback({ id: feedbackConfig.id });
  const dispatch = useDispatch();
  const viewFeedback = useSelector(
    (state: IStore) => state.feedback.viewFeedback
  );
  const lastFeedback = loadLastFeedback();
  const currentDate = Date.now();

  const isRequired =
    feedbackConfig.required &&
    currentDate < new Date(feedbackConfig.expire_date).getTime() &&
    currentDate >= new Date(feedbackConfig.start_date).getTime() &&
    lastFeedback === null;

  const displayFeedbackWindow = isRequired || viewFeedback;

  useEffect(() => {
    if (!viewFeedback && displayFeedbackWindow) {
      dispatch({ type: "SET_VIEW_FEEDBACK", data: true });
    }
  }, [viewFeedback, displayFeedbackWindow]);

  return () => {
    return {
      displayFeedbackWindow,
      isRequired,
    };
  };
}
