import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { formatDatetime } from "../../GeneralFunctions.js";
import { AiOutlineCheckCircle, AiOutlineWarning } from "react-icons/ai";
import Loading from "../Loading";
import { importers } from "./importers";
import { IStore } from "../../store/IStore.js";
import useSync from "./useSync";
import Overlay from "../Overlay.js";

type Sync = {
  upload_date: string;
};

type Props = {
  sync: Sync;
  toggleView: any;
};

export default function ImportDataModal({ sync, toggleView }: Props) {
  const baseUrl = useSelector((state: IStore) => state.baseUrl);
  const session = useSelector((state: IStore) => state.session);
  const loadSync = useSync();

  const [success, setSuccess] = useState("");
  const [importError, setImportError] = useState("");
  const [importLoading, setImportLoading] = useState(false);

  const uploadDataRequest = async (
    spreadsheetName: string,
    formData: FormData
  ) => {
    const request = await fetch(
      `https://api-netshoes.hooklab.com.br/upload/etl-netshoes/file-name/${spreadsheetName}/user/${session.id}`,
      {
        method: "post",
        body: formData,
      }
    );
    const response = await request.text();
    return response;
  };

  const validateFiles = () => {
    let promises: (() => Promise<string>)[] = [];
    importers["netshoes"].forEach((spreadsheet) => {
      const fileInput = document.querySelector(
        `input[name='${spreadsheet.name}']`
      ) as HTMLInputElement;

      const files = fileInput.files as FileList;

      if (spreadsheet.required) {
        if (files.length === 0) {
          throw new Error(
            `Insira a planilha ${spreadsheet.alias} para sincronizar os seus produtos.`
          );
        }
      }

      const file = files[0];

      if (file) {
        if (file.name.split(".").pop() !== spreadsheet.extension)
          throw new Error(
            `Extensão inválida para a planilha ${spreadsheet.alias}.Utilize o formato ${spreadsheet.extension}.`
          );

        const data = new FormData();
        data.append("file", file);
        promises.push(() => uploadDataRequest(spreadsheet.name, data));
      }
    });

    return promises;
  };

  const sendData = async function (e: any) {
    try {
      e.preventDefault();
      setImportLoading(true);

      await updateSync({
        error: false,
        isNew: true,
      });

      let promises = validateFiles();

      const results = await Promise.all(
        promises.map((request) => {
          return request();
        })
      );

      if (results.some((result) => result !== "200")) {
        results.forEach((sps) => {
          if (sps !== "200") {
            throw new Error(sps);
          }
        });
      }

      let hasCostPrice = false;

      const costPriceInput: HTMLInputElement | null = document.querySelector(
        `input[name='template_custo']`
      );
      if (costPriceInput) {
        hasCostPrice = costPriceInput.files
          ? costPriceInput.files.length > 0
          : false;
      }

      const costPriceETLURL = `https://api-netshoes.hooklab.com.br/etl-netshoes/new/user/${session.id}/platform/netshoes-custo`;
      const defaultETLURL = `https://api-netshoes.hooklab.com.br/etl-netshoes/new/user/${session.id}/platform/netshoes`;
      const endpointURL = hasCostPrice ? costPriceETLURL : defaultETLURL;

      if (hasCostPrice) {
        const etlRequest = await fetch(endpointURL);
        const response = await etlRequest.text();
        if (response === "200") {
          if (hasCostPrice) {
            const updateMarkupsRequest = await fetch(
              `${baseUrl}/modules/manage/update_markups_importer.php`
            );
            const response = await updateMarkupsRequest.json();
            if (response.http_code === "200") {
              setImportLoading(false);
              setSuccess(
                "Os dados foram sincronizados com sucesso. Atualize a página."
              );
            } else {
              setImportLoading(false);
              setSuccess(
                "Os dados foram sincronizados com sucesso. Atualize a margem mínima dos seus produtos manualmente na aba configurações."
              );
            }

            await updateSync({
              error: false,
              isNew: false,
            });
          }
        } else {
          throw new Error(`
                  Ocorreu um erro ao sincronizar os seus produtos. Tente novamente ou entre em contato com o suporte. Ref: ${response}. 
                `);
        }
      } else {
        await updateSync({
          error: false,
          isNew: false,
        });
        await loadSync();
        fetch(endpointURL);
        setSuccess(
          "A planilha foi adicionada a fila de processamento com sucesso! Em alguns minutos os seus dados serão atualizados."
        );
        setImportLoading(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        await updateSync({
          error: true,
          isNew: false,
        });
        setImportError(error.message);
        return setImportLoading(false);
      }
    }
  };

  const updateSync = async ({
    error,
    isNew,
  }: {
    error: boolean;
    isNew: boolean;
  }) => {
    const updateSyncDataRequest = await fetch(
      `${baseUrl}/modules/manage/update_sync_data.php?error=${error}&new=${isNew}`
    );
    const updateSyncDataResponse = await updateSyncDataRequest.json();
    if (updateSyncDataResponse.http_code !== "200")
      throw new Error("Ocorreu um erro ao atualizar o status da planilha");
  };

  const exit = () => {
    if (!importLoading) {
      toggleView();
    }
  };

  return (
    <>
      <div className="modal" id="importerModal">
        <div className="modalHeader">
          <div className="title">
            <h3 className="containerTitleSmall">Sincronizar produtos</h3>
          </div>
          <div className="close">
            <button className="iconBtn" onClick={exit}>
              <MdClose size={18} />
            </button>
          </div>
        </div>
        <div className="modalContent">
          <div id="importerContent">
            {importLoading && (
              <div
                style={{
                  position: "absolute",
                  background: "#FFF",
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                  display: "flex",
                  top: 0,
                }}
              >
                <Loading
                  text={
                    "Estamos enviando a planilha... Não feche o navegador. Aguarde alguns instantes..."
                  }
                />
              </div>
            )}

            {success && (
              <div id="success-layer">
                <AiOutlineCheckCircle />
                {success}
              </div>
            )}

            <form id="syncForm" method="post" encType="multipart/form-data">
              <div className="cell">
                <div className="spreadsheetName">Última importação</div>
                <div className="spreadsheetInput">
                  {formatDatetime(sync.upload_date)}
                </div>
              </div>
              {importers["netshoes"].map((spreadsheet) => (
                <div className="cell">
                  <div className="spreadsheetName">{spreadsheet.alias}</div>
                  <div className="spreadsheetInput">
                    <FileInput spreadsheet={spreadsheet} />
                  </div>
                </div>
              ))}
              <div className="cell">
                <div className="spreadsheetError">
                  {importError.length > 0 && (
                    <div>
                      <AiOutlineWarning /> {importError}
                    </div>
                  )}
                </div>
                <div className="spreadsheetSubmit">
                  <button className="blueBtn" onClick={sendData}>
                    Enviar dados
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Overlay toggleView={exit} />
    </>
  );
}

type SpreadsheetField = {
  name: string;
  required: boolean;
  extension: string;
};

const FileInput = ({ spreadsheet }: { spreadsheet: SpreadsheetField }) => {
  const [fileName, setFileName] = useState("");

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filename = e.target.value.split("\\").pop();

    if (filename) {
      if (filename.length > 0) {
        setFileName(filename);
      }
    }
  };
  return (
    <div className="importerButton">
      <input name={spreadsheet.name} type="file" onChange={handleFile} />
      <button className="blueBtn">
        {!fileName
          ? `Selecionar arquivo (.${spreadsheet.extension})`
          : fileName}
      </button>
    </div>
  );
};
