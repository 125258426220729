import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import { calculateVariation } from '../../GeneralFunctions';

export default React.memo(function Performance() {

    const dispatch = useDispatch();

    const performanceData = useSelector(state => state.performanceData);
    const summary = useSelector(state => state.summary);
    const baseUrl = useSelector(state => state.baseUrl);

    const [loadingPerformance, setLoadingPerformance] = useState(!performanceData);
    const [selectedDate, setSelectedDate] = useState(7);
    const [activeChart, setActiveChart] = useState('available')



    const getDatesArray = function (array) {


        const datesArray = array.map((item, index) => {

            const dateSaved = item.date_save.split("-");
            const date = `${dateSaved[2]}/${dateSaved[1]}`;

            if (array.length > 31) {
                if (index % 2 === 0) {
                    return date;
                } else {
                    return date;
                }
            } else {
                return date;
            }
        });

        return datesArray;

    }

    const getDataArray = function (array, type) {
        return array.map(item => item[type]);
    }

    const loadPerformanceData = function (selectedDate) {

        setLoadingPerformance(true);
        fetch(`${baseUrl}/modules/dashboard/load_performance_data.php?date=${selectedDate}`).then(response => response.json()).then(response => {

            if (response.http_code === '200') {
                var dates = getDatesArray(response.data);

                var newPerformanceData = {
                    available: {
                        quantity: response.data[0].available,
                        variation: calculateVariation(summary.total, response.data[0].available),
                        label: 'Produtos disponíveis',
                        data: getDataArray(response.data, 'available'),
                        color: '#000',

                    },
                    selling: {
                        quantity: response.data[0].selling,
                        variation: calculateVariation(summary.selling, response.data[0].selling),
                        label: 'Produtos sendo vendidos',
                        data: getDataArray(response.data, 'selling'),
                        color: 'green',
                    },
                    alert: {
                        quantity: response.data[0].alert,
                        variation: calculateVariation(summary.alert, response.data[0].alert),
                        label: 'Produtos em alerta',
                        data: getDataArray(response.data, 'alert'),
                        color: 'orange',
                    },
                    losing: {
                        quantity: response.data[0].losing,
                        variation: calculateVariation(summary.losing, response.data[0].losing),
                        label: 'Produtos perdendo',
                        data: getDataArray(response.data, 'losing'),
                        color: 'red',
                    },
                    netshoes: {
                        quantity: response.data[0].netshoes,
                        variation: calculateVariation(summary.netshoes, response.data[0].netshoes),
                        label: 'Produtos vendidos pela Netshoes/Zattini',
                        data: getDataArray(response.data, 'netshoes'),
                        color: 'purple',
                    },
                    dates: dates
                };


            } else {

                var newPerformanceData = {
                    available: {
                        quantity: 0,
                        variation: 0,
                        label: 'Produtos disponíveis',
                        data: [],
                        color: '#000',

                    },
                    selling: {
                        quantity: 0,
                        variation: 0,
                        label: 'Produtos sendo vendidos',
                        data: [],
                        color: 'green',
                    },
                    alert: {
                        quantity: 0,
                        variation: 0,
                        label: 'Produtos em alerta',
                        data: [],
                        color: 'orange',
                    },
                    losing: {
                        quantity: 0,
                        variation: 0,
                        label: 'Produtos perdendo',
                        data: [],
                        color: 'red',
                    },
                    netshoes: {
                        quantity: 0,
                        variation: 0,
                        label: 'Produtos vendidos pela Netshoes/Zattini',
                        data: [],
                        color: 'purple',
                    },
                    dates: []
                };

            }

            dispatch({ type: 'SET_PERFORMANCE_DATA', data: newPerformanceData });
            setLoadingPerformance(false);

        });
    }


    useEffect(() => {
        if (summary) {
            if (!performanceData) {
                loadPerformanceData(7);
            }
        }
    }, [performanceData, summary]);





    const verificationActiveChart = (chart) => { return activeChart === chart ? `section activeSection ${chart}` : 'section' };


    return (
        <div id='chartContainer' className='container'>
            {loadingPerformance ?
                <div className='loadingPerformance'>
                    <img src={`${baseUrl}/icons/loading2.gif`} />
                </div>

                :


                performanceData &&
                <>
                    <div className='navigator'>
                        <div style={{ borderTopLeftRadius: 10 }} className={verificationActiveChart('available')} onClick={() => setActiveChart('available')}>
                            <div className='title'>Disponíveis</div>
                            <div className='value'>{summary.total}</div>
                            <div className='variation'>
                                <Variation direction='up' data={performanceData.available.variation} />
                            </div>
                        </div>
                        <div className={verificationActiveChart('selling')} onClick={() => setActiveChart('selling')}>
                            <div className='title'>Vendendo</div>
                            <div className='value'>{summary.selling}</div>
                            <div className='variation'>
                                <Variation direction='up' data={performanceData.selling.variation} />
                            </div>
                        </div>
                        <div className={verificationActiveChart('alert')} onClick={() => setActiveChart('alert')}>
                            <div className='title'>Alerta</div>
                            <div className='value'>{summary.alert}</div>
                            <div className='variation'>
                                <Variation direction='down' data={performanceData.alert.variation} />
                            </div>
                        </div>
                        <div className={verificationActiveChart('losing')} onClick={() => setActiveChart('losing')}>
                            <div className='title'>Perdendo</div>
                            <div className='value'>{summary.losing}</div>
                            <div className='variation'>
                                <Variation direction='down' data={performanceData.losing.variation} />
                            </div>
                        </div>
                        <div style={{ borderTopRightRadius: 10 }}  className={verificationActiveChart('netshoes')} onClick={() => setActiveChart('netshoes')}>
                            <div className='title'>Netshoes/Zattini</div>
                            <div className='value'>{summary.netshoes}</div>
                            <div className='variation'>
                                <Variation direction='none' data={performanceData.netshoes.variation} />
                            </div>
                        </div>
                    </div>
                    <div id='chartContent'>
                        <div style={{ flex: 1 }}>

                            <DataChart activeChart={activeChart} />

                        </div>
                        <div className='selectDate'>
                            <select className='basicInput' onChange={(e) => { setSelectedDate(e.target.value); loadPerformanceData(e.target.value) }} value={selectedDate}>
                                <option value={1}>Ontem</option>
                                <option value={7}>Últimos 7 dias</option>
                                <option value={31}>Último mês</option>
                                <option value={93}>Últimos 3 meses</option>
                                <option value={186}>Últimos 6 meses</option>
                            </select>
                        </div>
                    </div>
                </>


            }
        </div>
    )
});


/* Variações */

const Up = ({ data, positiveOrNegative }) => <span className={positiveOrNegative} > <FaLongArrowAltUp />{data}%</span >;
const Down = ({ data, positiveOrNegative }) => <span className={positiveOrNegative}><FaLongArrowAltDown />{data}%</span>;

const Variation = ({ data, direction }) => {

    if (data > 0) {
        if (direction === 'up') {
            return <Up positiveOrNegative='positive' data={data} />
        } else {
            return <Up positiveOrNegative='negative' data={data} />
        }
    } else {
        if (direction === 'up') {
            return <Down positiveOrNegative='negative' data={data} />
        } else {
            return <Down positiveOrNegative='positive' data={data} />
        }
    }
}

//Array de datas

// Array de dados

/* Data Chart */

const DataChart = React.memo(({ activeChart }) => {

    const performanceData = useSelector(state => state.performanceData);

    const [options, setOptions] = useState({
        scales: {
            xAxes: [{
                ticks: {
                    display: true
                }
            }]
        },
        legend: {
            display: false,
        },
    });

    const [data, setData] = useState({});

    useEffect(() => {

        if (performanceData) {

            var data = performanceData[activeChart].data.map(i => i);

            setData({
                labels: performanceData.dates,
                datasets: [
                    {
                        label: performanceData[activeChart].label,
                        fill: false,
                        lineTension: 0,
                        borderWidth: 2,
                        backgroundColor: performanceData[activeChart].color,
                        borderColor: performanceData[activeChart].color,
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: performanceData[activeChart].color,
                        pointBackgroundColor: performanceData[activeChart].color,
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: performanceData[activeChart].color,
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 2,
                        pointHitRadius: 10,
                        data: data

                    },

                ],

            });

        }


    }, [activeChart, performanceData]);



    return <Line data={data} options={options} />
});