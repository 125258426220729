import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdClose } from 'react-icons/md';

export default function EditProduct({ product, toggleView }) {

    const dispatch = useDispatch();
    const baseUrl = useSelector(state => state.baseUrl);
    const products = useSelector(state => state.products);
    const productsCount = useSelector(state => state.productsCount);
    const [title, setTitle] = useState(product.title);
    const [skuNetshoes, setSkuNetshoes] = useState(product.pk_sku_netshoes ? product.pk_sku_netshoes : null);
    const [skuSeller, setSkuSeller] = useState(product.sku_seller ? product.sku_seller : null);
    const [marketplace, setMarketplace] = useState(product.marketplace ? product.marketplace : null);
    const [sellerPrice, setSellerPrice] = useState(product.seller_price ? product.seller_price.toString().replace('.', ',') : null);
    const [costPrice, setCostPrice] = useState(product.cost_price ? product.cost_price.toString().replace('.', ',') : null);
    const [markup, setMarkup] = useState(product.markup ? product.markup.toFixed(2) : null);
    const [minMarkup, setMinMarkup] = useState(product.min_markup ? product.min_markup.toString().replace('.', ',') : null);


    const saveProduct = function () {

        let data = new FormData();
        data.append('cost_price', costPrice);
        data.append('seller_price', sellerPrice);
        data.append('min_markup', minMarkup);
        data.append('sku_netshoes', skuNetshoes);
        data.append('marketplace', marketplace);


        fetch(`${baseUrl}/modules/products/update_product_data.php`, { method: 'post', body: data }).then(response => response.json()).then(response => {



            if (response.http_code === '200') {

                /* Atualiza a lista de produtos com as mudanças nas informações do produto */

                var newProduct = {
                    ...product,
                    cost_price: costPrice ? costPrice.replace(",", ".") : null,
                    seller_price: sellerPrice ? sellerPrice.replace(",", ".") : null,
                    min_markup: minMarkup ? minMarkup.replace(",", ".") : null,
                    markup: calculateMarkup() ? parseFloat(calculateMarkup().replace(",", ".")) : null,
                    difference_markup: calculateMarkup() ? parseFloat(calculateMarkup().replace(",", ".")) - parseFloat(minMarkup.replace(",", ".")) : null
                };

                var newProducts = products.map(product => product.pk_sku_netshoes === skuNetshoes && product.marketplace === marketplace ? newProduct : product);

                dispatch({ type: 'SET_USER_PRODUCTS', data: newProducts, count: productsCount });
                dispatch({ type: 'SET_DIALOG', data: { type: 'success', status: 'visible', text: 'Produto alterado com sucesso!' } });


                toggleView();

            } else {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Ocorreu um erro ao alterar esse produto.' } });
            }
        });

    }

    const calculateMarkup = function () {
        if (costPrice && sellerPrice) {
            if (parseInt(sellerPrice) !== 0) {
                if (parseInt(costPrice) !== 0) {
                    return (parseInt(sellerPrice) / parseInt(costPrice)).toFixed(1).toString().replace(".", ",");
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    return (
        <div className='modal' id='editProductModal'>
            <div className='modalHeader'>
                <div className='title'>
                    <h3 className='containerTitleSmall'>Editar Produto</h3>
                </div>
                <div className='close'>
                    <button className='iconBtn' onClick={() => toggleView()}>
                        <MdClose size={18} />
                    </button>
                </div>
            </div>
            <div className='modalContent'>
                <div className='row'>
                    <div className='field'>Título</div>
                    <div className='input'>
                        {title}
                    </div>
                </div>

                <div className='row'>
                    <div className='field'>SKU Netshoes</div>
                    <div className='input'>
                        {skuNetshoes}
                    </div>
                </div>
                <div className='row'>
                    <div className='field'>SKU Seller</div>
                    <div className='input'>
                        {skuSeller}
                    </div>
                </div>
                <div className='row'>
                    <div className='field'>Marketplace</div>
                    <div className='input'>
                        {marketplace}
                    </div>
                </div>
                <div className='row'>
                    <div className='field'>Preço de Custo</div>
                    <div className='input'>
                        R$ <input type='text' onChange={(e) => setCostPrice(e.target.value)} className='basicInput2' value={costPrice} />
                    </div>
                </div>
                <div className='row'>
                    <div className='field'>Preço de Venda</div>
                    <div className='input'>
                        R$ <input type='text' onChange={(e) => setSellerPrice(e.target.value)} className='basicInput2' value={sellerPrice} />
                    </div>
                </div>

                <div className='row'>
                    <div className='field'>Margem Mínima</div>
                    <div className='input'>
                        <input type='text' onChange={(e) => setMinMarkup(e.target.value)} className='basicInput2' value={minMarkup} />
                    </div>
                </div>

                <div className='row'>
                    <div className='field'>Margem Atual</div>
                    <div className='input'>
                        {calculateMarkup()}
                    </div>
                </div>
                <div className='row' style={{ padding: 15, justifyContent: 'flex-end' }}>
                    <button onClick={() => saveProduct()} className='blueBtn'>Salvar alterações</button>
                </div>

            </div>
        </div >
    )
}
