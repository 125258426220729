import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import Products from './Products';
import Historic from './Historic';


export default function PricesModal({ toggleView }) {

    return (
        <div className='modal' id='viewOpportunities'>
            <div className='modalHeader'>
                <div className='title'>
                    <h3 className='containerTitleSmall'>Produtos que podem ter o preço otimizado</h3>
                </div>
    
                <div className='close'>
                    <button className='iconBtn' onClick={() => toggleView()}>
                        <MdClose size={18} />
                    </button>
                </div>
            </div>
            <Products />
        </div>
    );
}
