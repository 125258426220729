import React, { useEffect, useState } from 'react'
import { AiFillLock } from 'react-icons/ai';
import { useSelector } from 'react-redux'
import { formatMoney } from '../../../GeneralFunctions';

export default function SuggestedPrice() {

    const session = useSelector(state => state.session);
    const activeProduct = useSelector(state => state.activeProduct);
    const [message, setMessage] = useState('');



    useEffect(() => {
        if (activeProduct.offers) {
            const offers = JSON.parse(activeProduct.offers);
            const sellers = offers.map(p => p.seller_name.toLowerCase());
            if (sellers.indexOf(session.user_name.toLowerCase()) === 0) {

                if (offers[1]) {
                    setMessage('Preço sugerido com base no histórico de preços do produto')
                }

            } else {
                setMessage('Preço sugerido com base no buy-box atual.')
            }
        }else{
            setMessage('Ainda não há sugestão de preço para esse produto.')
        }
    }, []);






    return (
        <div className='section'>
            <div className='section-title section-row sm-mb'>
                <h2>
                    Preço Sugerido
                </h2>
            </div>
            {activeProduct.suggested_price ?
                <div className='suggested-price sm-mb'>
                    {session.plan === '1' ?
                        <div className='not-available-plan'>

                            <AiFillLock />
                            Disponível no plano Performance
                        </div>
                        :
                        formatMoney(activeProduct.suggested_price)}
                </div>
                :
                null}
            <div className='suggested-price-explanation'>
                {message}
            </div>
        </div>

    )
}
