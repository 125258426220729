import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { myGet } from '../../../GeneralFunctions';
import Loading from '../../Loading';

export default function Index() {


    const productsCompetition = useSelector(state => state.productsCompetition);
    const baseUrl = useSelector(state => state.baseUrl);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});


    useEffect(() => {

        if (!productsCompetition) {
            setLoading(true);
            myGet(`${baseUrl}/modules/report/competition/get_competition_data.php`, (response) => {

                console.log('response', response)

                dispatch({ type: 'SET_PRODUCTS_COMPETITION', data: response.data });

                setData({
                    labels: [
                        'Alta',
                        'Média',
                        'Normal',
                        'Desconhecida'
                    ],
                    datasets: [{
                        data: [
                            response.data[2] ? response.data[2].count : 0,
                            response.data[1] ? response.data[1].count : 0,
                            response.data[0] ? response.data[0].count : 0,
                            response.data[3] ? response.data[3].count : 0
                        ],
                        backgroundColor: [
                            '#002fff',
                            '#5b8fff',
                            '#b7dfff',
                            '#E1e1e1'


                        ],
                        hoverBackgroundColor: [
                            '#002fff',
                            '#5b8fff',
                            '#b7dfff',
                            '#E1e1e1'
                        ]
                    }]
                })



                setLoading(false);
            }, (error) => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Ocorreu um erro ao carregar as concorrência dos produtos.' } })
                setLoading(false);
            }, (fail) => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Não foi possivel enviar a requisição para carregar as concorrência dos produtos.' } })
                setLoading(false);
            });

        } else {


            setData({
                labels: [
                    'Alta',
                    'Média',
                    'Normal',
                    'Desconhecida'
                ],
                datasets: [{
                    data: [productsCompetition[2] ? productsCompetition[2].count : 0, productsCompetition[1] ? productsCompetition[1].count : 0, productsCompetition[0] ? productsCompetition[0].count : 0, productsCompetition[3] ? productsCompetition[3].count : 0],
                    backgroundColor: [
                        '#002fff',
                        '#5b8fff',
                        '#b7dfff',
                        '#E1e1e1'


                    ],
                    hoverBackgroundColor: [
                        '#002fff',
                        '#5b8fff',
                        '#b7dfff',
                        '#E1e1e1'
                    ]
                }]
            })




        }

    }, [productsCompetition]);






    return (
        <div id='competitionContainer'>
            <div className='containerHeader'>
                <div className='title'>
                    <h3 className='containerTitleSmall'>Competitividade dos produtos</h3>
                </div>
            </div>
            <div className='container'>
                {loading ? <Loading /> :

                    productsCompetition ?
                        <>
                            <div className='competitionSummaryList'>
                                <div className='competitionSummaryListItem high'>
                                    <div className='number'>
                                        <div className='num'>{productsCompetition[2] ? productsCompetition[2].count : 0}</div>
                                        <span className='text'>
                                            produtos possuem concorrência <strong>alta</strong>
                                        </span>
                                    </div>
                                </div>
                                <div className='competitionSummaryListItem medium'>
                                    <div className='number'>
                                        <div className='num'>{productsCompetition[1] ? productsCompetition[1].count : 0}</div>
                                        <span className='text'>
                                            produtos possuem concorrência <strong>média</strong>
                                        </span>
                                    </div>
                                </div>
                                <div className='competitionSummaryListItem low'>
                                    <div className='number'>
                                        <div className='num'>{productsCompetition[0] ? productsCompetition[0].count : 0}</div>
                                        <span className='text'>
                                            produtos possuem concorrência <strong>normal</strong>
                                        </span>
                                    </div>
                                </div>
                                <div className='competitionSummaryListItem unknown'>
                                    <div className='number'>
                                        <div className='num'>{productsCompetition[3] ? productsCompetition[3].count : 0}</div>
                                        <span className='text'>
                                            produtos possuem concorrência <strong>desconhecida</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='chart'>
                                <Pie width={225} height={225} options={{ maintainAspectRatio: false, responsive: false }} legend={false} data={data} />
                            </div> */}
                        </>
                        :
                        <div style={{ padding: 20, fontSize: '1.3rem', color: 'gray' }}>Não há dados para serem mostrados.</div>
                }
            </div>
        </div>
    )
}
