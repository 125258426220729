import React from 'react'
import { useSelector } from 'react-redux'


export default function Loading({ text }) {

    const baseUrl = useSelector(state => state.baseUrl);

    return (
        <div id='loadingContainer'>
            {text ? text : null}
            <img src={`${baseUrl}/icons/loading2.gif`} alt='Carregando...' />
        </div>
    )
}
