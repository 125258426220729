import React, { useEffect, useState } from 'react'
import { myGet } from '../../../GeneralFunctions'
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import { Bar } from 'react-chartjs-2';

export default function AddedProducts() {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const baseUrl = useSelector(state => state.baseUrl);
    const addedProductsData = useSelector(state => state.addedProductsData);
    const [barData, setBarData] = useState({});


    const replaceMonth = function (m) {

        switch (m) {
            case "1":
                return 'Janeiro';
            case "2":
                return 'Fevereiro'
            case "3":
                return 'Março'
            case "4":
                return 'Abril'
            case "5":
                return 'Maio'
            case "6":
                return 'Junho'
            case "7":
                return 'Julho'
            case "8":
                return 'Agosto'
            case "9":
                return 'Setembro'
            case "10":
                return 'Outubro'
            case "11":
                return 'Novembro'
            case "12":
                return 'Dezembro'
        }

    }


    const loadChart = function (x, y) {

        if (x.length > 0 && y.length > 0) {

            var xAxis = x.map(x => replaceMonth(x.month_number));
            var yAxis = y.map(y => y.difference);

            yAxis = yAxis.filter(y => y);

            console.log(xAxis, yAxis);


            setBarData({
                labels: xAxis,
                datasets: [{
                    label: 'Produtos adicionados',
                    backgroundColor: '#008eff',
                    borderColor: '#008eff',
                    borderWidth: 1,
                    hoverBackgroundColor: '#008eff',
                    hoverBorderColor: '#008eff',
                    data: yAxis
                }]
            });

        }

    }


    useEffect(() => {

        if (!addedProductsData) {

            setLoading(true);


            myGet(`${baseUrl}/modules/report/added/get_added_products.php`, (response) => {

                let x = [];
                let y = [];

                if (response.data) {
                    x = response.data.x;
                    y = response.data.y;
                }

                let data = { x, y }

                loadChart(x, y);
                dispatch({ type: 'SET_ADDED_PRODUCTS', data: data });
                setLoading(false);

            }, (response) => {

                dispatch({
                    type: 'SET_DIALOG', data: {
                        type: 'fail',
                        status: 'visible',
                        text: `Ocorreu um erro ao carregar os produtos adicionados.`
                    }
                });

            }, (error) => {

                dispatch({
                    type: 'SET_DIALOG', data: {
                        type: 'fail',
                        status: 'visible',
                        text: `Não foi possível enviar a requisição para carregar os produtos adicionados. Ref:${error}.`
                    }
                });

            });
        } else {
            loadChart(addedProductsData.x, addedProductsData.y);
        }

    }, [addedProductsData]);

    console.log('Object', Object.values(barData).length, 'bardata', barData);

    return (
        <div id='addedProductsContainer'>
            <div className='containerHeader'>
                <div className='title'>
                    <h3 className='containerTitleSmall'>Produtos adicionados</h3>
                </div>
            </div>
            <div className='container'>
                {loading ?
                    <Loading />
                    :
                    <div>
                        {Object.values(barData).length > 0 ?
                            <Bar
                                data={barData}
                                options={{
                                    legend: {
                                        display: false
                                    },
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                reverse: false,
                                                stepSize: 100,
                                                beginAtZero: true
                                            },
                                        }],
                                        xAxes: [{
                                            barPercentage: 0.2
                                        }]
                                    }
                                }}
                            />
                            :
                            <div style={{ padding: 20, fontSize: '1.3rem', color: 'gray' }}>Não há dados para serem mostrados.</div>}
                    </div>
                }
            </div>
        </div>
    )
}
