import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { formatMoney, formatDatetimeToDate } from '../../GeneralFunctions';
import ProductHistory from '../ProductHistory';
import { AiOutlineAreaChart, AiOutlineLineChart, AiOutlineArrowRight } from 'react-icons/ai';

export default function OptimizedProducts() {


    const [tab, setTab] = useState('opportunity');



    return (
        <div id='optimizedProductsContainer' className='container'>
            {tab === 'optimize' && <OptimizeTab tab={tab} setTab={setTab} />}
            {tab === 'opportunity' && <OpportunityTab tab={tab} setTab={setTab} />}
        </div>
    )
}



const Tabs = ({ tab, setTab }) => {

    const optimized = useSelector(state => state.optimized);

    return (
        <li className='headerCell'>
            <button style={{ borderTopLeftRadius: 10 }} onClick={() => setTab('opportunity')} className={tab === 'opportunity' ? 'tabBtn activeTab' : 'tabBtn'}>Oportunidades ({optimized.opportunitiesProducts ? optimized.opportunitiesProducts.length : 0})</button>
            <button style={{ borderTopRightRadius: 10 }} onClick={() => setTab('optimize')} className={tab === 'optimize' ? 'tabBtn activeTab' : 'tabBtn'}>Otimização ({optimized.optimizeProducts ? optimized.optimizeProducts.length : 0})</button>
        </li>
    );

}



const OpportunityTab = ({ tab, setTab }) => {

    const optimized = useSelector(state => state.optimized);

    return (
        <ul>
            <Tabs tab={tab} setTab={setTab} />
            {optimized.opportunitiesProducts ?
                optimized.opportunitiesProducts.length > 0 ?

                    optimized.opportunitiesProducts.map((product, index) =>
                        <ProductOpportunity key={index} index={index} product={product} />
                    )

                    :
                    <li className='empty'>Nenhum produto a ser mostrado</li>
                :
                <li className='empty'>Nenhum produto a ser mostrado</li>
            }
        </ul>

    );

}

const OptimizeTab = ({ tab, setTab }) => {

    const optimized = useSelector(state => state.optimized);

    return (
        <ul>
            <Tabs tab={tab} setTab={setTab} />
            {/* <li className='headerCell'>
                <div className='section'>
                    <div className='index label'>
                        Ref.
                    </div>
                    <div className='subSection label'>
                        Produto
                </div>
                </div>
                <div className='section'>
                    <div className='subSection label'>
                        Preço anterior
                </div>
                    <div className='arrowSection'>

                    </div>
                    <div className='subSection label'>
                        Preço atual
                </div>
                </div>
            </li> */}

            {optimized.optimizeProducts ?
                optimized.optimizeProducts.length > 0 ?

                    optimized.optimizeProducts.map((product, index) =>
                        <ProductOptimize key={index} index={index} product={product} />
                    )

                    :
                    <li className='empty'>Nenhum produto a ser mostrado</li>
                :
                <li className='empty'>Nenhum produto a ser mostrado</li>
            }
        </ul>

    );


}


const ProductOptimize = ({ product, index }) => {

    const [chartView, setChartView] = useState(false);

    return (
        <li className='cell'>
            <div className='product' style={{ textAlign: 'left' }}>
                <div className='section'>

                    <div className='index label'>
                        {index + 1}
                        <button className='iconBtn' onClick={() => setChartView(!chartView)}>
                            <AiOutlineLineChart />
                        </button>
                    </div>
                    <div className='subSection' style={{ alignItems: 'flex-start' }} >
                        <a href={`https://www.${product.marketplace}.com.br/${product.pk_sku_netshoes}`} target='_blank' rel='noreferrer noopener' style={{ fontWeight: 'bold', textAlign: 'left' }}>{product.title} <strong style={{ color: '#51CB20' }}>+{formatMoney(product.optimized_difference)}</strong></a>
                        <span style={{ color: 'gray' }}>{formatDatetimeToDate(product.last_update)}: {formatMoney(product.current_sale_price)} para {formatMoney(product.sale_price)}</span>
                    </div>
                </div>
                {/* <div className='section'>
                    <div className='subSection'>
                        {formatMoney(product.current_sale_price)}
                    </div>
                    <div className='arrowSection'>
                        <AiOutlineArrowRight />
                    </div>
                    <div className='subSection'>
                        {formatMoney(product.sale_price)}
                        <span style={{ color: 'green' }}>+{formatMoney(product.optimized_difference)}</span>
                    </div>
                </div> */}
            </div>
            <div className='chart'>
                {chartView &&
                    <ProductHistory sku={product.pk_sku_netshoes} />
                }
            </div>
        </li>
    );
}


const ProductOpportunity = ({ product, index }) => {

    const session = useSelector(state => state.session);
    const [chartView, setChartView] = useState(false);

    return (
        <li className='cell'>
            <div className='product' style={{ textAlign: 'left' }}>
                <div className='section'>

                    <div className='index label'>
                        {index + 1}
                        <button className='iconBtn' onClick={() => setChartView(!chartView)}>
                            <AiOutlineLineChart />
                        </button>
                    </div>
                    <div className='subSection' style={{ alignItems: 'flex-start' }} >
                        <div>
                            <a href={`https://www.${product.marketplace}.com.br/${product.pk_sku_netshoes}`} target='_blank' rel='noreferrer noopener' style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                {product.title}
                            </a>
                            <strong className='earned'>+{formatMoney(product.earned)}</strong>
                        </div>
                        <span className='sku'>SKU {product.sku_seller} · {product.pk_sku_netshoes}</span>
                        <span style={{ color: 'gray' }}>
                            <strong>
                                {formatDatetimeToDate(product.last_update)}
                            </strong>: Vendido por {product.old_seller} à {formatMoney(product.current_sale_price)} e passou a ser vendido por você por {formatMoney(product.sale_price)} .</span>
                    </div>
                </div>
                {/* SKU {product.sku_seller} / {product.pk_sku_netshoes} */}
                {/* <div className='section'>
                    <div className='subSection'>
                        <div className='seller'>{product.old_seller}</div>
                        <span>{formatMoney(product.current_sale_price)}</span>
                    </div>
                    <div className='arrowSection'>
                        <AiOutlineArrowRight />
                    </div>
                    <div className='subSection'>
                        <div className='seller'>{session.id === '3' ? 'Sua Loja' : product.seller}</div>
                        <span>{formatMoney(product.sale_price)}</span>
                    </div>
                </div> */}
            </div>
            <div className='chart'>
                {chartView &&
                    <ProductHistory sku={product.pk_sku_netshoes} />
                }
            </div>
        </li>
    );
}

