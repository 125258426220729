import React from 'react'
import Header from '../Header/Header'
import Menu from '../Menu/Menu'
import { GiPieChart } from 'react-icons/gi'
import BrandQuantity from './BrandQuantity/BrandQuantity'
import Dialog from '../Dialog'
import AddedProducts from './AddedProducts/AddedProducts';
import Competition from './Competition/index.js';

export default function Report() {


    return (
        <>
            <Header pageTitle='Relatório' pageIcon={<GiPieChart size={16} />} />
            <Menu active='report' />
            <div className='pageContent'>
                <div id='reportContainer'>
                    <BrandQuantity />
                    <Competition />
                    <AddedProducts />
                </div>
            </div>
            <Dialog />
        </>
    )
}





