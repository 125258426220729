// export const importers = {
//     "moovin": [
//         {
//             "name": "moovin",
//             "extension": "csv",
//             "alias": "Planilha Moovin"
//         },
//         {
//             "name": "preco",
//             "extension": "xlsx",
//             "alias": "Planilha de preços (Netshoes)"
//         },
//         {
//             "name": "exp",
//             "extension": "xlsx",
//             "alias": "Planilha de exportação (Netshoes)"
//         }
//     ],
//     "netshoes": [
//         {
//             "name": "preco",
//             "extension": "xlsx",
//             "alias": "Planilha de preços (Netshoes)"
//         },
//         {
//             "name": "exp",
//             "extension": "xlsx",
//             "alias": "Planilha de exportação (Netshoes)"
//         },
//         {
//             "name": "estoque",
//             "extension": "xlsx",
//             "alias": "Planilha de estoque (Netshoes)"
//         }
//     ],
//     "anymarket": [
//         {
//             "name": "anymarket",
//             "extension": "xlsx",
//             "alias": "Lista completa Anymarket"
//         },
//         {
//             "name": "preco",
//             "extension": "xlsx",
//             "alias": "Planilha de preços (Netshoes)"
//         },
//         {
//             "name": "exp",
//             "extension": "xlsx",
//             "alias": "Planilha de exportação (Netshoes)"
//         }
//     ],
//     "shoppub": [
//         {
//             "name": "shoppub",
//             "extension": "xls",
//             "alias": "Planilha Shoppub"
//         },
//         {
//             "name": "preco",
//             "extension": "xlsx",
//             "alias": "Planilha de preços (Netshoes)"
//         },
//         {
//             "name": "exp",
//             "extension": "xlsx",
//             "alias": "Planilha de exportação (Netshoes)"
//         }
//     ],
//     "tray": [
//         {
//             "name": "tray",
//             "extension": "xlsx",
//             "alias": "Planilha Tray"
//         },

//         {
//             "name": "exp",
//             "extension": "xlsx",
//             "alias": "Planilha de exportação (Netshoes)"
//         },

//         {
//             "name": "estoque",
//             "extension": "xlsx",
//             "alias": "Planilha de estoque (Netshoes)"
//         },

//     ],
//     "placemarket": [
//         {
//             "name": "moovin",
//             "extension": "csv",
//             "alias": "Planilha Moovin"
//         },
//         {
//             "name": "placemarket",
//             "extension": "csv",
//             "alias": "Planilha Placemarket"
//         },
//         {
//             "name": "exp",
//             "extension": "xlsx",
//             "alias": "Planilha de exportação (Netshoes)"
//         }

//     ],
//     "moovin/estoque-netshoes": [
//         {
//             "name": "moovin",
//             "extension": "csv",
//             "alias": "Planilha Moovin"
//         },
//         {
//             "name": "preco",
//             "extension": "xlsx",
//             "alias": "Planilha de preços (Netshoes)"
//         },
//         {
//             "name": "exp",
//             "extension": "xlsx",
//             "alias": "Planilha de exportação (Netshoes)"
//         },
//         {
//             "name": "estoque",
//             "extension": "xlsx",
//             "alias": "Planilha de estoque (Netshoes)",
//         }
//     ]

// }

export const importers = {
    "netshoes": [
        {
            "name": "exp",
            "extension": "xlsx",
            "alias": "Planilha de exportação (Netshoes)",
            "required": true
        },
        {
            "name": "template_custo",
            "extension": "xlsx",
            "alias": "Template de custos (opcional)",
            "required": false
        }
    ],
   
}