import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/IStore';


export function useGet() {

    const baseUrl = useSelector((state: IStore) => state.baseUrl);

    //@ts-ignore
    const session = JSON.parse(localStorage.getItem('session'));
 
    return (url: string) => new Promise<any>((resolve, reject) => {
        if (session) {
            fetch(`${baseUrl}${url}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'GET'
            }).then(response => {

                if (response.ok) {
                    return response.json().then(response => resolve(response));
                } else {

                    var statusCode = response.status;

                    if (statusCode === 555) {
                        alert('Sua sessão expirou. Faça login novamente.')
                        const origin = window.location.origin;
                        window.location.href = origin + '/app/login';
                    } else {
                        return response.json().then(response => {
                            reject(response)
                        });
                    }
                }
            });
        }
    });

}
