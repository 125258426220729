import React, { useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { toggleView } from '../../../GeneralFunctions'
import { useSelector } from 'react-redux'
import Dialog from '../../Dialog'
import Loading from '../../Loading'
import Overlay from '../../Overlay'
import PricesModal from './PricesModal'


export default function Prices() {

    const [viewProducts, setViewProducts] = useState(false);
    const [loading, setLoading] = useState(false);
    const prices = useSelector(state => state.prices);
    const selectOptimizableProducts = useSelector(state => state.selectOptimizableProducts);

    const openProducts = function () {
        toggleView(viewProducts, setViewProducts);
    }


    return (
        <>
            <div className='container' id='optimizeOpportunities'>
                {prices.loading || loading ?
                    <Loading />
                    :
                    <>
                        <div className='bigNumber'>
                            {prices.quantity}
                            <span>
                                <h3 className="containerInsideTitleBig">Produtos podem ter o preço otimizado
                                {selectOptimizableProducts && selectOptimizableProducts != '1' ?
                                        `\n(com até ${(selectOptimizableProducts * 100).toFixed(0)}%)` : null}
                                </h3>
                            </span>
                        </div>

                        <div>
                            <button onClick={() => openProducts()} className='blueBtn' style={{ width: '100%' }}>
                                <AiOutlineEye />
                                Ver Produtos
                        </button>
                        </div>

                    </>
                }
            </div>
            {viewProducts &&
                <>
                    <Overlay toggleView={() => toggleView(viewProducts, setViewProducts)} />
                    <PricesModal toggleView={() => toggleView(viewProducts, setViewProducts)} />
                </>
            }
            <Dialog />
        </>
    );
}
