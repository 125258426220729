import React, { useEffect, useState } from 'react'
import { AiFillLock } from 'react-icons/ai';
import { useSelector } from 'react-redux'
import { formatMoney } from '../../../GeneralFunctions';

export default function OffersList() {

    const activeProduct = useSelector(state => state.activeProduct);
    const session = useSelector(state => state.session);
    const [sellerPosition, setSellerPosition] = useState(false);
    const [offersList, setOffersList] = useState([]);

    useEffect(() => {
        if (activeProduct.offers) {

            const list = JSON.parse(activeProduct.offers).map((offer, index) => {
                return {
                    ...offer,
                    position: index + 1
                }
            });


            if (session.plan === '1') {
                list.forEach((offer, index) => {
                    if (offer.seller_name.toLowerCase() === session.user_name.toLowerCase()) {

                        setOffersList(list.slice(index - 0, index + 1))


                    }
                });
            } else {
                setOffersList(list)
            }
        }
    }, []);

    const handleStyle = (offer) => {

        let style = {};

        if (offer.stock === 0) {
            style = { opacity: 0.6 };
        }



        if (offer.seller_name.toLowerCase() === session.user_name.toLowerCase() || (offer.seller_name.toLowerCase() === 'rainha calçados' && session.id == '3')) {
            if (offer.position === 1) {
                style = { ...style, fontWeight: 'bold', borderLeft: '4px solid var(--color-status-selling)' };
            } else {
                if (activeProduct.status === 'alert') {
                    style = { ...style, fontWeight: 'bold', borderLeft: '4px solid var(--color-status-alert)' };
                } else {
                    style = { ...style, fontWeight: 'bold', borderLeft: '4px solid var(--color-status-losing)' };
                }
            }

        }

        return style;
    }


    const handleSellerName = (seller) => {
        if(session.id == '3' && seller === 'RAINHA CALÇADOS'){
            return 'Sua Loja';
        }else{
            return seller;
        }
    }

    return (
        <div className='section nopadding' style={{ borderBottom: 0 }}>
            <div className='section-title' style={{ padding: 20 }}>
                <h2>
                    Ofertas deste produto
                    </h2>
            </div>
            <div className='sellers-list-item'>
                <div className='label sellers-list-item-section'>
                    Posição
                        </div>
                <div className='label sellers-list-item-section'>
                    Seller
                        </div>
                <div className='label sellers-list-item-section'>
                    Preço
                        </div>
                <div className='label sellers-list-item-section'>
                    Estoque
                        </div>
            </div>
            <div className='sellers-list'>

                {activeProduct.status === 'unavailable' ?
                    <div style={{ color: 'gray', padding: 20 }} className='seller-list-item'>Produto indisponível</div>
                    :
                    <>
                        {activeProduct.offers ?
                            offersList.map((offer, index) =>
                                <div className='sellers-list-item has-hover' style={handleStyle(offer)}>
                                    <div className='sellers-list-item-section'>
                                        {offer.position}º
                                        </div>
                                    <div className='sellers-list-item-section'>
                                        {handleSellerName(offer.seller_name)}
                                    </div>
                                    <div className='sellers-list-item-section'>
                                        {formatMoney(offer.price)}
                                    </div>
                                    <div className='sellers-list-item-section'>
                                        {offer.stock == '1' ? 'Possui estoque' : 'Sem estoque'}
                                    </div>
                                </div>
                            )
                            :
                            <div style={{ padding: 20, color: 'gray' }}>Ofertas desconhecidas para esse produto</div>}

                        {session.plan === '1' && <NotAvailableInThisPlan />}
                    </>}



            </div>
        </div>
    )
}

const NotAvailableInThisPlan = () => {
    return (
        <div >
            <div className='sellers-list-item not-available-plan-gray'>
                Existem mais concorrentes nesse produto
            </div>
            <div style={{ paddingLeft: 20 }} className='sellers-list-item not-available-plan'>
                <AiFillLock /> A lista completa de concorrentes é disponibilizada no plano Performance
            </div>
        </div>
    );
}
