
import React, { useEffect, useState } from 'react'
import { AiOutlineEye, AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import { myGet, toggleView, formatMoney } from '../../../GeneralFunctions'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '../../Dialog'
import Loading from '../../Loading'
import Overlay from '../../Overlay'
import OpportunitiesModal from './OpportunitiesModal'



export default function Opportunities() {

    const opportunities = useSelector(state => state.opportunities);
    const [viewOpportunities, setViewOpportunities] = useState(false);
    const [priceDifference, setPriceDifference] = useState(0.05);

    const openProducts = function () {
        toggleView(viewOpportunities, setViewOpportunities);
    }

    return (
        <>
            <div className='container' id='pricesOpporntunities'>
                {opportunities.loading  ?
                    <Loading />
                    :
                    <>
                        <div className='bigNumber'>
                            {opportunities.quantity}
                            <span>
                                <h3 className="containerInsideTitleBig">{priceDifference === '1' ? 'Oportunidades de redução de preço' : `Produtos possuem diferença de até ${(priceDifference*100)}%`}</h3>
                            </span>
                        </div>
                        <div>
                            <button onClick={() => openProducts()} className='blueBtn' style={{ width: '100%' }}>
                                <AiOutlineEye />
                                Ver Produtos
                            </button>
                        </div>
                    </>
                }
            </div>

            {viewOpportunities &&
                <>
                    <Overlay toggleView={() => toggleView(viewOpportunities, setViewOpportunities)} />
                    <OpportunitiesModal priceDifference={priceDifference} setPriceDifference={setPriceDifference} toggleView={() => toggleView(viewOpportunities, setViewOpportunities)} />
                </>
            }

            <Dialog />
        </>
    );
}





