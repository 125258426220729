import React from 'react'
import { MdClose } from 'react-icons/md';
import Products from './Products';
import { formatMoney } from '../../../GeneralFunctions';

export default function OpportunitiesModa({ priceDifference, setPriceDifference, toggleView }) {

    return (
        <div className='modal' id='viewOpportunities'>
            <div className='modalHeader'>
                <div className='title'>
                    <h3 className='containerTitleSmall'>{priceDifference === '9999' ? 'Todas as oportunidades' : `Oportunidades que possuem diferença de até ${priceDifference*100}%`}</h3>
                </div>

                <div className='close'>
                    <button className='iconBtn' onClick={() => toggleView()}>
                        <MdClose size={18} />
                    </button>
                </div>
            </div>
            <Products
                priceDifference={priceDifference}
                setPriceDifference={setPriceDifference}
            />
        </div>
    );
}
