import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineCheckCircle, AiOutlineWarning } from 'react-icons/ai';




export default function Dialog() {

    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();


    useEffect(() => {

        if (dialog.status === 'visible') {
            var newDialog = { ...dialog, status: 'hidden', text: '', type: 'none' };
            setTimeout(() => dispatch({ type: 'SET_DIALOG', data: newDialog }), 10000);
        }
    }, [dialog]);



    return (
        dialog.status === 'visible' &&
        <div id='dialogContainer' className={dialog.type}>
            {dialog.type === 'success' && <div><AiOutlineCheckCircle /> {dialog.text}</div>}
            {dialog.type === 'fail' && <div><AiOutlineWarning /> {dialog.text}</div>}
            {dialog.type === 'notice' && <div>{dialog.text}</div>}
        </div>
    )
}
