import React from 'react'
import Header from './Header/Header'
import Menu from './Menu/Menu'

import { AiOutlineRobot } from 'react-icons/ai'

export default function NotFound() {
    return (
        <>

            <Header pageTitle='' />

            <div className='pageContent'>
                <div id='notFoundContainer'>

                    <div><AiOutlineRobot size={128} /></div>
                    <div className='notFoundTitle'>Página não encontrada</div>
                    <div className='text'>Parece que a página que você tentou acessar não existe.</div>
                </div>
            </div>
        </>
    )
}
