import React from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import useProducts from '../../hooks/products/useProducts';

export default function ProductsHeaderCell() {

    const session = useSelector(state => state.session);

    return (
        <div className='headerCell'>
            <div className='index'>
                <div className='label'>Ref.</div>
            </div>
            <div className='section section-big'  >
                Título
                <div className='order'>
                    <OrderBtn id='title' />
                </div>
            </div>
            <div className='section'>
                Seu preço
                <div className='order'>
                    <OrderBtn id='seller_price' />
                </div>
            </div>
            {session.markup === 't' &&
                <div className='section' id='markup'>
                    Margem
                    <div className='order'>
                        <OrderBtn id='mark_up' />
                    </div>
                </div>
            }

            <div className='section' id='stock'>
                Competitividade
                <div className='order'>
                    <OrderBtn id='competition' />
                </div>
            </div>

            {session.plan !== '1' &&
                <div className='section' id='stock'>
                    Preço Sugerido
                    <div className='order'>
                        <OrderBtn id='suggested_price' />
                    </div>
                </div>
            }
            {/* <div className='section' id='stock'>
            <div className='label'>Posição</div>
            <div className='order'>
                <button className='iconBtn' onClick={() => handleOrder('stock')}>
                    {order[0] === 'stock' && order[1] === 'desc' ?
                        <MdKeyboardArrowDown size={20} />
                        :
                        order[0] === 'stock' && order[1] === 'asc' ?
                            <MdKeyboardArrowUp size={20} />
                            :
                            <MdKeyboardArrowDown size={20} />
                    }
                </button>
            </div>
        </div> */}
            <div className='section'>
                Preço Marketplace
                <div className='order'>
                    <OrderBtn id='diferenca_preco' />
                </div>
            </div>
            <div className='section'>
                Vendedor
                <div className='order'>
                    <OrderBtn id='seller' />
                </div>
            </div>
        </div>
    )
}

const OrderBtn = ({ id }) => {



    const order = useSelector(state => state.queryOrder);
    const query = useSelector(state => state.queryProduct);
    const filters = useSelector(state => state.queryFilters);

    const loadProducts = useProducts();
    const dispatch = useDispatch();

    const handleOrder = (received) => {
        if (order[0] !== received) {
            var newOrder = [received, 'asc'];
        } else {
            if (order[1] === 'asc') {
                var newOrder = [received, 'desc'];
            } else if (order[1] === 'desc') {
                var newOrder = [received, null];
            } else {
                var newOrder = [received, 'asc'];
            }

        }
        dispatch({ type: 'SET_PRODUCTS_ORDER', order: newOrder });
        loadProducts(filters, query, newOrder);
    }


    return (
        <button className='iconBtn' onClick={() => handleOrder(id)}>
            {order[0] === id && order[1] === 'desc' ?
                <MdKeyboardArrowDown size={20} />
                :
                order[0] === id && order[1] === 'asc' ?
                    <MdKeyboardArrowUp size={20} />
                    :
                    <MdKeyboardArrowDown size={20} />
            }
        </button>
    )
}