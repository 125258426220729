import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { myGet, formatMoney } from '../../../GeneralFunctions';
import { AiOutlineArrowRight, AiOutlineLineChart, AiOutlineTrophy } from 'react-icons/ai';
import ExportBtn from '../../ExportData/ExportBtn';
import ProductHistory from '../../ProductHistory';
import Loading from '../../Loading';
import LateralProductView from '../../Products/LateralProductView/LateralProductView';

export default function Products({ priceDifference, setPriceDifference }) {

    const activeProduct = useSelector(state => state.activeProduct);
    const baseUrl = useSelector(state => state.baseUrl);
    const opportunities = useSelector(state => state.opportunities);
    const dispatch = useDispatch();

    const [loadingProducts, setLoadingProducts] = useState(false);
    const [selectProductsMode, setSelectProductsMode] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const defaultColumns = ['pk_sku_netshoes', 'current_sale_price', 'suggested_price'];
    const forbiddenColumns = ['suggested_price_difference', 'current_sale_price', 'competition', 'stock', 'priority', 'last_update_stock', 'last_update_scrap', 'last_update_status', 'insert_date', 'is_available', 'id', 'fk_user', 'fk_sku_netshoes', 'marketplace', 'seller', 'status', 'offers'];


    useEffect(() => {
        if (!opportunities.products) {
            loadProducts(priceDifference);
        }
    }, [opportunities]);


    const toggleLateralView = (product, e) => {

        if(selectProductsMode) return; 
        
        let role = e.target.getAttribute('role');
        if (role == 'netshoes-link' || role == 'sku' || role == 'title') return;
        const selection = window.getSelection();

        if (selection.type != "Range") {
            document.querySelector('body').style.overflowY = 'hidden';
            if (!activeProduct) return dispatch({ type: 'SET_DATA', data: { activeProduct: product } });
            if (activeProduct.id === activeProduct.id) {
                document.querySelector('body').style.overflowY = 'auto';
                return dispatch({ type: 'SET_DATA', data: { activeProduct: false } });
            }

            document.querySelector('body').style.overflowY = 'hidden';
            return dispatch({ type: 'SET_DATA', data: { activeProduct: product } });
        }
    }


    const loadProducts = function (priceDifference) {
        setLoadingProducts(true);
        myGet(`${baseUrl}/modules/optimize/get_opportunities_products.php?priceDifference=${priceDifference}`, (response) => {
            var newOpportunities = { ...opportunities, products: response.data.products, quantity: response.data.quantity };
            dispatch({ type: 'SET_OPPORTUNITIES', data: newOpportunities });
            setLoadingProducts(false);
        }, () => {
            dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'fail', text: 'Ocorreu um erro ao carregar as oportunidades.' } });
            setLoadingProducts(false);
        }, (error) => {
            dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'fail', text: `Ocorreu um erro ao carregar as oportunidades. Ref: ${error}.` } });
            setLoadingProducts(false);
        });
    }


    return (
        loadingProducts ?
            <Loading />
            :
            <div className='modalContent'>
                <ul>
                    <li>
                        <div className='data'>
                            <div className='section'>
                                <div className='indexSection label'>Ref.</div>
                                <div className='subSection label'>Produto</div>
                            </div>
                            <div className='section'>
                                <div className='subSection label'>Status no marketplace</div>
                                <div className='subSection label'>Concorrência</div>
                            </div>
                            <div className='section'>
                                <div className='subSection label'>Seu preço atual</div>
                                <div className='arrowSection'></div>
                                <div className='subSection label'>Preço sugerido</div>
                            </div>
                        </div>
                    </li>
                    {opportunities.products ?
                        opportunities.products.length > 0 ?
                            opportunities.products.map((product, index) =>
                                <Product
                                    toggleLateralView={toggleLateralView}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                    selectProductsMode={selectProductsMode}
                                    product={product}
                                    key={index}
                                    index={index}
                                />
                            )
                            :
                            <li>Nenhum produto a ser exibido.</li>
                        :
                        <li>Nenhum produto a ser exibido.</li>}
                </ul>
                <div className='bottomRow'>
                    <div style={{ flex: 1 }}>
                        <span className='label'>Exibindo produtos com diferença de até</span>
                        <select style={{ marginLeft: 5 }} className='basicInput' value={priceDifference} onChange={(e) => { setPriceDifference(e.target.value); loadProducts(e.target.value); }}>
                            <option value={0.01}>1%</option>
                            <option value={0.05}>5%</option>
                            <option value={0.10}>10%</option>
                            <option value={0.20}>20%</option>
                            <option value={0.50}>50%</option>
                        </select>
                    </div>
                    <div>
                        <ExportBtn
                            products={opportunities.products}
                            defaultColumns={defaultColumns}
                            selectProductsMode={selectProductsMode}
                            setSelectProductsMode={setSelectProductsMode}
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            forbiddenColumns={forbiddenColumns}
                            section='opportunities'
                        />
                    </div>
                </div>
                <LateralProductView toggleLateralView={toggleLateralView} />
            </div>
    );
}

const Product = React.memo(({ index, product, selectedProducts, setSelectedProducts, selectProductsMode, toggleLateralView }) => {


    const [chartView, setChartView] = useState(false);

    const selectProduct = function (product) {
        if (selectProductsMode === 'select') {
            if (selectedProducts.some(p => p.sku_seller === product.sku_seller)) {
                var newSelectedProducts = selectedProducts.filter(p => p.sku_seller !== product.sku_seller);
                setSelectedProducts(newSelectedProducts);
            } else {
                var newSelectedProducts = selectedProducts.concat(product);
                setSelectedProducts(newSelectedProducts);
            }
        }
    }

    const competitionBadgeClassName = `competition${product.competition}`

    return (
        <li className='hover' key={index} style={selectedProducts.some(p => p.sku_seller === product.sku_seller) ? { backgroundColor: 'rgb(209, 255, 203)' } : {}} onClick={() => selectProduct(product)}>
            <div onClick={(e) => toggleLateralView(product, e)} className='data'>
                <div className='section'>
                    <div className='indexSection'>
                        {index + 1}
                    </div>
                    <div className='subSection'>
                        <a href={`https://www.${product.marketplace}.com.br/${product.sku_netshoes}`} target='_blank'>{product.title}</a>
                        <span className='sku'>Cód. {product.sku_seller} · {product.sku_netshoes}</span>
                    </div>
                </div>
                <div className='section'>
                    <div className='subSection'>
                        <div>{product.seller}</div>
                        <span>{formatMoney(product.current_sale_price)}</span>
                    </div>
                    <div className='subSection'>
                        <span className={competitionBadgeClassName}>
                            {product.competition === '1' ?
                                'Normal'
                                :
                                product.competition === '2' ?
                                    <div className='highCompetition'>
                                        <AiOutlineTrophy />
                                    Média
                                </div>
                                    :
                                    product.competition === '3' ?
                                        <div className='highCompetition'>
                                            <AiOutlineTrophy />
                                    Alta
                                    </div>
                                        :
                                        '-'
                            }
                        </span>
                    </div>
                </div>
                <div className='section'>
                    <div className='subSection'>
                        <div>{formatMoney(product.current_seller_price)}</div>
                    </div>
                    <div className='arrowSection'>
                        <AiOutlineArrowRight />
                    </div>
                    <div className='subSection'>
                        <div>{formatMoney(product.suggested_price)}</div>
                        <span className='newDifference'>{formatMoney(product.suggested_price_difference)}</span>
                    </div>
                </div>
            </div>
            {chartView &&
                <ProductHistory sku={product.sku_netshoes} />
            }
        </li>);
});