import React, { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import ExportPricesHistoric from './ExportPrices/ExportPricesHistoric';
import ExportPricesGenerator from './ExportPrices/ExportPricesGenerator';
import { myGet } from '../../GeneralFunctions';
import { useSelector, useDispatch } from 'react-redux';
import Help from '../Help';
import { AiOutlineSearch } from 'react-icons/ai';
import Loading from '../Loading';


export default function Markup({ toggleView }) {

    const session = useSelector(state => state.session);
    const baseUrl = useSelector(state => state.baseUrl);
    const userMarkups = useSelector(state => state.userMarkups);
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [defaultMarkup, setDefaultMarkup] = useState(session.defaultMarkup);

    useEffect(() => {
        setLoading(true);
        myGet(`${baseUrl}/modules/manage/get_markups.php`, (response) => {

            dispatch({ type: 'SET_USER_MARKUPS', data: response.data });
            setLoading(false);

        }, (response) => {

        }, () => { });

    }, []);


    const handleSave = function () {
        setSearch('');
        saveData();
    }

    const saveData = function () {
        setLoading(true);
        var data = new FormData(document.getElementById('markupForm'));
        fetch(`${baseUrl}/modules/manage/update_markups.php`, { method: 'post', body: data }).then(response => response.json()).then(response => {

            console.log(response);
            if (response.http_code === '200') {
                setLoading(false);
                dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'success', text: 'Alteração feita com sucesso.' } });

                var newSession = { ...session, defaultMarkup: defaultMarkup };
                dispatch({ type: 'SET_SESSION', data: newSession });

                toggleView();
            } else {
                dispatch({ type: 'SET_DIALOG', data: { status: 'visible', type: 'fail', text: `Ocorreu um erro ao alterar a margem. Tente novamente ou entre em contato com o suporte. Ref: ${response}` } });
            }

        });


    }



    return (
        <div className='modal' id='markupContainer'>
            <div className='modalHeader'>
                <div className='title'>
                    <h3 className='containerTitleSmall'>Margem mínima por marca</h3>
                </div>
                <div className='search'>
                    <input
                        className='basicInput2'
                        placeholder='Pesquisar marca'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <AiOutlineSearch />
                </div>
                <div className='close'>
                    <button className='iconBtn' onClick={() => toggleView()}>
                        <MdClose size={18} />
                    </button>
                </div>
            </div>
            <div className='modalContent' id='markupContent'>
                {loading ?
                    <Loading />
                    :
                    <form method='post' id='markupForm' >
                        <ul>
                            <li>
                                <div className='section'>
                                    <span className='label'>
                                        Margem mínima padrão
                                    </span>
                                    <Help text='A margem padrão será utilizada para as marcas que não tiverem a margem definida.' />
                                </div>
                                <div className='section'>
                                    <input type='text'
                                        placeholder='Margem padrão'
                                        className='basicInput'
                                        value={defaultMarkup}
                                        onChange={(e) => setDefaultMarkup(e.target.value)}
                                        name='default'
                                    />
                                </div>
                            </li>
                            {userMarkups ?
                                userMarkups.length > 0 ?
                                    userMarkups.map((brand, index) =>

                                        <BrandCell visible={brand.brand.search(search) !== -1} brand={brand} key={index} />
                                    )
                                    :
                                    <li className='empty'>Nenhuma marca a ser mostrada.</li>
                                :
                                <li className='empty'>Nenhuma marca a ser mostrada.</li>
                            }
                        </ul>
                    </form>
                }
                <div className='bottomRow'>
                    <div>
                        <button onClick={() => { handleSave() }} className='blueBtn'>Salvar alterações</button>
                    </div>

                </div>
            </div>
        </div >

    )
}


const BrandCell = ({ brand, visible }) => {


    const [value, setValue] = useState(brand.markup);
    const userMarkups = useSelector(state => state.userMarkups);

    const handleValue = function (value) {
        setValue(value);
    }


    return (

        <li style={visible ? { display: 'flex' } : { display: 'none' }}>
            <div className='section'>
                <span className='label'>
                    {brand.brand.charAt(0).toUpperCase() + brand.brand.slice(1)}
                </span>
            </div>
            <div className='section'>
                <input
                    type='text'
                    placeholder={`Margem ${brand.brand.charAt(0).toUpperCase() + brand.brand.slice(1)}`}
                    className='basicInput'
                    name={brand.brand}
                    value={value}
                    onChange={(e) => handleValue(e.target.value)}
                />

            </div>
        </li >

    );

}