import React from 'react'
import { IoIosSpeedometer, IoMdSpeedometer } from 'react-icons/io'

export default function PlanError() {
    return (
        <div id='plan-error-container'>
            <IoMdSpeedometer />
            Essa funcionalidade é disponibilizada no plano Performance
        </div>
    )
}
