import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ProductHistory from '../../ProductHistory';

export default function ProductHistoric() {


    const activeProduct = useSelector(state => state.activeProduct);
    const [chartView, setChartView] = useState(false);

    const handleChart = () => {
        setChartView(!chartView);
    }




    return (
        <div className='section'>
            <div className='section-title'>
                <div className='section-row'>
                    <div style={{ flex: 1 }}>
                        <h2>Histórico de preços</h2>
                    </div>
                    <div>
                        <button onClick={() => handleChart()} className='btnLink'>{!chartView ? 'Mostrar' : 'Esconder'}</button>
                    </div>
                </div>

            </div>
            {chartView &&
                <ProductHistory  sku={activeProduct.pk_sku_netshoes} />
            }
        </div>
    )
}
