import React, { useEffect } from 'react'
import Header from '../Header/Header'
import Menu from '../Menu/Menu'
import { FaRegChartBar } from 'react-icons/fa';
import Summary from '../Summary/Summary';
import Performance from './Performance';
import NewSales from './NewSales';
import LostSales from './LostSales';
import Earned from '../Optimize/Prices/Earned';
import Dialog from '../Dialog';
import { useSelector } from 'react-redux';
import PopupCentauro from '../PopupCentauro';


export default function Dashboard() {


    const session = useSelector(state => state.session);




    return (
        <>

            <Header pageTitle='Dashboard' pageIcon={<FaRegChartBar size={16} />} />
            <Menu active='dashboard' />



            <div className='pageContent'>
                <div id='dashboardContainer'>
                    <div className='containerHeader'>
                        <div className='title'>
                            <h3 className='containerTitleSmall'>Desempenho</h3>
                        </div>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <Performance />
                        <Summary />
                    </div>
                    <div className='containerHeader' style={{ marginTop: 20 }}>
                        <div className='title'>
                            <h3 className='containerTitleSmall'>Otimização de preços</h3>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Earned />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <NewSales />
                        <LostSales />
                    </div>

                </div>

            </div>
            {/* <PopupCentauro /> */}
            <Dialog />
        </>

    );
}
