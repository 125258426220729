import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

export default function useProducts() {

    const dispatch = useDispatch();
    const { loadingProducts = false, baseUrl } = useSelector(state => state);


    return (filter, query, order) => {
        if (loadingProducts === false) {

            dispatch({ type: 'LOADING_PRODUCTS', status: true });

            var data = new FormData();
            data.append("filters", JSON.stringify(filter));
            data.append("query", JSON.stringify(query));
            data.append("order", JSON.stringify(order));

            fetch(`${baseUrl}/modules/products/get_products.php`, {
                method: "POST",
                body: data

            }).then(response => response.json()).then(response => {
                dispatch({ type: 'SET_USER_PRODUCTS', data: response.data, count: response.resultsCount });
                dispatch({ type: 'LOADING_PRODUCTS', status: false });
                window.scrollTo(0, 0)
            });
        }
    }

}
