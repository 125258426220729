import React, { useState, useEffect } from 'react'
import Loading from './Loading'
import { Line } from 'react-chartjs-2'
import { useSelector, useDispatch } from 'react-redux';
import { myGet } from '../GeneralFunctions';

export default function ProductHistory({ sku }) {

    const productsHistory = useSelector(state => state.productsHistory);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [days, setDays] = useState(7);


    const dispatch = useDispatch();
    const baseUrl = useSelector(state => state.baseUrl);

    useEffect(() => {
        loadChart();
    }, []);

    useEffect(() => {
        loadChart();
    }, [days]);


    const options = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            xAxes: [{
                ticks: {
                    display: false
                }
            }]
        },
        legend: {
            display: false,
        }
    }



    const loadChart = () => {
        setLoading(true);
        myGet(`${baseUrl}/modules/optimize/prices/get_product_historic.php?sku=${sku}&date=${days}`, (response) => {
            var newProductsHistory = productsHistory.concat({ sku: sku, x: response.x, y: response.y });
            dispatch({ type: 'SET_PRODUCTS_HISTORY', data: newProductsHistory });
            setData({
                labels: response.x,
                datasets: [
                    {
                        label: 'Evolução dos preços',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: '#008eff',
                        borderColor: '#008eff',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: '#008eff',
                        pointBackgroundColor: '#008eff',
                        pointBorderWidth: 5,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: '#008eff',
                        pointHoverBorderColor: '#008eff',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: response.y
                    }
                ]

            });
            setLoading(false);

        }, (response) => {
            setLoading(false);
        }, (error) => {
            setLoading(false);
        });
    }


    return (

        <div className='chartContainer'>
            {loading ?
                <Loading />
                :
                <div>
                    <Line height={100} data={data} options={options} />
                    <div>
                        <div style={{ color: 'gray', marginTop: 10 }}>
                            Exibindo
                            <select style={{ marginLeft: 10 }} value={days} onChange={(e) => setDays(e.target.value)}>
                                <option value={7}>7 dias</option>
                                <option value={14}>14 dias</option>
                                <option value={30}>30 dias</option>
                                <option value={60}>60 dias</option>
                            </select>
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}
