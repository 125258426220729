import React, { useState } from 'react'
import { AiFillEdit } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux'
import { formatMoney, myGet, toggleView } from '../../../GeneralFunctions';
import ConfirmBox from '../../Confirmation/ConfirmBox';
import Overlay from '../../Overlay';
import EditProductModal from './EditProductModal';


export default function ProductDetails() {

    const activeProduct = useSelector(state => state.activeProduct);
    const baseUrl = useSelector(state => state.baseUrl);
    const session = useSelector(state => state.session);
    const products = useSelector(state => state.products);
    const editableProduct = useSelector(state => state.editableProduct);


    const dispatch = useDispatch();

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const openProduct = function () {
        dispatch({ type: 'SET_EDITABLE_PRODUCT', data: activeProduct });
        toggleView(editModal, setEditModal);
    }

    const deleteProduct = () => {
        myGet(`${baseUrl}/modules/products/remove_product.php?sku=${activeProduct.sku_seller}&marketplace=${activeProduct.marketplace}`, (response) => {

            dispatch({ type: 'SET_DIALOG', data: { type: 'success', status: 'visible', text: 'O produto foi deletado com sucesso.' } });
            setConfirmDelete(false);

            const newProducts = products.filter(product => {
                if (product.id !== activeProduct.id) {
                    return product;
                }
            });
            dispatch({ type: 'SET_DATA', data: { products: newProducts } });
            dispatch({ type: 'SET_DATA', data: { activeProduct: false } });
            document.querySelector('body').style.overflowY = 'auto';

        }, (error) => {
            dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Ocorreu um erro inesperado. Por favor entre em contato com o suporte.' } });
        })
    }


    return (
        <>
            <div className='section product-title'>

                <div style={{ flex: 1 }}>
                    <div className='sm-mb'>
                        <h3>{activeProduct.title}</h3>
                    </div>
                    <div>
                        <span>SKU {activeProduct.sku_seller} · {activeProduct.seller ? activeProduct.seller.toLowerCase() === session.user_name.toLowerCase() ? `Você está vendendo esse produto` : `Vendido por ${activeProduct.seller} · ${formatMoney(activeProduct.sale_price)}` : `Este produto ainda não foi monitorado`} </span>
                    </div>
                </div>
                <div className='actions'>
                    <div className='sm-mb'>
                        <button className='btnLink' onClick={openProduct}>
                            <AiFillEdit size={16} /> Editar
                        </button>
                    </div>
                    <div>
                        <button className='btn-link-red' onClick={() => setConfirmDelete(true)}>
                            <FaTrash size={16} /> Remover
                        </button>
                    </div>
                </div>

            </div>
            {confirmDelete &&
                <ConfirmBox text='Tem certeza que deseja deletar esse produto? Essa ação é irreversível.' confirm={() => deleteProduct()} cancel={() => setConfirmDelete(false)} />

            }

            {
                editModal &&
                <div>
                    <Overlay toggleView={() => toggleView(editModal, setEditModal, true)} />
                    <EditProductModal product={editableProduct} toggleView={() => toggleView(editModal, setEditModal, true)} />
                </div>
            }
        </>
    )
}
