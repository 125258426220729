import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { myGet, formatMoney } from '../../../GeneralFunctions';
import Loading from '../../Loading';
import { AiOutlineArrowRight, AiOutlineLineChart, AiOutlineTrophy } from 'react-icons/ai';
import ExportBtn from '../../ExportData/ExportBtn';
import ProductHistory from '../../ProductHistory';
import PlanError from '../../PlanError/PlanError';
import LateralProductView from '../../Products/LateralProductView/LateralProductView';


export default function Products() {

    const activeProduct = useSelector(state => state.activeProduct);
    const baseUrl = useSelector(state => state.baseUrl);
    const prices = useSelector(state => state.prices);
    const session = useSelector(state => state.session);

    const [loadingProducts, setLoadingProducts] = useState(false);
    const [selectProductsMode, setSelectProductsMode] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const selectOptimizableProducts = useSelector(state => state.selectOptimizableProducts) || 1;
    const setSelectOptimizableProducts = (value) => dispatch({ type: 'SET_DATA', data: { selectOptimizableProducts: value } });


    const forbiddenColumns = ['offers', 'old_seller', 'price_difference', 'competition', 'stock', 'priority', 'last_update_stock', 'last_update_scrap', 'last_update_status', 'insert_date', 'is_available', 'id', 'fk_user', 'fk_sku_netshoes', 'status', 'percentage_difference'];

    const dispatch = useDispatch();

    useEffect(() => {
        if (!prices.products) {
            loadProducts(99999);
        }
    }, [prices]);


    const toggleLateralView = (product, e) => {

        if(selectProductsMode) return;
        
        let role = e.target.getAttribute('role');
        if (role == 'netshoes-link' || role == 'sku' || role == 'title') return;
        const selection = window.getSelection();

        if (selection.type != "Range") {
            document.querySelector('body').style.overflowY = 'hidden';
            if (!activeProduct) return dispatch({ type: 'SET_DATA', data: { activeProduct: product } });
            if (activeProduct.id === activeProduct.id) {
                document.querySelector('body').style.overflowY = 'auto';
                return dispatch({ type: 'SET_DATA', data: { activeProduct: false } });
            }

            document.querySelector('body').style.overflowY = 'hidden';
            return dispatch({ type: 'SET_DATA', data: { activeProduct: product } });
        }
    }

    const loadProducts = function (priceDifference) {
        setLoadingProducts(true);
        myGet(`${baseUrl}/modules/optimize/get_optimizable_products.php?priceDifference=${priceDifference}`, (response) => {
            var newPrices = { ...prices, products: response.data.products, quantity: response.data.quantity };
            dispatch({ type: 'SET_PRICES', data: newPrices });
            setLoadingProducts(false);
        }, () => {
            dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Não foi possível carregar os produtos. Tente novamente ou contate o suporte' } });
            setLoadingProducts(false);
        }, (error) => {
            dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Não foi possível carregar os produtos. Tente novamente ou contate o suporte. Ref: ${error}` } });
            setLoadingProducts(false);
        });
    }



    return (
        loadingProducts ?
            <Loading />
            :
            <div className='modalContent'>

                {session.plan == '1' ?
                    <PlanError />
                    :
                    <>
                        <ul>
                            <li className='cell' style={{ borderLeft: 0 }}>
                                <div className='data'>
                                    <div className='indexSection label'>Ref.</div>
                                    <div className='section'>
                                        <div className='subSection label'>Produto</div>
                                        <div className='subSection label'>Concorrência</div>
                                    </div>
                                    <div className='section'>
                                        <div className='subSection label'>Preço atual</div>
                                        <div className='arrowSection'></div>
                                        <div className='subSection label'>Preço sugerido</div>
                                        <div className='subSection label'>Variação (%)</div>
                                    </div>
                                </div>
                            </li>
                            {
                                prices.products ?
                                    prices.products.length > 0 ?
                                        prices.products.map((product, index) =>
                                            <Product
                                                toggleLateralView={toggleLateralView}
                                                setSelectedProducts={setSelectedProducts}
                                                selectedProducts={selectedProducts}
                                                selectProductsMode={selectProductsMode}
                                                index={index}
                                                key={index}
                                                product={product}
                                                selected={selectedProducts.some(p => p.sku_seller === product.sku_seller)}
                                            />
                                        )
                                        :
                                        <li>Nenhum produto a ser exibido.</li>
                                    :
                                    <li>Nenhum produto a ser exibido.</li>
                            }
                        </ul>

                        <div className='bottomRow'>
                            <div style={{ flex: 1 }}>
                                <span className='label'>Exibindo produtos com diferença de até</span>
                                <select value={selectOptimizableProducts} onChange={(e) => { setSelectOptimizableProducts(e.target.value); loadProducts(e.target.value) }}>
                                    <option value={0.01}>1%</option>
                                    <option value={0.05}>5%</option>
                                    <option value={0.10}>10%</option>
                                    <option value={0.25}>25%</option>
                                    <option value={0.50}>50%</option>
                                    <option value={1}>Todos</option>

                                </select>
                            </div>
                            <div>
                                <ExportBtn
                                    products={prices.products}
                                    selectProductsMode={selectProductsMode}
                                    setSelectProductsMode={setSelectProductsMode}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                    forbiddenColumns={forbiddenColumns}
                                    section='optimize'
                                />
                            </div>
                        </div>
                        <LateralProductView toggleLateralView={toggleLateralView} />
                    </>
                }
            </div>

    );
}


const Product = React.memo(({ product, index, selected, setSelectedProducts, selectProductsMode, selectedProducts, toggleLateralView }) => {


    const [view, setView] = useState(false);


    const selectProduct = function (product) {
        if (selectProductsMode === 'select') {
            if (selectedProducts.some(p => p.sku_seller === product.sku_seller)) {
                var newSelectedProducts = selectedProducts.filter(p => p.sku_seller !== product.sku_seller);
                setSelectedProducts(newSelectedProducts);
            } else {
                var newSelectedProducts = selectedProducts.concat(product);
                setSelectedProducts(newSelectedProducts);
            }
        }
    }


    return (
        <li className='cell hover'  style={selected ? { backgroundColor: 'rgb(209, 255, 203)' } : {}} onClick={() => selectProduct(product)}>
            <div className='data' role={selectProductsMode} onClick={(e) => toggleLateralView(product, e)}>
                <div className='indexSection'>
                    {index + 1}
                </div>
                <div className='section'>
                    <div className='subSection'>
                        <a href={`https://www.${product.marketplace}.com.br/${product.sku_netshoes}`} target='_blank'>{product.title}</a>
                        <span className='sku'>Cód. {product.sku_seller} · {product.sku_netshoes}</span>
                    </div>
                    <div className='subSection'>
                        {product.competition === '1' ?
                            'Normal'
                            :
                            product.competition === '2' ?
                                <div className='highCompetition'>
                                    <AiOutlineTrophy />
                                    Média
                                </div>
                                :
                                product.competition === '3' ?
                                    <div className='highCompetition'>
                                        <AiOutlineTrophy />
                                    Alta
                                    </div>
                                    :
                                    '-'
                        }
                    </div>
                </div>
                <div className='section'>
                    <div className='subSection'>
                        <div>{formatMoney(product.sale_price)}</div>
                    </div>
                    <div className='arrowSection'>
                        <AiOutlineArrowRight />
                    </div>
                    <div className='subSection'>
                        <div>{formatMoney(product.suggested_price)}</div>
                        <span className='newDifference' style={{ color: 'green' }}>+{formatMoney(product.price_difference)}</span>
                    </div>
                    <div className='subSection' style={{ color: 'green' }}>
                        +{(parseFloat(product.percentage_difference) * 100).toFixed(0)}%
                    </div>
                </div>
            </div>

            {view &&
                <ProductHistory sku={product.sku_netshoes} />
            }
        </li>
    );


});

