import React, { useState } from 'react'
import { MdLiveHelp } from 'react-icons/md'
import Header from '../Header/Header'
import Menu from '../Menu/Menu'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { FaTools } from 'react-icons/fa';
import { RiPriceTag3Line } from 'react-icons/ri';

export default function Support() {

    const [question, setQuestion] = useState(false);



    return (
        <>

            <Header pageTitle='Suporte' pageIcon={<MdLiveHelp size={16} />} />
            <Menu active='support' />

            <div className='pageContent' >
                <div id='supportContainer'>

                    <div class="containerHeader">
                        <div class="title">
                            <h3 class="containerTitleSmall">Perguntas frequentes</h3>
                        </div>
                    </div>
                    <div >
                        {!question ?
                            <ul id='questionsList'>
                                <li className='container' onClick={() => setQuestion(1)}>
                                    <div className='label'>
                                        SINCRONIZAÇÃO
                                    </div>
                                    <div>
                                        <h4>Como eu sincronizo a minha conta Hooklab com a minha plataforma de e-commerce?</h4>
                                    </div>

                                </li>
                                {/* <li className='container' onClick={() => setQuestion(2)}>
                                    <div className='label'>
                                        ALTERAÇÃO DE PREÇOS
                                    </div>
                                    <div>
                                        <h4>Como alterar o preço de vários produtos ao mesmo tempo na plataforma Netshoes?</h4>
                                    </div>
                                </li> */}
                            </ul>
                            :
                            <>
                                {question === 1 &&
                                    <div>
                                        <Question1 setQuestion={setQuestion} />
                                    </div>
                                }

                                {/* {question === 2 &&
                                    <div>
                                        <Question2 setQuestion={setQuestion} />
                                    </div>
                                } */}
                            </>
                        }
                    </div>
                </div>


            </div>
        </>
    )
}


const QuestionTitle = (props) => {

    return (
        <div className='questionHeader'>
            <button style={{ fontSize: '2rem' }} onClick={() => props.setQuestion(false)} className='iconBtn'><AiOutlineArrowLeft /></button>
            <h4>{props.title}</h4>
        </div>
    )
}


const Question1 = (props) => {

    const session = useSelector(state => state.session);

    return (
        <div className='container questionContainer'>
            <QuestionTitle setQuestion={props.setQuestion} title='Como eu sincronizo a minha conta Hooklab com a minha plataforma de e-commerce?' />;
            <div className='questionContent'>
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/BTjsngHs3oE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                </div> */}
                <ul>

                    <li style={{ marginBottom: 20 }}>
                        Essa função tem como objetivo sincronizar os produtos da suaplataforma de e-commerce
                        e os produtos sendo monitorados na Hooklab.
                    </li>
                    <li>
                        Inicialmente, entre em sua conta na plataforma Netshoes e clique na aba Lista por Fornecedor, no menu lateral esquerdo.
                    </li>
                    <li>
                        Em seguida, clique em <strong>Exportar</strong> e selecione a opção <strong>Lista Completa</strong> para baixar a lista de exportação.
                        <img alt='Baixar planilha de exportação.' src='http://hooklab.com.br/etc/imagens/suporte/1_exp.jpg' />
                    </li>
                    <li>
                        Clique em <strong>Preço</strong> e selecione a opção <strong>Exportar Preço</strong> para baixar a planilha de preços.
                        <img alt='Baixar planilha de preços.' src='http://hooklab.com.br/etc/imagens/suporte/2_preco.jpg' />
                    </li>
                    <li>
                        Clique em <strong>Estoque</strong> e selecione a opção <strong>Exportar Estoque</strong> para baixar a planilha de Estoque.
                        <img alt='Baixar planilha de estoque.' src='http://hooklab.com.br/etc/imagens/suporte/3_estoque.jpg' />
                    </li>
                    {session.platform === 'moovin' &&
                        <>
                            <li>
                                <h3>Para clientes Moovin</h3>
                            </li>
                            <li>
                                Para sincronizar os seus produtos com a plataforma Moovin, clique na aba Produtos no menu superior.
                            </li>
                            <li>
                                Na seção Escolha um serviço, selecione a opção Gerar CSV Completo, para fazer baixar o relatório dos seus produtos.
                                <img alt='Baixar planilha completa Moovin.' src='http://hooklab.com.br/etc/imagens/suporte/4_moovin.jpg' />
                            </li>

                        </>
                    }

                    <li>
                        Após ter baixado todos os produtos, no menu lateral, clique na aba <strong><FaTools style={{ marginRight: 3 }} /> Gerenciar</strong>
                    </li>
                    <li>
                        Por fim, clique em <strong>Sincronizar produtos</strong> e faça o upload das planilhas baixadas.
                    </li>
                </ul>
            </div>

        </div>
    );
}

// const Question2 = (props) => {
//     return (
//         <div className='container questionContainer'>
//             <QuestionTitle setQuestion={props.setQuestion} title='Como alterar o preço de vários produtos ao mesmo tempo na plataforma Netshoes?' />;
//             <div className='questionContent'>
//                 <ul>
//                     <li>
//                         No menu lateral, clique em <strong><FaTools style={{ marginRight: 3 }} />Gerenciar</strong>
//                     </li>
//                     <li>
//                         Clique na seção <strong><RiPriceTag3Line style={{ marginRight: 3 }} />Alterar preços</strong>
//                     </li>
//                     <li>
//                         Ao clicar no botão, abrirá uma tela contendo duas sessões: <br />
//                         1) Gerar nova planilha: Seção que será utilizada para selecionar os produtos que serão alterados. <br />
//                         2) Histórico de alterações: Seção em que será armanezado todo o histórico de todas as alterações realizadas.
//                     </li>
//                     <li>Para gerar uma nova planilha, clique no botão <b>Gerar nova planilha</b>.
//                     <img alt='Gerar nova planilha.' src='https://hooklab.com.br/suporte/imagens/q5img2.png' />
//                     </li>

//                     <li>
//                         Selecione os filtros para selecionar os produtos que serão alterados. <br />
//                          Primeiramente selecione o STATUS ATUAL dos produtos que você deseja alterar. É possível selecionar mais de um status.

//                     </li>

//                     <li>
//                         Em seguida, selecione a <strong>DIFERENÇA DE PREÇO ATUAL</strong> dos produtos que você deseja precificar <strong>NO FORMATO REAL</strong>, sendo por exemplo <strong>1 centavo equivalente a R$ 0,01</strong>.
//                         <div className='box'>
//                             Por exemplo: se você selecionar R$ 0,01 (1 CENTAVO), o sistema irá procurar por produtos que possuem atualmente 1 centavo de diferença em relação ao preço de mercado.
//                         </div>
//                     </li>

//                     <li>
//                         Selecione a <strong>NOVA DIFERENÇA DE PREÇO</strong> dos produtos que você deseja precificar <strong>NO FORMATO REAL</strong>.
//                         <div className='box'>
//                             Por exemplo: se você selecionar R$ 0,01, o preço dos seus produtos ficará R$ 0,01 (1 CENTAVO) abaixo do preço que está atualmente no mercado.
//                         </div>
//                     </li>

//                     <li>
//                         Feito isso, clique no botão Pesquisar Produtos.
//                     </li>

//                     <li>
//                         Selecione quais produtos você deseja aplicar a mudança de preço, marcando-os individualmente, ou através do botão Selecionar Todos no menu superior
//                     </li>

//                     <li>Confira as informações dos produtos, como o preço atual e o novo preço e clique em <strong>Gerar Planilha</strong> para baixar a planilha contendo alterações.</li>

//                     <li>
//                         Acesse a plataforma da Netshoes. Na seção Preço clique em Importação Preço. Selecione a planilha de preços gerada clicando no botão Buscar e envie-a clicando no botão Enviar.
//                         <img src='https://hooklab.com.br/suporte/imagens/q5img5.png' />
//                     </li>

//                     <li>Por fim aguarde a planilha ser processada. A Netshoes será responsável por realizar a alteração dos preços de seus produtos.</li>



//                 </ul>

//             </div>
//         </div>
//     )

// }
