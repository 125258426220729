import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { myGet } from '../../../GeneralFunctions';
import { Bar } from 'react-chartjs-2';
import Loading from '../../Loading';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';

export default function BrandQuantity() {

    const brandsQuantityData = useSelector(state => state.brandsQuantityData);
    const baseUrl = useSelector(state => state.baseUrl);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [navigatorPage, setNavigatorPage] = useState(0);
    const brandsPerPage = 5;
    const [activeBrand, setActiveBrand] = useState({});
    const [loadingBrand, setLoadingBrand] = useState(false);
    const [activeBrandBarData, setActiveBrandBarData] = useState({});
    const [brandsData, setBrandsData] = useState({});

    useEffect(() => {

        if (!brandsQuantityData) {
            setLoading(true);
            myGet(`${baseUrl}/modules/report/brands/get_brands_quantity_data.php`, (response) => {
                dispatch({ type: 'SET_BRANDS_QUANTITY_DATA', data: response.data });

                // Vai para a ultima página do navigator.
                setNavigatorPage(Math.ceil(response.data.x.length / brandsPerPage) - 1);
                setLoading(false);

            }, (response) => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Ocorreu um erro ao tentar carregar o relatório de mercas. Ref: ${response}.` } })
                setLoading(false);
            }, (error) => {
                dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: `Não foi possível enviar a requisição do relatório de mercas. Ref: ${error}.` } })
                setLoading(false);
            });
        }
    }, [brandsQuantityData, brandsPerPage]);


    useEffect(() => {

        if (brandsQuantityData.x && brandsQuantityData.y) {

            //Define as legendas do eixo X conforme a marca que veio no banco.

            var labels = brandsQuantityData.x.map(brand => brand.name);

            //Da um update na informação que vai ser renderizado no gráfico
            setBrandsData({
                labels: labels,
                datasets: [
                    {
                        label: 'Quantidade de produtos',
                        backgroundColor: '#ff5283',
                        borderColor: '#ff5283',
                        borderWidth: 1,
                        hoverBackgroundColor: '#ff5283',
                        hoverBorderColor: '#ff5283',
                        data: brandsQuantityData.y
                    }
                ]
            });
        }

    }, [brandsQuantityData, activeBrand]);


    const renderChart = (selling, alert, losing, netshoes) => {

        // Objeto com a configuração de cada dataset

        var datasetsData = [
            {
                label: 'Vendendo',
                color: '#00800091',
                data: [selling]
            },
            {
                label: 'Alerta',
                color: '#ffa50091',
                data: [alert]
            },
            {
                label: 'Perdendo',
                color: '#ff00008a',
                data: [losing]
            },
            {
                label: 'Netshoes',
                color: '#6607668a',
                data: [netshoes]
            },
        ];


        var datasets = datasetsData.map(dataset => {

            var data = dataset.data.map(i => i);

            return {
                label: dataset.label,
                backgroundColor: dataset.color,
                borderColor: dataset.color,
                borderWidth: 1,
                hoverBackgroundColor: dataset.color,
                hoverBorderColor: dataset.color,
                data: data
            }

        });


        // Remove os datasets zerados

        datasets = datasets.filter(dataset => dataset.data[0] > 0);

        //Seta as informações da barra de marca individual

        setActiveBrandBarData({
            labels: [''],
            datasets: datasets
        });

        setLoadingBrand(false);
    }



    const loadBrandData = function (brand) {

        if (activeBrand.name === brand) {

            setActiveBrand({});

        } else {
            var selectedBrand = brandsQuantityData.x.find(b => b.name === brand);

            if (selectedBrand.data) {

                setActiveBrand(selectedBrand);
                renderChart(selectedBrand.data.selling, selectedBrand.data.alert, selectedBrand.data.losing, selectedBrand.data.netshoes);

            } else {


                setActiveBrand(selectedBrand);
                setLoadingBrand(true);



                myGet(`${baseUrl}/modules/report/brands/get_brand_data.php?brand=${brand}`, (response) => {

                    var newBrandsQuantityData = brandsQuantityData.x.map(b => b.name === brand ? { ...b, data: response.data } : b);
                    dispatch({ type: 'SET_BRANDS_QUANTITY_DATA', data: { ...brandsQuantityData, x: newBrandsQuantityData } })
                    renderChart(response.data.selling, response.data.alert, response.data.losing, response.data.netshoes);

                },
                    (response) => {
                        dispatch({ type: 'SET_DIALOG', data: { type: 'fail', text: `Ocorreu um erro ao carregar os dados dessa marca. Ref: ${response}`, status: 'visible' } });
                        setLoadingBrand(false);
                    },
                    (error) => {
                        dispatch({ type: 'SET_DIALOG', data: { type: 'fail', text: `Não foi possível enviar a requisição para carregar os dados dessa marca. Ref: ${error}`, status: 'visible' } });
                        setLoadingBrand(false);
                    });
            }

        }

    }





    return (
        <div id='brandQuantityContainer'>
            <div className='containerHeader'>
                <div className='title'>
                    <h3 className='containerTitleSmall'>Quantidade de produtos pelas principais marcas</h3>
                </div>
            </div>
            <div className='container' style={{ padding: 0 }}>
                {loading ?
                    <Loading />
                    :
                    <>
                        {brandsQuantityData ?
                            brandsQuantityData.x.length > 0 ?
                                <>
                                    <div className='navigator'>


                                        {navigatorPage > 0 &&
                                            <div onClick={() => setNavigatorPage(navigatorPage - 1)} className='item arrow'>
                                                <AiOutlineArrowLeft />
                                            </div>
                                        }


                                        {brandsQuantityData.x.map((brand, index) =>

                                            (index >= (navigatorPage * brandsPerPage)) && (index < ((navigatorPage + 1) * brandsPerPage)) ?

                                                <div className={activeBrand.name === brand.name ? 'activeItem item' : 'item'} onClick={() => loadBrandData(brand.name)}>
                                                    <div className='title'>{brand.name}</div>
                                                    <div className='number'>{brandsQuantityData.y[index]}</div>
                                                </div>
                                                : null
                                        )}

                                        {((navigatorPage + 1) * brandsPerPage) < (brandsQuantityData.x.length) &&
                                            <div onClick={() => setNavigatorPage(navigatorPage + 1)} className='item arrow'>
                                                <AiOutlineArrowRight />
                                            </div>
                                        }

                                    </div>



                                    <div>
                                        {loadingBrand ?
                                            <Loading />
                                            :
                                            activeBrand.name ?
                                                <BrandChart activeBrandBarData={activeBrandBarData} />
                                                :
                                                <BrandsChart brandsData={brandsData} />
                                        }

                                    </div>
                                </>
                                :
                                <div style={{ padding: 20, fontSize: '1.3rem', color: 'gray' }}>
                                    Não há dados para serem mostrados.
                                </div>
                            :

                            <div style={{ padding: 20, fontSize: '1.3rem', color: 'gray' }}>
                                Não há dados para serem mostrados.
                            </div>
                        }
                    </>
                }
            </div>
        </div >
    )
}



const BrandChart = ({ activeBrandBarData }) => {




    const brandOptions = {
        scales: {
            xAxes: [{
                barPercentage: 0.2,

            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                }
            }]


        },
        maintainAspectRatio: false,
        legend: {
            display: false
        },
    };



    return (
        <Bar
            width={100}
            height={350}
            data={activeBrandBarData}
            options={brandOptions}
        />);

}



const BrandsChart = ({ brandsData }) => {
    return (
        <Bar
            data={brandsData}
            width={100}
            height={350}
            options={{
                legend: {
                    display: false
                },
                maintainAspectRatio: false
            }}
        />
    );
}