import React, { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

export default function ExportModal({ forbiddenColumns, setSelectedProducts, selectedProducts, toggleView, defaultColumns, selectProductsMode, products, setWindow, setSelectProductsMode, section }) {

    const [columns, setColumns] = useState(false);
    const dispatch = useDispatch();
    const session = useSelector(state => state.session);
    const [fileFormat, setFileFormat] = useState('xlsx');

    useEffect(() => {
        if (!columns) {

            //Verifica qual é o modo de seleção para poder identificar quais colunas tem nos produtos.
            if (selectProductsMode === 'select') {
                var listColumns = Object.keys(selectedProducts[0]).map(col => {
                    return { name: col, checked: false, alias: formatColumnName(col), dataType: formatDataType(col) };
                });
                setColumns(listColumns);
            } else {
                var listColumns = Object.keys(products[0]).map(col => {
                    return { name: col, checked: false, alias: formatColumnName(col), dataType: formatDataType(col) };
                });
                setColumns(listColumns);
            }

        }
    }, [columns]);


    //Formata o nome das colunas padrão

    const formatColumnName = function (column) {


        switch (column) {
            case 'pk_sku_netshoes':
            case 'sku_netshoes':
            case 'fk_sku_netshoes':
                return 'SKU Netshoes';

            case 'old_sale_price':
                return 'Preço Último Seller'

            case 'current_seller_price':
            case 'seller_price':
                return `Seu preço atual`;

            case 'old_seller':
                return 'Último Seller';

            case 'title':
                return 'Título';

            case 'min_price':
                return 'Preço Mínimo';

            case 'marketplace':
                return 'Marketplace';

            case 'cost_price':
                return 'Preço de Custo';

            case 'min_markup':
                return 'Margem Mínima';

            case 'stock':
                return 'Estoque';

            case 'current_price_difference':
                return 'Diferença de Preço Atual';

            case 'current_sale_price':
                return 'Preço atual no marketplace';

            case 'suggested_price':
                return 'Preço Sugerido';

            case 'suggested_price_difference':
                return 'Diferença do preço sugerido';

            case 'percentage_difference':
                return 'Variação do novo preço';

            case 'status':
                return 'Status';

            case 'sku_seller':
                return 'Sku Seller';

            case 'category':
                return 'Categoria';

            case 'seller':
                return 'Vendedor';

            case 'brand':
                return 'Marca';

            case 'sale_price':
                if (section === 'optimize') {
                    return 'Seu preço atual';
                } else {
                    return 'Preço no buy-box'
                }

            case 'price_difference':
                return 'Diferença do preço atual';

            case 'competition':
                return 'Concorrência';

            default:
                return column;
        }
    }

    // Formata o tipo dos dados para poder fazer o tratamento para cada caso

    const formatDataType = function (column) {

        switch (column) {
            case "old_sale_price":
            case "current_sale_price":
            case "min_price":
            case "cost_price":
            case "seller_price":
            case "current_price_difference":
            case "suggested_price":
            case "suggested_price_difference":
            case "sale_price":
            case "sale_price":
            case "price_difference":
                return 'number';
            default:
                return 'string';
        }
    }

    const generateSpreadsheet = function () {

        var finalArray = [];
        var selectedColumns = columns.filter(column => column.checked);
        var p = selectProductsMode === 'all' ? products : selectedProducts;


        if (selectedColumns.length > 0) {

            p.forEach(product => {
                var object = {};
                Object.keys(product).forEach(key => {
                    selectedColumns.forEach(selected => {
                        if (selected.name === key) {
                            if (selected.dataType === 'number') {
                                var data = product[selected.name];
                            } else {
                                var data = product[selected.name];
                            }
                            object[selected.alias] = data;
                        }
                    });
                });
                finalArray.push(object);
            });






            var data = JSON.stringify(finalArray);

            fetch(`https://api-netshoes.hooklab.com.br/export/default/file-name/exportacao_hooklab/format/${fileFormat}/user/${session.id}`, {
                method: 'POST',
                body: data,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    if (blob) {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = `exportacao_hooklab.${fileFormat}`;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove(); //afterwards we remove the element again   

                        toggleView();
                        setSelectProductsMode(false);
                        setWindow(false);
                        setSelectedProducts([]);
                        dispatch({ type: 'SET_DIALOG', data: { type: 'success', status: 'visible', text: 'A planilha foi gerada com sucesso.' } })
                    }
                });
        } else {
            dispatch({ type: 'SET_DIALOG', data: { type: 'fail', status: 'visible', text: 'Selecione as colunas que deseja exportar.' } })
        }
    }

    return (
        <div className='modal' id='exportColumnsModal'>
            <div className='modalHeader'
                style={{
                    padding: 15,
                    boxShadow: '0px 0px 1px 1px #e1e1e1'
                }}
            >
                <div className='title'>
                    <h3 className='containerTitleSmall'>Selecione as colunas que deseja exportar</h3>
                </div>
                <div className='close'>
                    <button
                        style={{ padding: 0 }}
                        onClick={() => toggleView()}
                        className='iconBtn'
                    >
                        <MdClose
                            size={18}
                        />
                    </button>
                </div>
            </div>
            <div>
                <ul>
                    {columns &&
                        columns.map((column, index) =>
                            <ColumnCell
                                key={index}
                                index={index}
                                column={column}
                                columns={columns}
                                setColumns={setColumns}
                                defaultColumns={defaultColumns}
                                forbiddenColumns={forbiddenColumns}
                            />
                        )
                    }
                </ul>
                <div className='bottomRow'>
                    <div className='fileFormatSection'>
                        <span className='label'>Escolha um formato</span>
                        <select className='basicInput' value={fileFormat} onChange={(e) => setFileFormat(e.target.value)}>
                            <option value='xlsx'>xlsx</option>
                            <option value='xls'>xls</option>
                            <option value='csv'>csv</option>
                        </select>
                    </div>
                    <div>
                        <button className='blueBtn2' onClick={() => generateSpreadsheet()}>Gerar planilha</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

const ColumnCell = React.memo(({ column, columns, setColumns, index, forbiddenColumns }) => {


    const handleColumnName = function (newName, column) {
        var newColumns = columns.map(col => col === column ? { ...col, alias: newName } : col);
        setColumns(newColumns);
    }

    const handleColumnCheck = function (value) {
        var newColumns = columns.map(col => col === column ? { ...col, checked: value === 'on' ? false : true } : col);
        setColumns(newColumns);
    }

    return (
        forbiddenColumns.indexOf(column.name) === -1 &&
        <li>
            <div className='data'>
                <div className='columnNameSection'>
                    <input
                        type='text'
                        placeholder={columns[index].alias}
                        value={columns[index].alias}
                        onChange={(e) => handleColumnName(e.target.value, column)}
                        className='basicInput2'
                    />
                </div>
                <div className='checkboxSection' style={{ display: 'flex', alignItems: 'center', paddingRight: 20 }}>
                    <input type='checkbox' value={column.checked} onChange={(e) => handleColumnCheck(e.target.value)} />
                </div>
            </div>
        </li>
    );

});