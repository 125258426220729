export const formatDatetime = function (date) {
    if (date) {
        var splitDate = date.split(" ")[0].split("-");
        return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}, ${date.split(" ")[1].split(":")[0]}:${date.split(" ")[1].split(":")[1]}h`;
    } else {
        return 'N/A';
    }

}

export const formatDatetimeToDate = function (date) {
    if (date) {
        var splitDate = date.split(" ")[0].split("-");
        return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
    } else {
        return 'N/A';
    }

}

export const formatMoney = function (price) {
    if (price === 0) return Number(0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    if (price) {

        // return `R$ ${(parseFloat(price).toFixed(2).toString().replace(".", ","))}`;
        return Number(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });


    } else {
        return `?`;
    }


}

export function getCurrentDateTime() {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!

    var yyyy = today.getFullYear();
    var hour = today.getHours();
    hour = (hour < 10 ? '0' : '') + hour;
    var minute = today.getMinutes();
    minute = (minute < 10 ? '0' : '') + minute;

    today = yyyy + '-' + mm + '-' + dd + ' ' + hour + ':' + minute;
    return today;

}


export const calculateVariation = function (actualValue, oldValue) {
    if (oldValue != 0) {
        return (((parseInt(actualValue) - parseInt(oldValue)) / parseInt(oldValue)) * 100).toFixed(0);
    } else {
        return 0;
    }
}


export const myGet = function (url, callback, error, requestFailed) {


    fetch(url).then(response => response.json()).then(response => {
        if (response.http_code === '200') {
            callback(response);
        } else {
            error(response);
        }

    }).catch(err => {
        if (requestFailed) {
            requestFailed(err);
        }
    });

}


export const toggleView = function (state, setState, preventChangeScroll) {
    if (state) {
        if (!preventChangeScroll) {
            document.body.style.overflowY = 'auto';
        }
        setState(false);
    } else {
        document.body.style.overflowY = 'hidden';
        setState(true);
    }


}

