
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { myGet } from "../../GeneralFunctions";
import { useGet } from "../../hooks/http/useGet";

// import { setLastFeedback } from "../../store/feedbackReducer/feedbackReducer";
import { IStore } from "../../store/IStore";

type Props = {
  id: string;
};

export default function useLastFeedback({ id }: Props) {
  const get = useGet();
  const dispatch = useDispatch();

  const loadedLastFeedback = useSelector(
    (state: IStore) => state.feedback.loadedLastFeedback
  );

  const lastFeedback = useSelector(
    (state: IStore) => state.feedback.lastFeedback
  );

  const baseUrl = useSelector((state: IStore) => state.baseUrl);

  useEffect(() => {
    if (!loadedLastFeedback) {
      (async () => {
        try {
          const feedbacks = await fetch(
            `${baseUrl}/modules/feedback/get-feedbacks.php`,
            {
              method: "GET",
            }
          );
          const data = await feedbacks.json();
          const lastFeedback = data.find((f: any) => f.id === id);
          dispatch({
            type: "SET_LAST_FEEDBACK",
            data: lastFeedback ? lastFeedback : null,
          });
        } catch (error) {
          //@ts-ignore
          alert(error.message);
        }
      })();
    }
  }, [loadedLastFeedback]);

  return () => lastFeedback;
}
