import React, { useState } from 'react'
import { AiFillEdit, AiOutlineClockCircle, AiOutlineLineChart, AiOutlineTrophy } from 'react-icons/ai';
import { IoMdSpeedometer } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { toggleView } from '../../GeneralFunctions';
import ProductHistory from '../ProductHistory';
import ReactToolTip from 'react-tooltip'




const Product = ({ data, index, toggleView, toggleLateralView }) => {

    const dispatch = useDispatch();

    const [mouseOver, setMouseOver] = useState(false);
    const [markupWindow, setMarkupWindow] = useState(false);
    const [chartView, setChartView] = useState(false);

    const activeProduct = useSelector(state => state.activeProduct)
    const session = useSelector(state => state.session);

    const {
        last_update_scrap,
        title,
        marketplace,
        seller_price,
        markup,
        stock,
        sale_price,
        seller,
        pk_sku_netshoes,
        status,
        sku_seller,
        difference_markup,
        min_markup,
        min_price,
        competition,
        show_product,
        suggested_price,
        market_difference,
        suggested_price_difference
    } = data;


    const formatPrice = function (price) {

        if (price) {
            return Number(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        } else {
            return <span className='grayLabel'>-</span>
        }

    }

    const formatMarkup = (markup) => {
        if (markup) {
            markup = parseFloat(markup.toString().replace(",", "."));
            return markup.toFixed(1);
        } else {
            return <span className='grayLabel'>-</span>
        }

    }

    const formatStock = (stock) => {
        if (stock === '1') {
            return '1+';
        } else {
            return stock;
        }
    }

    const formatSeller = (status, seller, skuNetshoes, marketplace) => {

        return <a href={`https://www.${marketplace}.com.br/${skuNetshoes}`} role='netshoes-link' className={status} target='_blank'>{seller}</a>;

    }

    const formatdifferenceMarkUp = (difference, markup) => {
        if (markup !== "" && markup !== null && markup !== undefined) {
            if (difference >= 0) {
                return <span className='greenLabel'>+{parseFloat(difference).toFixed(2)}</span>;
            } else {
                return <span className='redLabel'>{parseFloat(difference).toFixed(2)}</span>;
            }
        } else {
            return null;
        }
    }

    const formatLastUpdate = (lastUpdate) => {


        if (lastUpdate) {

            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var hour = date.getHours();
            var minute = date.getMinutes();
            var productDate = lastUpdate.split(" ")[0];
            var sYear = productDate.split("-")[0];
            var sMonth = productDate.split("-")[1];
            var sDay = productDate.split("-")[2];
            var productDate2 = lastUpdate.split(" ")[1];
            var sHour = productDate2.split(":")[0];
            var sMinute = productDate2.split(":")[1];
            var convertHoraAtual, convertHora, diferenca, dv, lp;


            if (sYear == year) {
                convertHoraAtual = ((parseInt(day) * 24) + (parseInt(month) * 31 * 24) + parseInt(hour));
                convertHora = ((parseInt(sDay) * 24) + (parseInt(sMonth) * 31 * 24) + parseInt(sHour));
                diferenca = convertHoraAtual - convertHora;
                if (diferenca > 24) {
                    dv = Math.floor(diferenca / 24);
                    if (dv == "1") {
                        lp = "1 dia";
                    } else {
                        lp = `${dv} dias`;
                    }
                } else {
                    if (diferenca <= "1") {
                        var convertMinuto = parseInt((parseInt(sDay) * 24 * 60) + (parseInt(sMonth) * 31 * 24 * 60) + (parseInt(sHour) * 60) + parseInt(sMinute));
                        var convertMinutoAtual = parseInt((parseInt(day) * 24 * 60) + (parseInt(month) * 31 * 24 * 60) + (parseInt(hour) * 60) + parseInt(minute));
                        var dm = parseInt(convertMinutoAtual) - parseInt(convertMinuto);
                        if (dm > 60) {
                            diferenca = Math.floor(dm / 60);
                            lp = `${diferenca}h`;
                        } else {
                            diferenca = Math.floor(dm);
                            lp = `${diferenca}min`;
                        }
                    } else {
                        dv = Math.floor(diferenca);
                        lp = `${dv}h`;
                    }
                }
            } else {
                lp = `${sDay}/${sMonth}/${sYear} às ${sHour}:${sMinute}h`;
            }

            return (
                <div className='clock'>
                    <AiOutlineClockCircle />
                    {lp}
                </div>);
        }
    }

    const formatTitle = (title) => {
        if (title === '' || title == 'Indisponível' || title === null || title == 'Indisponivel') {
            return <span className='grayLabel'>-</span>;
        } else {
            return `${title}`;
        }
    }

    const formatPriceDifference = (sellerPrice, salePrice, status) => {

        var actualDifference = (parseFloat(sellerPrice) - parseFloat(salePrice)).toFixed(2);

        switch (status) {
            case "selling":
                return <span className='greenLabel'>VENDENDO</span>;

            case "alert":
                return <div data-tip='Diferença entre o preço do mercado e o seu preço.'>
                    <span className='orangeLabel'>{formatPrice(actualDifference)}</span>
                    <span className='market-difference-percent'>({(Number((market_difference) * 100).toFixed(0))}%)</span>
                </div>

            case "losing":
                return <span data-tip='Diferença entre o preço do mercado e o seu preço.' className='redLabel'>{formatPrice(actualDifference)}</span>;


            case "netshoes":
                if (actualDifference > 0) {
                    return <span data-tip='Diferença entre o preço do mercado e o seu preço.' className='orangeLabel'>{formatPrice(actualDifference)}</span>;
                } else if (actualDifference <= 0) {
                    return <span data-tip='Diferença entre o preço do mercado e o seu preço.' className='blueLabel'>{formatPrice(actualDifference)}</span>
                } else {
                    return ``;
                }

            case "nostock":
                return '';

            case "unavailable":
                return '';

            case "unknown":
                return <span className='grayLabel'>-</span>;
        }


    }



    const renderCompetition = () => {

        if (competition == '3') {
            return (<div className='competitionBadge lowCompetition '></div>);
        }

        if (competition == '2') {
            return (<div className='competitionBadge mediumCompetition '></div>);
        }

        if (competition == '1') {
            return (<div className='competitionBadge highCompetition '></div>);
        }
    }

    const competitionBadgeClassName = `competition${competition}`





    const isProductActive = (() => {
        if (!activeProduct) return false;
        return activeProduct.id === data.id
    })();

    return (

        <div onClick={(e) => toggleLateralView(data, e)} className={isProductActive ? 'productContainer productActive' : 'productContainer'} onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
            <div className={`cell`}>
                <div className='index'>
                    {show_product && <div className='custom-added-badge'>Adicionado</div>}
                    {competitionBadgeClassName && <div className={competitionBadgeClassName}></div>}
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ marginRight: 3 }}>
                            {index}
                        </div>
                    </div>
                </div>
                <div className='section section-big' >
                    <div className='marketplace-name label'>
                        {marketplace.toUpperCase()}
                    </div>
                    <div>
                        <span role='title' className='product-title'>{formatTitle(title)}</span>
                    </div>
                    <div className='product-sku'>
                        <div role='sku' className='sku-seller'>Cód. {sku_seller}</div>
                        <div className='division'>·</div>
                        <div role='sku' className='sku-netshoes'>{pk_sku_netshoes}</div>
                    </div>
                </div>

                <div className='section'>
                    {formatPrice(seller_price)}
                </div>
                {session.markup === 't' &&
                    <div className='section' id='markup' onMouseEnter={() => setMarkupWindow(true)} onMouseLeave={() => setMarkupWindow(false)}>
                        <div>{formatMarkup(markup)}</div>
                        <div className='markupDifference'>{formatdifferenceMarkUp(difference_markup, markup)}</div>

                        {markupWindow && markup !== null &&
                            <div className='window'>
                                <div className='windowRow'>
                                    <div className='windowTitle'>Margem</div>
                                    <div className='windowValue'>{formatMarkup(markup)}</div>
                                </div>
                                <div className='windowRow'>
                                    <div className='windowTitle'>Margem Mínima</div>
                                    <div className='windowValue'>{min_markup}</div>
                                </div>
                                <div className='windowRow'>
                                    <div className='windowTitle'>Diferença</div>
                                    <div className='windowValue'>{formatdifferenceMarkUp(difference_markup, markup)}</div>
                                </div>
                                <div className='windowRow'>
                                    <div className='windowTitle'>Preço Mínimo</div>
                                    <div className='windowValue'>{formatPrice(min_price)}</div>
                                </div>
                            </div>
                        }

                    </div>
                }



                <div className='section' >
                    <span className={competitionBadgeClassName}>
                        {competition == '1' ?
                            'Normal'
                            :
                            competition == '2' ?
                                <div className='highCompetition'>
                                    <AiOutlineTrophy />
                                    Média
                                </div>
                                :
                                competition == '3' ?
                                    <div className='highCompetition'>
                                        <AiOutlineTrophy />
                                        Alta
                                    </div>
                                    :
                                    <span className='grayLabel'>-</span>}
                    </span>
                </div>
                {session.plan !== '1' &&
                    <div className='section'>
                        <div>{formatPrice(suggested_price)}</div>
                        <div>
                            <span className={suggested_price_difference > 0 ? 'greenLabel' : 'blueLabel'}>
                                {formatPrice(suggested_price_difference)}
                            </span>
                        </div>
                    </div>}
                <div className='section' >
                    <div>{formatPrice(sale_price)}</div>
                    <div>{formatPriceDifference(seller_price, sale_price, status)}</div>
                </div>
                <div className='section'>
                    <div className='seller'>
                        {formatSeller(status, seller, pk_sku_netshoes, marketplace)}
                        {renderCompetition()}
                    </div>
                    <div>{formatLastUpdate(last_update_scrap)}</div>


                </div>
            </div>
            {chartView &&
                <ProductHistory sku={pk_sku_netshoes} />
            }
            <ReactToolTip effect='solid' />
        </div>



    )
}

export default React.memo(Product);



// <div className='productContainer'>
//             <div className='cell' onClick={() => setShowMarketplaces(!showMarketplaces)}>
//                 <div className='index'>
//                     {parseInt(props.index) + parseInt(1)}
//                 </div>
//                 <div className='productSection' style={{flex:1, marginRight:0}}>

//                     <div className='productTitle'>{title}</div>
//                 </div>
//                 <div className='productSection'>
//                     <div className='productPrice'>R$ 80,50</div>
//                 </div>
//                 <div className='productSection'>
//                     <div className='productPrice'>R$ 70,50</div>
//                 </div>
//                 <div className='productSection'>

//                     <div className='productPrice'>4</div>
//                 </div>
//                 <div className='arrow'>
//                     {showMarketplaces ?
//                         <IoIosArrowUp size={18} />
//                         :
//                         <IoIosArrowDown size={18} />
//                     }
//                 </div>

//             </div>
//            
//         </div >