import React from 'react'
import { useSelector } from 'react-redux'
import OffersList from './OffersList';
import ProductDetails from './ProductDetails';
import ProductHistoric from './ProductHistoric';
import SuggestedPrice from './SuggestedPrice';


export default function LateralProductView({ toggleLateralView }) {

    const activeProduct = useSelector(state => state.activeProduct);




    return (
        <>
            <div id='lateral-product-view' style={activeProduct ? { right: 0 } : { right: '-34vw' }}>
                {activeProduct ?
                    <>
                        <ProductDetails/>
                        <SuggestedPrice />
                        <ProductHistoric />
                        <OffersList />


                    </>
                    :
                    null}
            </div>

            {activeProduct &&
                <div id='lateral-product-view-overlay' onClick={(e) => toggleLateralView(activeProduct, e)}></div>
            }

    

        </>
    )
}




/* <div className='section'>
    {activeProduct.status === 'selling' && <span className={`lateral-product-view-status-span-${activeProduct.status}`}>Você está vendendo esse produto por {formatMoney(activeProduct.sale_price)}.</span>}
    {(activeProduct.status === 'alert' || activeProduct.status === 'losing') && <span className={`lateral-product-view-status-span-${activeProduct.status}`}>{activeProduct.seller} está vendendo esse produto por {formatMoney(activeProduct.sale_price)}.</span>}
</div> */