import React, { useState } from 'react'
import { GiFishingHook, GiPieChart } from "react-icons/gi";
import { FaTools, FaInbox, FaRegChartBar } from "react-icons/fa";
import { MdAccountCircle, MdLiveHelp } from "react-icons/md";
import { IoIosExit, IoIosPricetags } from "react-icons/io";


import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GrOptimize } from 'react-icons/gr';
import { BiBox } from 'react-icons/bi';
import { FiBox } from 'react-icons/fi';

export default function Menu({ active }) {

    const history = useHistory();
    const [menu, setMenu] = useState(false)
    const baseUrl = useSelector(state => state.baseUrl);
    const dispatch = useDispatch();

    const navigate = function (route) {
        history.push(route);
    }


    const logout = function () {
        window.location.href = `${baseUrl}/modules/logout/index.php`;

    }






    return (
        <div className='menuContainer' style={menu ? { width: '16vw' } : { width: '60px' }} onMouseEnter={() => setMenu(true)} onMouseLeave={() => setMenu(false)}>
            <ul>
                <li onClick={() => navigate('/dashboard')} className='item'>
                    <div className='icon'>
                        <GiFishingHook size={22} />
                    </div>

                    <div style={menu ? { display: 'flex' } : { display: 'none' }} className='title'>
                        Hooklab
                    </div>
                </li>

                <li onClick={() => navigate('/dashboard')} className={active === 'dashboard' ? 'activeItem' : 'item'}>
                    <div className='icon'>
                        <FaRegChartBar size={22} />
                    </div>
                    <div style={menu ? { display: 'flex' } : { display: 'none' }} className='title'>
                        Dashboard
                    </div>
                </li>

                <li onClick={() => navigate('/produtos')} className={active === 'products' ? 'activeItem' : 'item'}>
                    <div className='icon'>
                        <FiBox size={22} />
                    </div>
                    <div style={menu ? { display: 'flex' } : { display: 'none' }} className='title'>
                        Produtos
                    </div>
                </li>


                <li onClick={() => navigate('/otimizar')} className={active === 'optimize' ? 'activeItem' : 'item'}>
                    <div className='icon'>
                        <IoIosPricetags size={22} />
                    </div>
                    <div style={menu ? { display: 'flex' } : { display: 'none' }} className='title'>
                        Otimizar
                    </div>
                </li>

                <li onClick={() => navigate('/relatorio')} className={active === 'report' ? 'activeItem' : 'item'}>
                    <div className='icon'>
                        <GiPieChart size={22} />
                    </div>
                    <div style={menu ? { display: 'flex' } : { display: 'none' }} className='title'>
                        Relatório
                    </div>
                </li>
                <li onClick={() => navigate('/editar')} className={active === 'manage' ? 'activeItem' : 'item'}>
                    <div className='icon'>
                        <FaTools size={22} />
                    </div>
                    <div style={menu ? { display: 'flex' } : { display: 'none' }} className='title'>
                        Gerenciar
                    </div>
                </li>
                <li onClick={() => navigate('/conta')} className={active === 'account' ? 'activeItem' : 'item'}>
                    <div className='icon'>
                        <MdAccountCircle size={22} />
                    </div>
                    <div style={menu ? { display: 'flex' } : { display: 'none' }} className='title'>
                        Conta
                    </div>
                </li>

                <li onClick={() => navigate('/suporte')} className={active === 'support' ? 'activeItem' : 'item'}>
                    <div className='icon'>
                        <MdLiveHelp size={22} />
                    </div>
                    <div style={menu ? { display: 'flex' } : { display: 'none' }} className='title'>
                        Suporte
                    </div>
                </li>

            </ul>
            {menu &&
                <div style={{ position: 'absolute', bottom: 0, color: '#FFF', fontSize: '1.2rem', padding: 10 }}>
                <a href='https://hooklab.com.br/pt-br/termos-de-uso.pdf' target='_blank' style={{ fontWeight: 500, color: '#FFF' }}>Termos de uso</a>
                </div>}

            <div onClick={() => logout()} style={{ position: 'absolute', bottom: 30, width: '100%' }} className={active === 'editProducts' ? 'activeItem' : 'item'}>
                <div className='icon'>
                    <IoIosExit size={22} />
                </div>
                <div style={menu ? { display: 'flex' } : { display: 'none' }} className='title'>
                    Desconectar
                </div>
            </div>



        </div >
    )
}
