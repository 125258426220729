import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaBell, FaUserCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { MdMessage, MdWarning } from 'react-icons/md';
import { AiFillMessage, AiFillAlert } from 'react-icons/ai'
import { BiSmile, BiUserCircle } from 'react-icons/bi';
import ReactTooltip from 'react-tooltip';

export default function Header({ pageTitle, pageIcon }) {

    const [window, setWindow] = useState(false);
    const session = useSelector(state => state.session);
    const baseUrl = useSelector(state => state.baseUrl);
    const [notifications, setNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();



    useEffect(() => {
        if (unreadNotifications > 0) {
            setTimeout(() => {
                setUnreadNotifications(0);
            }, 10000);
        }

    }, [unreadNotifications]);

    useEffect(() => {

        fetch(`${baseUrl}/modules/header/get_notifications.php`).then(response => response.json()).then(response => {
            if (response.http_code === '200') {
                setUnreadNotifications(response.data.filter(n => n.read === 'f').length);
                setNotifications(response.data);
            }
        });

    }, []);



    const toggleNotifications = function () {
        setUnreadNotifications(0);
        if (window) {
            setWindow(false);
        } else {
            fetch(`${baseUrl}/modules/header/read_notifications.php`);
            setWindow(true);
        }
    }

    const openFeedback = () => {
        dispatch({ type: 'SET_VIEW_FEEDBACK', data: true})
    }

    return (
        <div className='headerContainer'>
            <div className='spot' id='pageTitleSpot'>
                {pageIcon}
                {pageTitle}
            </div>
            <div id='buttonSpot'>
                <div className='spot' style={{ borderRight: '1px solid #b5b5b5', paddingRight: 15 }}>
                    <button data-tip='Avalie a Hooklab' className='iconBtn' onClick={() => openFeedback()} style={{ padding: 0, marginRight: 15}}>
                        <BiSmile size={20} color='#000' /></button>
                    <button data-tip='Notificações'  className='iconBtn' onClick={() => toggleNotifications()} style={{ padding: 0 }}>
                        <FaBell size={20} color='#000' /></button>

                    {unreadNotifications > 0 &&
                        <div onClick={() => toggleNotifications()} id='unreadMessagesBox'>{unreadNotifications === 1 ? '1 nova notificação' : `${unreadNotifications} novas notificações`}</div>
                    }
                    {window &&
                        <Notifications notifications={notifications} />
                    }
                </div>
                <div className='spot' id='userSpot'>
                    <BiUserCircle size={22} color='#000' /> {session.user_name}
                </div>
            </div>
            <ReactTooltip effect='solid' />
        </div>
    )
}


const Notifications = ({ notifications }) => {




    return (
        <div id='notificationsWindow'>
            {notifications.length > 0 ?
                <ul>
                    {notifications.map(notification =>

                        <li>
                            <div className='icon'>
                                {notification.notification_type === 'Novidade' ?
                                    <AiFillMessage color='#000' size={24} />
                                    :
                                    <AiFillAlert color='orange' size={24} />

                                }
                            </div>
                            <div className='content'>
                                <div className='type'>{notification.notification_type}</div>
                                <div className='text'>{notification.notification_text}</div>
                            </div>
                        </li>

                    )}
                </ul>
                :
                <div style={{ padding: 20, fontSize: '1.3rem', color: 'gray' }}>Nenhuma nova notificação</div>
            }
        </div>

    );

}